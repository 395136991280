import React, { useReducer, useContext, useEffect, useState } from "react";
import { getError } from "../../utils";
import { toast } from "react-toastify";
import { Table, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  FaPlusCircle,
  FaRegTrashAlt,
} from "react-icons/fa";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import { Store } from "../../Store";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Pagination from "../../components/ParamPagination/ParamPagination.jsx";
import _deliverySchedules from "../../services/deliverySchedules.service.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        deliverySchecules: action.payload,
        loadingFetch: false,
        itemQuantity: action.payload.length,
      };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function DeliverySchedule() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [
    { loadingFetch, errorFetch, deliverySchecules, successDelete, itemQuantity },
    dispatch,
  ] = useReducer(reducer, {
    deliverySchecules: [],
    loadingFetch: true,
    errorFetch: "",
    itemQuantity: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentPosts = deliverySchecules.slice(indexOfFirstItem, indexOfLastItem);

  const [siblingCount,setSiblingCount] = useState(1);
  window.addEventListener("resize", (e) => {
    if(window.innerWidth < 400) {
      setSiblingCount(0);
    } else {
      setSiblingCount(1);
    }
  })
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentPosts = deliverySchecules.slice(indexOfFirstItem, indexOfLastItem);
  };

  //#region REQUESTS
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const result = await _deliverySchedules.list();
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: error.message });
        console.error(error);
        toast.error("Error obteniendo los horarios.");
    }
  };
  const deleteHandler = async (schedule) => {
    if (deliverySchecules.length > 1) {
      if (window.confirm("¿Estás seguro de que quieres eliminar este horario?")) {
        try {
          await _deliverySchedules.delete(schedule._id);
          toast.success("Horario de delivery eliminado Correctamente");
          dispatch({ type: "DELETE_SUCCESS" });
        } catch (err) {
          dispatch({ type: "DELETE_FAIL" });
          console.error(getError(errorFetch));
        }
      }
    } else {
      toast.warning("Debes tener al menos un horario de delivery.")
    }
  };
  //#endregion 
  useEffect(() => {
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
        fetchData();
    }
  }, [successDelete, userInfo]);




  return (
    <div>
      <Helmet>
        <title>Horarios de Delivery</title>
      </Helmet>
      <div className="container">
        {loadingFetch ? (
          <LoadingBox></LoadingBox>
        ) : errorFetch ? (
          <MessageBox variant="danger">{errorFetch}</MessageBox>
        ) : (
          <div>
            <div className="row adminProdBtnRow align-items-center justify-content-end">
              <div className="col-lg-2 col-sm-12 ">
              <LinkContainer to="/AdminScreen/AdminSchedules/AddSchedule">

                  <Button>
                    {" "}
                    <FaPlusCircle></FaPlusCircle>Agregar Horario
                  </Button>
                  </LinkContainer>
              </div>
            </div>
            <Card>
              <Table responsive hover size="sm">
                <thead>
                  <tr>
                    <th>Descripcion</th>
                    <th>Horario de Inicio</th>
                    <th>Horario de Finalizacion</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((schedule, i) => (
                    <tr key={i}>
                 
                      <td className="text-center">{schedule.label}</td>
                      <td className="text-center">{schedule.hourFrom }</td>
                      <td className="text-center">{schedule.hourTo }</td>
                      <td className="col-1 text-center">
                     
                          <Button onClick={() => deleteHandler(schedule)}>
                            <FaRegTrashAlt></FaRegTrashAlt>Eliminar
                          </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        )}
        <div className="admin-pagination-container">

          <Pagination
          siblingCount = {siblingCount}
            className="pagination-bar"
            totalCount={itemQuantity}
            onPageChange={onPageChange}
            currentPage={currentPage}
            pageSize={itemsPerPage}
          ></Pagination>
        </div>
      </div>
    </div>
  );
}
export default DeliverySchedule;
