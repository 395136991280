import React, { useEffect, useState } from 'react';
import "./newProductVariantSettings.css";
import classnames from 'classnames'
//#region BS COMPONENTS
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import ButtonSecondaryTranslucidHover from '../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import Button from 'react-bootstrap/esm/Button';
//#endregion
import { toast } from 'react-toastify';
import VariantConfiguration from './VariantConfiguration/VariantConfiguration';
import { makeVariantName } from '../../../utils';

export default function NewProductVariantSettings({ variants, setVariants, variantsAreValid , disabled,
   toggleUsage, updateVariantTotalStock}) {
  const [variantsToEdit, setVariantsToEdit] = useState({...variants});
  const [activeKey, setActiveKey] = useState(-1);
  const setVariantValue = (id,key,value, updateVariants) => {
    const variantsCopy = {};
    Object.assign(variantsCopy,variantsToEdit);
    const v = parseInt(value) >= 0 ? parseInt(value) : value;
    variantsCopy[id][key] = v;
    setVariantsToEdit(variantsCopy);
    if(updateVariants){
      setVariants(variantsCopy);
    }
};
const updateAndCloseVariant = (variant) => {
  const variantKey = variant._id + variant.name;
  setVariants(variantsToEdit);
  updateVariantTotalStock(variantsToEdit);
  changeItem(variantKey)
}
const changeItem = (key) => {
  setActiveKey(key != activeKey ? key : -1);
}

  return (
    <Card id="new-product-variant-settings" className={classnames('col-xl-7 col-lg-8 col-md-9 col-10', { disabled })}>
      <Card.Header as="h5">Lista de variantes 
        {
          !variantsAreValid &&
          <span>
            Al menos una de las variantes debe tener una foto
          </span>

        }  
      </Card.Header>
      <Card.Body>
        <Accordion activeKey={activeKey} onSelect={changeItem}>
          {
            Object.keys(variants).map((variantId, i) => {
              const variant = variants[variantId]
              const { options, _id } = variant;
              return <Accordion.Item eventKey={i} key={_id + variant.name}>
                <Accordion.Header>{makeVariantName(variant)}</Accordion.Header>
                <Accordion.Body className='px-0'>
                  <VariantConfiguration onSuccess = {updateAndCloseVariant.bind(this,variant)} variant={variant} setVariantValue={setVariantValue}/>
                </Accordion.Body>
              </Accordion.Item>
            })
          }

        </Accordion>
      </Card.Body>
      <Card.Footer className='d-flex justify-content-end'>
         <ButtonSecondaryTranslucidHover onClick={toggleUsage}>
          Redefinir opciones
        </ButtonSecondaryTranslucidHover>
      </Card.Footer>
    </Card>
  )
}
