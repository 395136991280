import React, { useReducer, useContext, useEffect, useState } from "react";
import { getError, getStatus, hasPermission } from "../../utils";
import { toast } from "react-toastify";
import { Card, Button } from "react-bootstrap";
import { FaPlusCircle, FaListAlt} from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import { Store } from "../../Store";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import PermissionWrapper from "../../permission-utils/PermissionWrapper.jsx";
import { SCOPES } from "../../permission-utils/scopes.js";
import DynamicListTable from "../../components/DynamicListTable";
import _categories from "../../services/category.service.js";
import "./CategoriesScreen.css";
import useQueryFilters from "../../hooks/useQueryFilters.jsx";
import ExportToExcelButton from "../../components/ExportData/ExportToExcel.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        categories: action.payload.categories,
        categoriesCount: action.payload.categoriesCount,
        loadingFetch: false,
      };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function CategoriesScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const [
    { loadingFetch, errorFetch, categoriesCount,  categories, successDelete },
    dispatch,
  ] = useReducer(reducer, {
    categories: [],
    loadingFetch: true,
    errorFetch: "",
    categoriesCount : 0
  });
  const navigate = useNavigate();
  const [actions] = useState({
    seeDetails: seeDetailsHandler
  })
  const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

  const { setPageSize, setPage, fields, siblingCount } = useQueryFilters({ baseUrl: '/AdminScreen/Categories', resizeWidth: 400 });

//TODO: Revisar esto. No se si es necesario.

  useEffect(() => {
    if(userInfo && userInfo.role) {
      actions.deleteHandler = hasPermission(userInfo.role,"categories",[SCOPES.canDelete]) ? deleteHandler : null;
      actions.editHandler = hasPermission(userInfo.role,"categories",[SCOPES.canEdit]) ? editHandler : null;
    }
   },[userInfo]);

   useEffect(() => {
		if(parseInt(fields.page) > Math.ceil(categoriesCount / fields.pageSize)){
			setPage(1); // Reset page if it's out of bounds
		}
	},[fields, categoriesCount])

  useEffect(() => {
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData()
    };
  }, [successDelete,search]);

  //#region REQUESTS
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const result = await _categories.adminList(searchParams);
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: error.message });
    }
  };

  const deleteHandler = async (category) => {
    if (window.confirm("Eliminar esta categoria?")) {
      try {
        await _categories.delete(category._id);
        toast.success("Categoria eliminada");
        dispatch({ type: "DELETE_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "DELETE_FAIL",
        });
        console.error(err);
        toast.error(getError(err));
      }
    }
  };
  //#endregion 

  async function editHandler(category) {
    navigate(`/AdminScreen/Categories/EditCategory/${category._id}`)
  }
  async function seeDetailsHandler(category) {
    navigate(`/AdminScreen/Categories/category/${category._id}`)
  }


  return (
    <>
      <Helmet>
        <title>Lista de categorías</title>
      </Helmet>
      <div id = "admin-categories-container" className="admin-con p-3">
        <div className="row justify-content-start">

        </div>
        <h1 className="section-title text-right">
          <FaListAlt></FaListAlt>Lista de categorías
        </h1>
        {loadingFetch ? (
          <LoadingBox></LoadingBox>
        ) : errorFetch ? (
          <MessageBox variant="danger">{errorFetch}</MessageBox>
        ) : (
          <div className="Container">
            <div className="row adminProdBtnRow align-items-center justify-content-end">
              <div className="col-lg-3 col-sm-12 ">
                <PermissionWrapper fn="categories" scopes={[SCOPES.canCreate]}>
                <LinkContainer to="/AdminScreen/Categories/AddCategory">
                  <Button>
                    <FaPlusCircle className="pe-1"></FaPlusCircle>Agregar
                    categoría
                  </Button>
                </LinkContainer>
                </PermissionWrapper>
              </div>
                  <ExportToExcelButton
                    dataFetcherFunction={_categories.adminList.bind(this, searchParams, true)}
                    data={categories}
                    dataName={'categories'}
                    overlayPlacement='top'
                  />
            </div>
            <Card>
              <DynamicListTable
                data={categories}
                loading={loadingFetch}
                error={errorFetch}
                totalCount={categoriesCount}
                itemsPerPage={fields.pageSize}
                setPageSize={setPageSize}
                currentPage = {fields.page}
                onPageChange={setPage}
                actionButtons={actions}
                dataName="categories"
                showCheckboxColumn={false}
                links={true}
                exportOption={false}
                siblingCount={siblingCount}
              />
            </Card>
          </div>)}
      </div>
      </>
      )

}
export default CategoriesScreen;
