export default class Product {
	constructor(
		name,
		slug,
		image,
		brand,
		category,
		htmlDescription,
		description,
		price,
		countInStock,
		minStockToConfirm,
		hasVariants,
		active,
		highlightedProduct,
		priceRange,
		_id = null,
	) {
		Object.assign(this, {
			name,
			slug,
			image,
			brand,
			category,
			htmlDescription,
			description,
			price,
			countInStock,
			minStockToConfirm,
			hasVariants,
			active,
			highlightedProduct,
			priceRange,
		});
		if (_id) {
			this._id = _id;
		}
	}
	//#region GETTERS

	//#endregion
	isBasicDataValid() {
		return (
			this.name &&
   this.brand &&
   this.category &&
   this.description &&
   this.price &&
   parseInt(this.countInStock) >= 0
		);
	}
	static create({
		_id = null,
		name,
		slug,
		image,
		brand,
		category,
		htmlDescription,
		description,
		price,
		countInStock,
		minStockToConfirm,
		hasVariants,
		active,
		highlightedProduct,
		priceRange,
	}) {
		return new Product(
			name,
			slug,
			image,
			brand,
			category,
			htmlDescription,
			description,
			price,
			countInStock,
			minStockToConfirm,
			hasVariants,
			active,
			highlightedProduct,
			priceRange,
			_id,
		);
	}
	static createEmptyProduct() {
		return new Product(
			'',
			'',
			null,
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			false,
			false,
			false,
			'',
			'',
		);
	}
}
