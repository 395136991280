import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getFilterUrl } from '../utils';

export default function useQueryFilters({baseUrl, resizeWidth}) {
    const { search } = useLocation();
    const navigate = useNavigate();
	const searchParams = new URLSearchParams(search);
	const [siblingCount, setSiblingCount] = useState(1);

	const getFields = () => {
		return {
			...Object.fromEntries(searchParams),
			page: parseInt(searchParams.get("page")) || 1,
			pageSize: parseInt(searchParams.get("pageSize")) || 16,
		}
	}
	const [fields, setFields] = useState(getFields());

	/**
	 * Sends data loaded into filters to the server
	 */
    const submitFilters = () => {
		const query = getFilterUrl({...fields, page: 1});
		navigate(query ? `${baseUrl}?${query}` : baseUrl);
	};
	/**
	 * Sets the fields and sends the data loaded into filters directly to the server
	 */
	const setFieldsAndSubmit = (fieldsToSubmit) => {
		const query = getFilterUrl({ ...fields, ...fieldsToSubmit, page: 1 });
		navigate(query ? `${baseUrl}?${query}` : baseUrl);
	}
	
    const setPageSize = (size) => {
		const query = getFilterUrl({...fields, pageSize: size});		
		navigate(`${baseUrl}?${query}`);
	  }
	const setPage = (page) => {
		const query = getFilterUrl({...fields, page});
		navigate(`${baseUrl}?${query}`);
	  }

	const handleResize = () => {
		setSiblingCount(window.innerWidth >= resizeWidth ? 1 : 0);
	};

	useEffect(() => {
		setFields(getFields());
	}, [search]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

  return {fields, siblingCount, submitFilters , setPageSize, setPage, setFields, setFieldsAndSubmit}
}
