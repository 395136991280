import React from "react";
import "../Screens.css";
import { useLayoutEffect, useReducer, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import DOMPurify from "dompurify";
import _settings from "../../services/settings.service";
import _richText from "../../services/richText.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        text: action.payload.text,
        gotaSettings: action.payload.settings,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default function ContactInfo() {
  const [{ loading, text, gotaSettings }, dispatch] = useReducer(reducer, {
    text: {},
    gotaSettings: {},
    loading: true,
    error: "",
  });

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await _richText.getContactPage();
        const { data: settings } = await _settings.getPublicData();
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { text: data, settings: settings },
        });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="container container-envios">
      <Helmet>
        <title>Contacto</title>
      </Helmet>
      <Card>
        <Row className="envios-Row">
          <div className="col-lg-6 align-items-center">
            <iframe
              title="companyLocation"
              src={gotaSettings.googleMapLocationUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-lg-6 col col-envios-contact">
            <div dangerouslySetInnerHTML={createMarkup(text.htmlText)}></div>
          </div>
        </Row>
      </Card>
    </div>
  );
}
