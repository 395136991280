import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import React, { useContext, useReducer, useState } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { MdLibraryAdd, MdClose } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import { getError, getStatus } from "../../utils";
import { useNavigate } from "react-router-dom";
import _categories from "../../services/category.service.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddCategoryScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ loadingCreate }, dispatch] = useReducer(reducer, {
    loadingCreate: false,
  });
  const [name, setName] = useState("");
  const validateForm = () => {
    return name;
  };

  const addCategoryHandler = async () => {
    if (validateForm()) {
      try {
        dispatch({ type: "CREATE_REQUEST" });
        await _categories.create(name);
        dispatch({ type: "CREATE_SUCCESS" });
        toast.success("Categoria creada");
        setName("");
        navigate(-1);
      } catch (ex) {
        dispatch({ type: "CREATE_FAIL" });
        toast.error(getError(ex));
      }
    } else {
      toast.error("Error. Revise los datos.");
    }
  };
  let navigate = useNavigate();

  return (
    <Modal size="lg" show={true} animation={false}>
      <div className="container align-items-center">
        {loadingCreate && <LoadingBox></LoadingBox>}
        <Helmet>
          <title>Agregar Categoria</title>
        </Helmet>
        <div className="row justify-content-around">
          <div className="col-lg-11 col-10">
            <h1 className="my-3">
              <MdLibraryAdd></MdLibraryAdd>Agregar Categoria
            </h1>
          </div>
          <div className="col-lg-1 col-2">
            <MdClose
              className="my-3"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></MdClose>
          </div>
        </div>
        <Form onSubmit = {(e) => e.preventDefault()}> 
          <div className="row align-items-center">
            <Form.Group className="mb-3 col-lg-10" controlId="">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                required
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>

          <Row className="modal-btns">
            <Col>
              <Button className="cancelar" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button className="guardar" onClick={addCategoryHandler}>
                Agregar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default AddCategoryScreen;
