import axios from '../restClient';

const BASE_URI = '/auth';

class AuthService {
	async login(email, password) {
		const uri = BASE_URI + '/login';
		const res = await axios.post(uri, { email, password });
		return res;
	}
	async logout(refreshToken, id) {
		const uri = BASE_URI + '/logout';
		const res = await axios.post(uri, { refreshToken, id });
		return res;
	}
}
export default new AuthService();
