import React, { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./DeleteFaqQuestionModal.css";

export default function ModalDeleteFaqQuestion({
  deleteShow,
  question,
  onHide,
  deleteHandler
}) {
  return (
    <Modal
      show={deleteShow}
      onHide={onHide}
      dialogClassName="modal-delete-question"
    >
      <Modal.Header closeButton>
        <Modal.Title>Borrar pregunta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que quieres eliminar esta pregunta?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={deleteHandler.bind(this,question._id)}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
