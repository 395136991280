import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import {
  FaEdit,
  FaInfoCircle,
  FaPlusCircle,
  FaRegTrashAlt,
} from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackBtn from "../../components/GoBackButton/GoBackBtn";
import MessageBox from "../../components/MessageBox/MessageBox";
import Pagination from "../../components/Pagination/Pagination";
import { Store } from "../../Store";
import { getError, getStatus } from "../../utils";
import "./CategoryDetails.css";
import { SCOPES } from "../../permission-utils/scopes";
import PermissionWrapper from "../../permission-utils/PermissionWrapper";
import _category from "../../services/category.service";
import _product from "../../services/product.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, category: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "FETCH_RELATED_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
        products: "",
        successLoadingProducts: false,
      };
    case "FETCH_RELATED_PRODUCTS_SUCCESS":
      return {
        ...state,
        products: action.payload,
        itemQuantity: action.payload.length,
        loading: false,
        successLoadingProducts: true,
      };
    case "FETCH_RELATED_PRODUCTS_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};
export default function CategoryDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [categoryName, setCategoryName] = useState("");
  const [
    {
      loading,
      error,
      category,
      products,
      loadingDelete,
      successDelete,
      itemQuantity,
      successLoadingProducts,
    },
    dispatch,
  ] = useReducer(reducer, {
    category: "",
    successLoadingProducts: false,
    products: "",
    loading: true,
    error: "",
    itemQuantity: 0,
  });
  const [siblingCount,setSiblingCount] = useState(1);
  window.addEventListener("resize", (e) => {
    if(window.innerWidth < 400) {
      setSiblingCount(0);
    } else {
      setSiblingCount(1);
    }
  })
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await _category.get(id);
        setCategoryName(result.data.name);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };

      fetchData();
  }, [id, userInfo]);

  useEffect(() => {
    const fetchData = async () => {
      if (category) {
        dispatch({ type: "FETCH_RELATED_PRODUCTS_REQUEST" });
        const id = category._id;
        try {
          const result = await _product.getProductsWithCategory(id);
          dispatch({
            type: "FETCH_RELATED_PRODUCTS_SUCCESS",
            payload: result.data,
          });
        } catch (ex) {
          dispatch({ type: "FETCH_RELATED_PRODUCTS_FAIL" });
          console.error(getError(ex));
        }
      }
    };
    fetchData();
  }, [category, successDelete]);

  const deleteHandler = async (product) => {
      if (window.confirm("Seguro que quieres borrar este producto?")) {
        dispatch({ type: "DELETE_REQUEST" });
        try {
          await _product.delete(product._id);
          toast.success("Producto eliminado correctamente");
          dispatch({ type: "DELETE_SUCCESS" });
        } catch (err) {
          dispatch({
            type: "DELETE_FAIL",
          });
            console.error(err);
            toast.error("Error. No se pudo borrar el producto.");
        }
      }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentPosts = products.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentPosts = products.slice(indexOfFirstItem, indexOfLastItem);
  };
  useEffect(() => {
    if (successLoadingProducts) onPageChange(1);
  }, [successLoadingProducts]);

  return (
    <div>
      <Helmet>
        <title>{categoryName}</title>
      </Helmet>
      <Row>
        <div className="col-sm-8 col-md-6 category-details-container admin-con container">
          <div className="category-details p-4">
            <GoBackBtn></GoBackBtn>
            <h1 className="mt-1 mb-3">
              <FaInfoCircle className="p-0"></FaInfoCircle>{" "}
              <span className="d-inline-block ps-1">Detalles</span>{" "}
            </h1>
            <Form>
              <Row>
                <Form.Group className="mb-3 col-6" controlId="name">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={categoryName}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Row>
            </Form>
          </div>
          <PermissionWrapper  fn="categories" field="products" scopes={[SCOPES.canView]}>
          <div className="related-products-container  p-5 pb-2">
            <Row className="justify-content-between">
              <h2 className="col-8">Productos de la categoría</h2>
              <PermissionWrapper  fn="categories" field="products" scopes={[SCOPES.canCreate]}>
                <LinkContainer
                  className="col-3"
                  to={{
                    pathname: `/AdminScreen/AddProduct`,
                    search: `category=${id}`,
                  }}
                >
                  <Button>
                    <FaPlusCircle className="pe-1"/>Agregar Producto
                  </Button>
                </LinkContainer>
              </PermissionWrapper>
            </Row>
            {products ? (
              <>
                <Table hover size="sm">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Marca</th>
                      <th>Precio</th>
                      <th>Stock</th>
                      <th>Está activo?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPosts &&
                      currentPosts.map((product) => (
                        <tr key={product._id}>
                          <td>
                            <Link to={`/product/${product.slug}`}>
                              {product.name}
                            </Link>
                          </td>
                          <td>{product.brand}</td>
                          <td>${product.price}</td>
                          <td>{product.countInStock}</td>
                          <td>{product.active ? "SI" : "NO"}</td>
                          <td className="col-1 text-center">
                            <DropdownButton drop="start" title="">
                              <LinkContainer to={`/product/${product.slug}`}>
                                <Dropdown.Item>Ver Detalles</Dropdown.Item>
                              </LinkContainer>
                              <LinkContainer to={`/AdminScreen/EditProduct/${product.slug}`}>

                                <Dropdown.Item>
                                  <FaEdit> </FaEdit> Editar
                                </Dropdown.Item>
                              </LinkContainer>
                              <PermissionWrapper fn="categories" field="products" scopes={[SCOPES.canDelete]}>
                                <Dropdown.Item
                                  onClick={() => deleteHandler(product)}
                                >
                                  <FaRegTrashAlt> </FaRegTrashAlt>Eliminar
                                </Dropdown.Item>
                              </PermissionWrapper>
                            </DropdownButton>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="admin-pagination-container">
                <Pagination
                  siblingCount = {siblingCount}
                  className="pagination-bar"
                  totalCount={itemQuantity}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  pageSize={itemsPerPage}/>
                  </div>
              </>
            ) : (
              <div className="mt-5">
                <MessageBox>No hay productos asociados.</MessageBox>
              </div>
            )}
          </div>
          </PermissionWrapper>
        </div>
      </Row>
    </div>
  );
}
