import React, { useContext, useEffect, useReducer } from 'react';
import './VariantOptionsConfigScreen.css';
import { Helmet } from 'react-helmet-async';
import { FaEye, FaSitemap, FaTrash } from 'react-icons/fa';
import { Button, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import MessageBox from '../../components/MessageBox/MessageBox';
import LoadingBox from '../../components/LoadingBox/LoadingBox';
import { Store } from '../../Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import BtnOutlineSecondary from '../../components/BtnOutlineSecondary/BtnOutlineSecondary';
import NewVariantOption from './NewVariantOption/NewVariantOption';
import { getStatus } from '../../utils';

export default function VariantOptionsConfig() {

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [{ variantOptions, loading, error, showNewOptionModal, sucessNewOption }, dispatch] =
    useReducer(reducer, {
      showNewOptionModal: false,
      loading: true,
      error: "",
      variantOptions: [],
      sucessNewOption: false
    });

  const redirect = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    navigate("/signin");
    toast.error("Sesion expirada. Vuelve a ingresar.");
  };

  useEffect(() => {
    async function fetchOptions() {
      dispatch({ type: "FETCH_OPTIONS_REQUEST" });
      try {
        const response = await axios.get('/api/productVariants/globalOptions', {
          headers: { authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: "FETCH_OPTIONS_SUCCESS", payload: response.data })
      } catch (ex) {
        console.error(ex);
        dispatch({ type: "FETCH_OPTIONS_FAIL", payload: "No se pudo obtener las opciones." });
      }
    };
    if (userInfo && userInfo.token) {
      if (loading || sucessNewOption) {
        fetchOptions();
      }
    } else {
      redirect();
    }

  }, [userInfo, sucessNewOption]);

  async function newOptionSubmitHandler(e) {
    const { name } = e.target;
    if (userInfo.token) {
      if (name && name.trim().length <= 20) {
        try {
          await axios.post('/api/productVariants/globalOptions', { name },
            { headers: { Authorization: `Bearer ${userInfo.token}` } });
          toast.success("Opcion agregada con éxito.");
          dispatch({ type: "ADD_NEW_OPTION_SUCCESS" })
        } catch (ex) {
          if (getStatus(ex) == 401) {
            redirect();
          } else {
            console.error(ex)
            toast.error("Error. No se pudo agregar la opción.")
          }
        }
      }
    } else {
      redirect();
    }
  }
  return (
    <div className='container admin-con mt-2'>
      <Helmet>
        <title>Configuracion de Variantes Globales</title>
      </Helmet>
      <h1 className="text-right col-6"><FaSitemap className='mb-2 pe-2' /> Variantes de Producto Globales</h1>
      <div className="row justify-content-end">

        <BtnOutlineSecondary className="w-25" clickHandler={() => dispatch({ type: "SHOW_NEW_OPTION_MODAL" })}>
          Nueva opcion
        </BtnOutlineSecondary>
      </div>
      <div className="variant-config-body">

        {loading ?
          <LoadingBox /> :
          error ?
            <MessageBox variant="danger">{error}</MessageBox> :
            variantOptions.length ?
              <ListGroup id='lista-metodos' className='mt-2'>
                {
                  variantOptions.map((option, index) => {
                    return (
                      <ListGroup.Item key={index}>
                        <Row className='justify-content-between px-2'>
                          <Col xs={8}>
                            {option.name}
                          </Col>
                          <Col xs={4} md={2} className='d-flex justify-content-around p-0'>
                            <button onClick={() => console.info("select")} className='action-button'>
                              <FaEye></FaEye>
                            </button>
                            <button onClick={() => console.info("borrar")} className='action-button'>
                              <FaTrash></FaTrash>
                            </button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  })
                }
              </ListGroup> :
              <MessageBox className="mt-5 text-center" variant="info">
                No se encontraron opciones de variantes
              </MessageBox>
        }
      </div>
      {/* <NewVariantOption show={showNewOptionModal} redirect={redirect}
        token={userInfo.token} className="mt-5"
        hide={() => dispatch({ type: "HIDE_NEW_OPTION_MODAL" })}
        success={() => dispatch({ type: "ADD_NEW_OPTION_SUCCESS" })} /> */}

      <Modal size="md" show={showNewOptionModal} animation={true}>
        <Modal.Header>
          <Row className='align-items-center'>
            <h1>
              <FaSitemap className='mb-2 pe-2' />
              Nueva opcion
            </h1>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <NewVariantOption submitHandler={newOptionSubmitHandler} />
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <Button onClick={() => dispatch({ type: "HIDE_NEW_OPTION_MODAL" })}>
            Cancelar
          </Button>
          <Button type='submit' form = "form-new-variant-option">
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}



const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_OPTIONS_REQUEST":
      return { ...state, loading: true, sucessNewOption: false };
    case "FETCH_OPTIONS_SUCCESS":
      return { ...state, variantOptions: action.payload, loading: false };
    case "FETCH_OPTIONS_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "SHOW_NEW_OPTION_MODAL":
      return { ...state, showNewOptionModal: true };
    case "HIDE_NEW_OPTION_MODAL":
      return { ...state, showNewOptionModal: false };
    case "ADD_NEW_OPTION_SUCCESS":
      return { ...state, showNewOptionModal: false, sucessNewOption: true };
    default:
      return state;
  }
};