import Button from "react-bootstrap/Button";
import React from "react";
import "./BtnPlus.css";
import classnames from 'classnames';

export default function BtnPlus({ type, onClick, className}) {
  return (
    <Button className={classnames("btn-plus", { [className]: className })}  type={type} onClick={onClick}>
      +
    </Button>
  );
}
