import React, { useContext, useReducer, useState } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { MdLibraryAdd, MdClose } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import { getError, getStatus } from "../../utils";
import { useNavigate } from "react-router-dom";
import _banners from "../../services/banner.service";
import GoBackBtn from "../../components/GoBackButton/GoBackBtn";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddBanner() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ loadingCreate }, dispatch] = useReducer(reducer, {
    loadingCreate: false,
  });
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isActive, setIsActive] = useState(false);


  const addBannerHandler = async () => {
      try {
        dispatch({ type: "CREATE_REQUEST" });
        await _banners.create(name, image, isActive);
        dispatch({ type: "CREATE_SUCCESS" });
        toast.success("Banner agregado");
        navigate("/AdminScreen/Config");
      } catch (ex) {
        dispatch({ type: "CREATE_FAIL" });
        toast.error(getError(ex));
      }
  };
  let navigate = useNavigate();

  const uploadPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    formData.append("documentId", image);
    const response = await _banners.uploadBanner(formData);
    setImage(response.data._id);
    setImageUrl(response.data.fileLink);
  };

  return (
    <Modal size="lg" show={true} animation={false}>
      <div className="container align-items-center">
        <Helmet>
          <title>Nuevo Banner</title>
        </Helmet>
        <div className="row justify-content-around">
          <div className="col-lg-11 col-10">
            <h1 className="my-3">
              <MdLibraryAdd></MdLibraryAdd>Agregar Banner
            </h1>
          </div>
          <div className="col-lg-1 col-2">
            <MdClose
              className="my-3"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></MdClose>
          </div>
        </div>
        <Form>
          <div className="row">
            <Form.Group className="mb-3 col-lg-6" controlId="">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="mb-3 col-lg-6" controlId="">
              <Form.Label>Subir banner</Form.Label>
              <Form.Control
                type="file"
                onChange={uploadPicture}
                name="bannerImg"
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 col-lg-2" controlId="">
              <Form.Label>Activo</Form.Label>
              <Form.Check
                checked={isActive}
                defaultValue={isActive}
                name="activeId"
                onChange={(e) => setIsActive(e.target.checked)}
              ></Form.Check>
            </Form.Group>
          </div>
          <div className="row align-items-centers">
            <div className="col-lg-12 col-10 container-banner-preview">
              {imageUrl ? (
                <img
                  alt="product_photo"
                  src={imageUrl}
                  className="banner-preview"
                />
              ) : (
                <img
                  alt="product_photo"
                  src="/images/emptyPhoto.png"
                  className="banner-preview"
                />
              )}
            </div>
          </div>
          <Row className="modal-btns">
            <Col>
              <Button className="cancelar" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button className="guardar" onClick={addBannerHandler}>
                Agregar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default AddBanner;
