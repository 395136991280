import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import "./FaqPage.css";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import * as React from "react";
import FaqQuestion from "../../components/FaqQuestionItem/FaqQuestionItem";
import _faq from "../../services/faq.service.js";
import _utils from "../../services/utils.service.js";


function FaqPage() {
  const [show, setShow] = useState(false);
  const [loadingClass, setLoadingClass] = useState("d-none");
  const [iframeClass, setIframeClass] = useState("d-none");
  const [iframeSrc, setIframeSrc] = useState("");
  const [questions, setQuestions] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const reqQuestions = await _faq.list();
        setQuestions(reqQuestions.data);
      } catch (ex) {
        console.error(ex);
      }
    };
    const fetchSrc = async () => {
      try {
        const iframeReq = await _utils.getIFrame();
        setIframeSrc(iframeReq.data);
      } catch (ex) {
        console.error(ex);
      }
    };
    fetchQuestions();
    fetchSrc();
  }, []);
  useEffect(() => {
    if (show === true) {
      setLoadingClass("d-inline-block col-1");
      setIframeClass("d-none");
      setTimeout(() => {
        setLoadingClass("d-none");
        setIframeClass("d-block");
      }, 1000);
    }
  }, [show]);

  return (
    <div className="container container-faq mt-3">
      <div className="row">
        <h1 className=" col-12 col-md-10">¿En qué podemos ayudarte?</h1>
        {iframeSrc && (
          <Button
            variant="outline-danger"
            className="mb-3 col-6 col-md-2 h-50"
            onClick={handleShow}
          >
            Reportar Error
          </Button>
        )}
      </div>
      <>
        <Modal show={show} onHide={handleClose} dialogClassName="modal-faq">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="row justify-content-center align-items-center">
            <div className={loadingClass}>
              <LoadingBox></LoadingBox>
            </div>
            <iframe className={iframeClass} src={iframeSrc}></iframe>
          </Modal.Body>
        </Modal>
        {questions.length ? (
          <Accordion className="mt-1">
            {questions.map(({ question, answer, active }, index) => {
              return (
                active && (
                  <FaqQuestion
                    key={index}
                    question={question}
                    answer={answer}
                    eventKey={index}
                  ></FaqQuestion>
                )
              );
            })}
          </Accordion>
        ) : (
          <div className="row justify-content-center align-items-center">
            <LoadingBox></LoadingBox>
          </div>
        )}
      </>
    </div>
  );
}
export default FaqPage;
