import React, { useEffect, useReducer } from "react";
import Product from "../../components/Product/Product.jsx";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MessageBox from "../../components/MessageBox/MessageBox";
import BootstrapCarouselComponent from "../../components/Carousel/Carousel.jsx";
import productService from "../../services/product.service.js";
import "./HomeScreen.css";
import "../Screens.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, products: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  // const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const result = await productService.list();;
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: error.message });
    }
    // setProducts(result.data);
  };
  return (
    <div>
      <BootstrapCarouselComponent/>

      <section className="products">
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {products.some(p => p.highlightedProduct && p.active) && 
            <Row className="w-100">
              <h1 className="text-center col-12 titles">Productos destacados</h1>
            </Row>}
            <Row className="w-100 px-1">
              {products.map((product) =>
                product.highlightedProduct && product.active ? (
                  <Col
                    key={product.slug}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    className="mb-3"
                  >
                    <Product product={product}></Product>
                  </Col>
                ) : null
              )}
            </Row>
          </>
        )}
      </section>
    </div>
  );
}
export default HomeScreen;
