import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { Store } from "../../Store";
import "./AddressesScreen.css";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import AddressBook from "../../components/AddressBook/AddressBook";
import { getError } from "../../utils";
import { toast } from "react-toastify";
import _addresses from "../../services/address.service";

export default function AddressesScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const res = await _addresses.list(userInfo._id);
      setAddresses(res.data);
      setLoading(false);
    } catch (ex) {
      console.error(ex);
      toast.error(getError(ex));
      setLoading(false);
    }
  };

  return (
    <Container className="addresses-screen">
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : (
        <AddressBook
          userInfo={userInfo}
          addresses={addresses}
          setAddresses={setAddresses}
          title="Mis direcciones"
        ></AddressBook>
      )}
    </Container>
  );
}
