import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Store } from "../../../Store";
import _utils from "../../../services/utils.service";

export default function ConfirmDeleteMethodModal({
  deleteShow,
  handleClose,
  method,
  onHide,
}) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const deleteMethod = async () => {
    try {
      await _utils.deleteMethod(method._id);
      toast.success("Metodo eliminado");
      handleClose();
    } catch (ex) {
      toast.error("Error");
      console.error(ex);
    }
  };
  return (
    <Modal show={deleteShow} onHide={onHide} dialogClassName="modal-delete-pay">
      <Modal.Header closeButton>
        <Modal.Title>Borrar método</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que quieres eliminar este método?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={deleteMethod}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
