import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPlusCircle, FaFileUpload, FaFilter } from 'react-icons/fa';
import { TbExchange } from "react-icons/tb";
import { BsArrowLeftCircle, BsCheck, BsX} from 'react-icons/bs';
import { Card, Col, Modal, Button } from 'react-bootstrap';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { useContext } from 'react';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { hasPermission, toCamelCase } from '../../utils';
import { Helmet } from 'react-helmet-async';
import './PendingApprovals.css';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';
import { SCOPES } from '../../permission-utils/scopes.js';
import DynamicListTable from '../../components/DynamicListTable';
import FormFilter from '../../components/FormFilter/FormFilter.jsx';
import FlapService from '../../services/flap.service.js'; 
import Tab from '../../components/Tabs/Tabs.jsx';
import useQueryFilters from '../../hooks/useQueryFilters.jsx';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      const {flaps, pendingCount, rejectedCount, 
        approvedCount, allCount} = action.payload;
      return {
        ...state,
        loading: false,
        flaps,
        pendingCount,
        rejectedCount,
        approvedCount,
        allCount
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false, successDelete: false };
    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    case 'OPEN_DETAILS_MODAL':
      return { ...state, selectedId: action.payload, modalIsOpen: true };
    case 'CLOSE_MODAL':
      return { ...state, selectedId: null, modalIsOpen: false };
    default:
      return state;
  }
};

function AdminFLAPs() {
  const [reducerState,
    dispatch,
  ] = useReducer(reducer, {
    flaps: [],
    loading: true,
    error: '',
    modalIsOpen: false,
    selectedId: null,
    itemQuantity: 0,
    loadingDelete: false,
    successDelete: false,
    pendingCount: 0,
    rejectedCount: 0,
    approvedCount: 0,
    allCount: 0
  });
  const
    {
      loading,
      error,
      flaps,
      successDelete,
      itemQuantity,
      allCount
    } = reducerState;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [comment, setComment] = useState(''); 


	const statuses = [
		{ label: 'Todos', value: 'All' },
		{ label: 'Pendientes', value: 'Pending' },
		{ label: 'Aprobados', value: 'Approved' },
		{ label: 'Rechazados', value: 'Rejected' },
	];

  const { setPageSize, setPage, setFieldsAndSubmit, fields, siblingCount } =
    useQueryFilters({ baseUrl: '/AdminScreen/Adminflaps', resizeWidth: 400 });

  const getTotalCurrentCount = () => fields.status ? reducerState[toCamelCase(fields.status) + 'Count'] : allCount;

  useEffect(() => {
    const currTotalCount = getTotalCurrentCount();
    if (parseInt(fields.page) > Math.ceil(currTotalCount / fields.pageSize)) {
      setPage(1); // Reset page if it's out of bounds
    }
  }, [fields, allCount]);


  const seeDetailsHandler = (flap) =>
    dispatch({ type: 'OPEN_DETAILS_MODAL', payload: flap._id });

  useEffect(() => {
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
  }, [successDelete, search, dispatch]);

  const fetchData = async () => {
    dispatch({ type: 'FETCH_REQUEST' });
    try {
      const { data }  = await FlapService.list(searchParams);
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_FAIL', payload: error.message });
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setComment('');
  };
  const openModal = (action, item) => {
    setModalAction(action);
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirm = async (action) => {
    closeModal();
    if (action === 'accept') {
      await acceptChangeConfirm(selectedItem, comment); 
    } else if (action === 'reject') {
      await rejectChangeConfirm(selectedItem, comment);
    }
  };

  async function deleteHandler(flap) {
    if (window.confirm('Are you sure you want to delete this FLAP?')) {
      try {
        await FlapService.delete(flap._id);
        toast.success('FLAP deleted successfully');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        dispatch({ type: 'DELETE_FAIL' });
        console.error(error);
        toast.error('Error. Could not delete the FLAP');
      }
    }
  }

  const acceptChangeConfirm = async (flap, comment) => {
    try {
      await FlapService.acceptChange(flap._id, comment); 
      toast.success('Change accepted successfully');
      dispatch({ type: 'DELETE_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'DELETE_FAIL' });
      console.error(error);
      toast.error('Error. Could not accept the change');
    }
  };
  
  const rejectChangeConfirm = async (flap, comment) => {
    try {
      await FlapService.rejectChange(flap._id, comment);
      toast.success('Change rejected successfully');
      dispatch({ type: 'DELETE_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'DELETE_FAIL' });
      console.error(error);
      toast.error('Error. Could not reject the change');
    }
  };

  const [actions, setActions] = useState({
    deleteHandler: hasPermission(userInfo.role, 'products', [
      SCOPES.canDelete,
    ])
      ? deleteHandler
      : null,
    previewHandler: seeDetailsHandler,
    acceptChangeHandler: (item) => openModal('accept', item),
    rejectChangeHandler: (item) => openModal('reject', item),
    verifyHandler: (item) => openModal('verify', item),

  });

  return (
    <>
       <Modal
        show={showModal}
        centered
        onHide={closeModal}
        dialogClassName="verify-flap-modal"
      >
        <Modal.Header>
        <h5 className="modal-title"><strong>Aceptar o rechazar cambios</strong></h5>

          <Button variant="link" onClick={closeModal} className="close-btn">
            <BsX className="close-icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <table className="table mt-3">
              <thead>
                <tr>
                  <th>Registro</th>
                  <th>Campo</th>
                  <th>Valor Actual</th>
                  <th>Nuevo Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItem.record.name}</td>
                  <td>{selectedItem.field}</td>
                  <td>{selectedItem.currentValue}</td>
                  <td>{selectedItem.newValue}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div>
            <label htmlFor="comments"><strong>Comentarios</strong> (opcional):</label>
            <textarea id="comments" className="comments-form-control" placeholder="Deja tus comentarios..."    value={comment} 
    onChange={(e) => setComment(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button className="reject-btn" onClick={() => { handleConfirm('reject'); }}>
            <BsX className="icon-confirm" /> Rechazar
          </Button>
          <Button className="accept-btn" onClick={() => { handleConfirm('accept'); }}>
            <BsCheck className="icon-confirm" /> Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>Control de cambios</title>
      </Helmet>
      <div id="admin-flaps-container" className="admin-con p-3 pt-2">
        <h1 className="text-right section-title">
          <TbExchange /> {" "}Control de cambios
        </h1>
     
        	<div className="tabs">
					<div className="tabs-container" style={{ width: '80%' }}>
						{statuses.map(status => (
							<Tab
								key={status.value}
								status={status.label}
                showQuantityInBadge={status.value === "Pending"}
								active={fields.status === status.value || (!fields.status && status.value.toLowerCase() === 'all')}
								onClick={() => setFieldsAndSubmit({status: status.value})}
                count={reducerState[toCamelCase(status.value) + 'Count']}

							/>
						))}
					</div>
	
				</div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Card>
            {/* <FormFilter /> */}
            {flaps.length > 0 ? (
              <DynamicListTable
                openProductModal={seeDetailsHandler}
                data={flaps}
                loading={loading}
                error={error}
                count={itemQuantity}
                totalCount={getTotalCurrentCount()}
								itemsPerPage={fields.pageSize}
								setPageSize={setPageSize}
								currentPage = {fields.page}
								onPageChange={setPage}
                actionButtons={actions}
                dataName="flaps"
                showCheckboxColumn={false}
                links={true}
                exportOption={false}
                siblingCount={siblingCount}
                frontPaginator={true}
              />
            ) : (
              <MessageBox className={'my-2'}>
                No pending approvals found
              </MessageBox>
            )}
          </Card>
        )}
      </div>
    </>
  );
}

export default AdminFLAPs;
