import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './UpdatePricesModal.css';
import {toast} from 'react-toastify'; 

function UpdatePricesModal({ show, handleClose, handleUpdatePrices, selectedProducts }) {
    const [mode, setMode] = useState('percentage'); // 'percentage' or 'amount'
    const [adjustment, setAdjustment] = useState('aumento'); // 'aumento' or 'descuento'
    const [value, setValue] = useState('');
    const [products, setProducts] = useState([]);
    const [enableButton, setEnableButton] = useState(false);
    

    useEffect(() => {
        setProducts(selectedProducts);
    }, [selectedProducts]);

    const resetModalState = () => {
        setMode('percentage');
        setAdjustment('aumento');
        setValue('');
        setEnableButton(false)
    };
    const handleModalClose = () => {
        resetModalState(); 
        handleClose();
    };

    const handleModeChange = (newMode) => {
        setMode(newMode);
        setValue('');
    };

    const handleAdjustmentChange = (newAdjustment) => {
        setAdjustment(newAdjustment);
        setValue('');
    };

    const handleValueChange = (e) => {
        setValue(e.target.value);
    };

    const handleCalculatePrices = () => {
        const numericValue = parseFloat(value);
        if(!isNaN(numericValue) || numericValue > 0) {
        const updatedProducts = products.map((product) => {
            let newPrice = product.price;
            if (mode === 'percentage') {
                if (adjustment === 'aumento') {
                    newPrice = product.price * (numericValue / 100 + 1);
                } else if (adjustment === 'descuento') {
                    newPrice = product.price * (1 - numericValue / 100);
                }
            } else if (mode === 'amount') {
                if (adjustment === 'aumento') {
                    newPrice = product.price + numericValue;
                } else if (adjustment === 'descuento') {
                    newPrice = product.price - numericValue;
                }
            }
            newPrice = Math.round(newPrice * 100) / 100;
            return { ...product, newPrice };
        });
        setProducts(updatedProducts);
        setEnableButton(true);
    } else {
        toast.error("Debe ingresar un valor numérico mayor a 0");
    }
    };

    const handleSubmit = () => {
        handleUpdatePrices(products);
        handleModalClose();
        setProducts([]);
    };

    const getPlaceholderText = () => {
        const action = adjustment === 'aumento' ? 'Incrementar' : 'Descuento';
        return `${action} por ${mode === 'percentage' ? 'porcentaje' : 'monto'}`;
    };

    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName='update-prices-modal'>
            <Modal.Header closeButton>
                <Modal.Title className='text-center w-100'>Modificar Precios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-around">
                    <div className="d-flex flex-wrap w-100 justify-content-around">
                        <div className="switch-container">
                            <div className="toggle-button-group mb-3">
                                <button
                                    className={`toggle-button ${mode === 'percentage' ? 'active' : ''}`}
                                    onClick={() => handleModeChange('percentage')}
                                >
                                    Porcentaje
                                </button>
                                <button
                                    className={`toggle-button ${mode === 'amount' ? 'active' : ''}`}
                                    onClick={() => handleModeChange('amount')}
                                >
                                    Monto
                                </button>
                            </div>

                        </div>
                        <div className='switch-container'>
                            <div className="toggle-button-group mb-3">
                                <button
                                    className={`toggle-button ${adjustment === 'aumento' ? 'active' : ''}`}
                                    onClick={() => handleAdjustmentChange('aumento')}
                                >
                                    Aumento
                                </button>
                                <button
                                    className={`toggle-button ${adjustment === 'descuento' ? 'active' : ''}`}
                                    onClick={() => handleAdjustmentChange('descuento')}
                                >
                                    Descuento
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Form className='d-flex justify-content-center w-100'>
                    <Form.Group controlId="formValue" className="d-flex justify-content-center align-items-center w-100">
                        <Form.Control
                            type="number"
                            placeholder={getPlaceholderText()}
                            value={value}
                            onChange={handleValueChange}
                            className="custom-input"
                        />
                    </Form.Group>
                </Form>
                <div className="d-flex justify-content-center">
                    <Button className='apply-modification' onClick={handleCalculatePrices}>Aplicar</Button>
                </div>

                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Precio Actual</th>
                                <th>Nuevo Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name} {item.variant && `- ${item.variant.name}`}</td>
                                    <td>{item.price}</td>
                                    <td>{item.newPrice !== undefined ? item.newPrice : null}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={!enableButton}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UpdatePricesModal;
