import React, {useEffect, useReducer } from "react";
import "./EmailConfig.css";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { getError, getStatus, regexEmail } from "../../utils";
import BtnPlus from "../PlusButton/BtnPlus";
import _settings from "../../services/settings.service";

export default function EmailConfig() {

  const [{ loading, error, emails, successNewEmail, successDeleteEmail },
    dispatch,
  ] = useReducer(reducer, {
    emails: [],
    loading: true,
    error: "",
    successNewEmail: false,
    successDeleteEmail: false
  });

  useEffect(() => {
    if (loading || successNewEmail || successDeleteEmail) {
      fetchInfo();
    }
  }, [successNewEmail,successDeleteEmail]);

  //#region REQUESTS
  const fetchInfo = async () => {
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const res = await _settings.getAdminEmails();
      dispatch({ type: "FETCH_SUCCESS", payload: res.data });
    } catch (ex) {
      dispatch({ type: "FETCH_FAIL", payload: ex });
      toast.error("No se pudo obtener los e-mails.")
      console.error(ex);
    }
  };
  const addEmailHandler = async (e) => {
    e.preventDefault();
    const { value: email } = e.target.elements.email_to_add;
    if (regexEmail.test(email)) {
      try {
        await _settings.createAdminEmail(email);
        dispatch({ type: "NEW_EMAIL_SUCCESS" })
        toast.success("Debe validar su e-mail. Revise su correo.")
      } catch (ex) {
        console.error(ex);
        toast.error(getError(ex));
      }
    } else {
      toast.error("Direccion de e-mail inválida.");
    }
  };
  const deleteEmailHandler = async (id) => {
    try {
      await _settings.deleteAdminEmail(id);
      dispatch({type : "DELETE_EMAIL_SUCCESS"});
      toast.success("E-mail eliminado con éxito.")
    } catch (ex) {
      toast.error("No se pudo eliminar el e-mail")
    }
  };
  //#endregion 

  return (
    <div className="email-config">
      <p>Agregue aquí los correos electrónicos de los administradores.</p>
      <Form className="row align-items-center" onSubmit={addEmailHandler}>
        <Form.Group className="col-lg-6 col-10" controlId="email_to_add">
          <Form.Control
            name="email_to_add"
            required
          ></Form.Control>
        </Form.Group>
        <BtnPlus type="submit"></BtnPlus>
      </Form>
      <div className="container  ps-0 mt-2">
        
        <ListGroup className="mb-1 email-list">
          {emails.length > 0 &&
            emails.map(({ email,verified, _id }, index) => {
              return (

                      <ListGroup.Item key={index} id={ _id}>
                        <Row className="justify-content-between px-2">

                          <Col xs={11} md = {10} className="py-1">
                            {email}
                            <Badge className = {"ms-1 " + (verified ? "email-check" : "email-pending")}>
                              {
                                verified ? 
                                "Verificado" :
                                "Pendiente"
                              }
                            </Badge>
                          </Col>
                          <Col
                            xs = {1} md={2}
                            className="d-flex align-items-center justify-content-around p-0"
                          >
                            <button
                              onClick={() => deleteEmailHandler(_id)}
                              className="action-button"
                            >
                              <FaTrash/>
                            </button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
              );
            })}
        </ListGroup>
      </div>
    </div>
  );
}
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, successNewEmail: false, successDeleteEmail: false };
    case "FETCH_SUCCESS":
      return { ...state, emails: action.payload, loading: false, };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "NEW_EMAIL_SUCCESS":
      return { ...state, successNewEmail: true };
    case "DELETE_EMAIL_SUCCESS":
      return { ...state, successDeleteEmail: true };
    default:
      return state;
  }
};
