import React from "react";
import Spinner from "react-bootstrap/Spinner";
import classnames from 'classnames'
function LoadingBox({size}) {
  return (
    <Spinner size={size} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
export default LoadingBox;
