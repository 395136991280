import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useReducer,
	useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { getError, getStatus } from '../../utils';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import moment from 'moment';
import MessageBox from '../../components/MessageBox/MessageBox';
import './placeOrderScreen.css';
import _orders from '../../services/order.service.js';
import _settings from '../../services/settings.service.js';
import _utils from '../../services/utils.service.js';
import CheckoutNavigator from '../../components/CheckoutNavigator/CheckoutNavigator.jsx';
import _discountCodes from '../../services/discountcodes.service.js';
import _mercadopago from '../../services/mercadopago.service.js';
import _paymentMethods from '../../services/utils.service.js';
import { getDiscountAmount, getPaymentMethodCharge, getTaxAmount, getSubtotal, getTotal } from '../../utils/pricesUtils.js';


const reducer = (state, action) => {
	switch (action.type) {
	case 'CREATE_REQUEST':
		return { ...state, loading: true };
	case 'CREATE_SUCCESS':
		return { ...state, loading: false };
	case 'CREATE_FAIL':
		return { ...state, loading: false };
	default:
		return state;
	}
};

function PlaceOrderScreen() {
	const navigate = useNavigate();
	const [{ loading }, dispatch] = useReducer(reducer, {
		loading: false,
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart, usesShipping, userInfo } = state;
	let {shippingAddress, orderDeliveryDetails} = cart;
	const [missingStock, setMissingStock] = useState('');
	const [discountPercent, setDiscountPercent] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [chargeAmount, setChargeAmount] = useState(0);
	const [chargePercent, setChargePercent] = useState(0);
	const [cartItemsCopy] = useState(cart?.cartItems); //used only to display the cart items

	useEffect(() => {
		if(!cart?.cartItems.length) {
			navigate("/");
		}
		initData();
	},[]);
	
	const initData = async () => {
		try {
			ctxDispatch({type: 'SHOW_LOADING_OVERLAY'});
			if(cart.paymentMethod.usesPaymentGateway) {
				await placeOrderHandler();
			} else {
				if (usesShipping) {
					checkShippingInfo();
				}
				if (cart.discountCoupon) {
					await getDiscountData();
				}
				ctxDispatch({ type: 'HIDE_LOADING_OVERLAY' });
			}
			
		} catch(ex) {
			console.error(ex);
			toast.error(getError(ex));
			ctxDispatch({type: 'HIDE_LOADING_OVERLAY'});
		}
	}
	const checkShippingInfo = () => {
			if (!shippingAddress.hasOwnProperty('addressLine1') || !orderDeliveryDetails) {
				navigate('/checkout/shipping');
			} else {
				shippingAddress = null;
			}
	};

	const getPaymentMethodData = async (cartItems, paymentMethod, discountPercent) => {
		const pm = await _paymentMethods.getPaymentMethod(paymentMethod);
		if (pm) {
			const charge = getPaymentMethodCharge(cartItems, pm.data.chargePercent, discountPercent);
			setChargeAmount(charge);
			setChargePercent(pm.data.chargePercent);
		}

	};

	const getDiscountData = async () => {
		const discount = await _discountCodes.getByCode(cart.discountCoupon);
		if (discount) {
			const amountDiscount = getDiscountAmount(cart.cartItems, discount.data.discountPercent);
			setDiscountAmount(amountDiscount);
			setDiscountPercent(discount.data.discountPercent);
		}
		if (cart.paymentMethod == 'MercadoPago' || cart.paymentMethod == 'MercadoPagoCuotas') {
			await getPaymentMethodData(cart.cartItems, cart.paymentMethod, discount?.data.discountPercent);
		}
	};

  
	cart.taxPrice = getTaxAmount(cart.cartItems, 22);
	cart.subtotalPrice = getSubtotal(cart.cartItems);
	cart.totalPrice = getTotal(cart.cartItems, chargePercent, discountPercent);

	//TODO: hacer esto en clase (a crear) Order
	const orderValidator = () => {    
		const {
			cartItems: orderItems,
			paymentMethod,
			itemsPrice,
			taxPrice,
			subtotalPrice,
		} = cart;
		return (
			orderItems.length &&
      (!usesShipping || shippingAddress) &&
      (!usesShipping || deliveryDetailsValidator(orderDeliveryDetails)) &&
      paymentMethod &&
      itemsPrice &&
      taxPrice &&
      subtotalPrice 
		);
	};

	const deliveryDetailsValidator = (deliveryDetails) => {
		return deliveryDetails &&
      (!deliveryDetails.comments || deliveryDetails.comments.length <= 500) &&
      deliveryDetails.timeForDelivery &&
      deliveryDetails.shippingDate;
	};



	async function uploadProofOfPayment() {
		let doc;
		try {
			const file = cart.paymentProof[0]; 
			if (!file) {
				console.error('No file found in cart.paymentProof');
				return;
			}
   
			const formData = new FormData();
			let r = Math.random().toString(36).substring(7);
			formData.append('file', file, r);
			const response = await _orders.uploadCOmprobante(formData);
			doc = response.data;
		} catch (ex) {
			console.error('Error uploading payment proof:', ex);
		}
		return doc;     
	}

	const placeOrderHandler = async (e = null) => {
		if(e) {
			e.preventDefault();
		}
		if (orderValidator()) {
			ctxDispatch({ type: 'SHOW_LOADING_OVERLAY' });
			dispatch({ type: 'CREATE_REQUEST' });           
			try{  
				const newOrder = {
					orderItems: cart.cartItems,
					paymentMethod: cart.paymentMethod.method,
					itemsPrice: cart.itemsPrice,
					taxPrice: cart.taxPrice,
					subtotalPrice: cart.subtotalPrice,
					seller: cart.seller,
					discount: cart.discountCoupon,
          			totalPrice: cart.totalPrice,
					discountedAmount: discountAmount,
					paymentMethodChargedAmount: chargeAmount
				};
				if(usesShipping) {
					const {name: fullName, addressLine1: address,
						addressLine2: streetCorner, city,state,country,
						phone: telephone} = shippingAddress;
					newOrder.shippingAddress = {
						fullName,
						address,
						streetCorner,
						city,
						state,
						country,
						telephone
						, ...orderDeliveryDetails};
				} else {
					newOrder.noShipping = true;
				}
				const paymentMethodRequest = await _utils.getPaymentMethod(cart.paymentMethod.method);
				if(paymentMethodRequest.data && paymentMethodRequest.data.uploadAttachment) {
					const proofOfPayment = await uploadProofOfPayment();
					if(proofOfPayment) {
						newOrder.proofOfPayment = proofOfPayment;
					} else {
						navigate('/payment');
					}
				}
				const { data } = await _orders.create(newOrder, paymentMethodRequest?.data.usesPaymentGateway);
				const {order, paymentLinkData} = data.response;
				if (paymentLinkData) {
					await handleMpCheckout(paymentLinkData, order)
				} else {
					navigate(`/order/${order._id}`);
					ctxDispatch({ type: 'HIDE_LOADING_OVERLAY' });
				}
				cleanCart();
			} catch (err) {
				dispatch({ type: 'CREATE_FAIL' });
				if(getStatus(err) == 400 && err.response.data.missingStock) {
					setMissingStock(err.response.data.missingStock);
				} else {
					toast.error('Error. No se pudo crear la orden');
					console.error(err);
				}
				ctxDispatch({ type: 'HIDE_LOADING_OVERLAY' });
			}
		} else {
			toast.error('Error. Revise los datos.');
		}
	};
	const cleanCart = () => {
		ctxDispatch({ type: 'CART_CLEAR' });
		ctxDispatch({ type: 'CLEAR_ORDER_SELLER' });
		ctxDispatch({ type: 'CLEAR_ORDER_COUPON' });
		dispatch({ type: 'CREATE_SUCCESS' });
		localStorage.removeItem('cartItems');
		localStorage.removeItem('seller');
		localStorage.removeItem('discountCoupon');
	}
	const handleMpCheckout = async (paymentLinkData, order) => {
		const checkout = paymentLinkData.init_point;
		try {
			await _mercadopago.createTransaction(order._id, paymentLinkData.id);
			await _orders.setMercadoPagoStatusAsPending(order._id);
			location.replace(checkout);
		} catch (ex) {
			console.error(ex);
		}
	};

	return (
		<div id="place-order-screen">
			<Helmet>
				<title>Pedido</title>
			</Helmet>
			<h1 className="my-3">Pedido</h1>
			<Row>
				<Col md={8}>
					{orderDeliveryDetails && shippingAddress && <Card className="mb-3">
						<Card.Body>
							<Card.Title>Envio</Card.Title>
							<Card.Text>
								<strong>Nombre:</strong> {shippingAddress.name} <br />
								<strong>Localidad:</strong> {shippingAddress.country},
								{shippingAddress.state},{shippingAddress.city}
								<br />
								<strong>Direccion:</strong> {shippingAddress.addressLine1},
								{shippingAddress.addressLine1}
								<br />
								<strong>Telefono:</strong> {shippingAddress.phone}
								<br />
								<strong>Horario de Preferencia:</strong>
								{orderDeliveryDetails.timeForDelivery}
								<br />
								<strong>Fecha de preferencia:</strong>
								{moment(orderDeliveryDetails.shippingDate).format('DD/MM/YYYY')}
								<br />
								<strong>Comentarios:</strong> {orderDeliveryDetails.comments}
							</Card.Text>
							<Link to="/checkout/shipping">Editar</Link>
						</Card.Body>
					</Card> }
					<Card className="mb-3">
						<Card.Body>
							<Card.Title>Pago</Card.Title>
							<Card.Text>
								<strong>Metodo:</strong> {cart.paymentMethod.method}
							</Card.Text>
							<Link to="/checkout/payment">Editar</Link>
						</Card.Body>
					</Card>
					<ListGroup className="cartt">
						{cartItemsCopy.map((item,i) => (
							<ListGroup.Item key={item._id+'-'+ i}>
								<Row className="align-items-center">
									<Col md={2}>
										{item.image && typeof item.image === 'object' ? (
											<img
												src={item.image.fileLink}
												alt={item.name}
												className="img-fluid rounded img-thumbnail"
											></img>
										) : (
											<img
												alt="product_photo"
												src="/images/emptyPhoto.png"
												className="img-fluid rounded img-thumbnail"
											/>
										)}
									</Col>
									{
										item.product ?
											<Col md={2}>
												<Link to={`/product/${item.product.slug}`}>{item.product.name}</Link>
											</Col>
											:
											<Col md={2}>
												<Link to={`/product/${item.slug}`}>{item.name}</Link>
											</Col>
									}
									<Col md={2} className="item-options">
										{item.product ? item.options.map((opt) => (
											<div>{`${opt.option.name}: ${opt.value.value}`}</div>
										)) :
											<div className="text-center">
                    N/A
											</div>
										}
									</Col>
									<Col md={2}>
										<span>Cant: {item.quantity}</span>{' '}
									</Col>
									<Col md={2}>Precio: ${item.price}</Col>
									<Col md={2}>Total: ${item.price * item.quantity}</Col>
								</Row>
							</ListGroup.Item>
						))}
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card>
						<Card.Body>
							<Card.Title>Resumen de Pedido</Card.Title>
							<ListGroup variant="flush">
								<ListGroup.Item>
									<Row>
										<Col>Subtotal</Col>
										<Col>${cart.subtotalPrice.toFixed(2)}</Col>
									</Row>
								
									{discountPercent > 0 ? (
										<Row>
											<Col>Descuento({discountPercent}%)</Col>
											<Col> -${discountAmount}</Col>
										</Row>

									):null}
                  	{cart.paymentMethod.method == 'MercadoPago' || cart.paymentMethod.method == 'MercadoPagoCuotas' ? (
										<Row>
											<Col>MercadoPago</Col>
											<Col>${chargeAmount}</Col>
										</Row>

									) : null}
                   <div className='border mb-1 mt-1'></div>
									<Row>
										<Col><b>Total</b></Col>
										<Col><b>${cart.totalPrice.toFixed(2)}</b></Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
					{missingStock && (
						<Card className="mb-3">
							<Card.Body>
								<MessageBox variant="danger" cols={12}>
									{missingStock.map((msg) => {
										return (
											<>
												{msg} <br />{' '}
											</>
										);
									})}
								</MessageBox>
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<CheckoutNavigator previousStepHandler={() => navigate('/checkout/payment')}
				nextStepHandler={!(cart.cartItems.length === 0 || loading) ?  placeOrderHandler : null}
				activeStep={usesShipping ? 4 : 3} forwardButtonText = "Confirmar pedido" showForwardIcon = {false}/>
		</div>
	);
}

export default PlaceOrderScreen;
