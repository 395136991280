import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import './AdminOrders.css';
import {
	DropdownButton,
	Dropdown,
	Card,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import Tab from '../../components/Tabs/Tabs.jsx';
import { BsXLg, BsSearch } from "react-icons/bs";
import { FaListUl } from 'react-icons/fa';
import { getError, getStatus, hasPermission, toCamelCase } from '../../utils';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';
import { SCOPES } from '../../permission-utils/scopes.js';
import DynamicListTable from '../../components/DynamicListTable';
import _orders from '../../services/order.service.js';
import ExportToExcelButton from '../../components/ExportData/ExportToExcel.js';
import useQueryFilters from '../../hooks/useQueryFilters.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
			const { cancelledCount,
				completedCount,
				orderConfirmedCount,
				pendingPaymentCount,
				paymentConfirmationCount,
				awaitingConfirmationCount,
				orders,
				allCount } = action.payload;
		return {
			...state,
			orders,
			allCount,
			cancelledCount,
			completedCount,
			orderConfirmedCount,
			paymentConfirmationCount,
			pendingPaymentCount,
			awaitingConfirmationCount,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'PAY_REQUEST':
		return { ...state, loadingPay: true };
	case 'PAY_SUCCESS':
		return { ...state, loadingPay: false, successPay: true };
	case 'PAY_FAIL':
		return { ...state, loadingPay: false };
	case 'PAY_RESET':
		return { ...state, loadingPay: false, successPay: false };
	case 'DELIVER_REQUEST':
		return { ...state, loadingDeliver: true };
	case 'DELIVER_SUCCESS':
		return { ...state, loadingDeliver: false, successDeliver: true };
	case 'DELIVER_FAIL':
		return { ...state, loadingDeliver: false };
	case 'DELIVER_RESET':
		return { ...state, loadingDeliver: false, successDeliver: false };
	case 'CANCEL_ORDER_SUCCESS':
		return { ...state, successCancel: true };
	case 'CANCEL_ORDER_RESET':
		return { ...state, successCancel: false };
	default:
		return state;
	}
};

function AdminOrders() {
	const [reducerState, dispatch] = useReducer(reducer, {
		orders: [],
		loading: true,
		error: '',
		successPay: false,
		loadingPay: false,
		loadingDeliver: false,
		successDeliver: false,
		itemQuantity: 0,
		successCancel: false,
		allCount : 0,
		cancelledCount: 0,
		completedCount : 0,
		orderConfirmedCount : 0,
		paidCount : 0,	
		pendingDeliverCount : 0,
		pendingPaymentCount : 0,
		awaitingConfirmationCount: 0
	});
	const {
		loading,
		error,
		orders,
		successPay,
		successDeliver,
		successCancel,
		allCount,} = reducerState;

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [showConfirmPay, setShowConfirmPay] = useState(false);
	const [showCheckboxColumn, setShowCheckboxColumn] = useState(false);
	const [showConfirmOrder, setShowConfirmOrder] = useState(false);
	const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [actionButtons, setActionButtons] = useState(null);
	const [selectedOrders, setSelectedOrders] = useState([]);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const {setPageSize, setPage, setFieldsAndSubmit, fields, siblingCount} =
		useQueryFilters({ baseUrl: '/AdminScreen/AdminOrders', resizeWidth: 400});
	const getTotalCurrentCount = () => fields.status ? reducerState[toCamelCase(fields.status) + 'Count'] : allCount;

	useEffect(() => {
		const currTotalCount = getTotalCurrentCount();
		if (parseInt(fields.page) > Math.ceil(currTotalCount / fields.pageSize)) {
			setPage(1); // Reset page if it's out of bounds
		}
	}, [fields, successCancel, successDeliver, successPay]);

	const navigate = useNavigate();
	// Tab Filtering
	


	const statuses = [
		{ label: 'Todos', value: 'all' },
		{ label: 'Orden a Confirmar', value: 'Awaiting Confirmation' },
		{ label: 'Pendiente de pago', value: 'Pending Payment' },
		{ label: 'Pago a Confirmar', value: 'Payment Confirmation' },
		{ label: 'Entregado', value: 'Completed' },
		{ label: 'Cancelado', value: 'Cancelled' },
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await _orders.list(searchParams);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		fetchData();
		if (successPay) {
			dispatch({ type: 'PAY_RESET' });
		}
		if (successDeliver) {
			dispatch({ type: 'DELIVER_RESET' });
		}
		if (successCancel) {
			dispatch({ type: 'CANCEL_ORDER_RESET' });
		}
		if (!actionButtons) {
			addActionButtons();
		}
	}, [userInfo, successPay, successDeliver, successCancel, search]);




	const addActionButtons = () => {
		const role = userInfo.role;
		const scopes = [SCOPES.canEdit];
		const actions = {};
		if (hasPermission(role, 'orders', scopes, 'deliver')) {
			actions.confirmOrder = handleShowConfirmOrder;
		}
		if (hasPermission(role, 'orders', scopes, 'paid')) {
			actions.confirmPayment = handleShowConfirmPay;
		}
		if (hasPermission(role, 'orders', scopes, 'cancel')) {
			actions.cancelOrder = showCancelOrderHandler;
		}
		setActionButtons(actions);
	};
	const showCancelOrderHandler = (order) => {
		setShowCancelOrderModal(true);
		if (order) {
			setSelectedOrder(order);
		}
	};
	const cancelOrderHandler = async () => {
		setShowCheckboxColumn(false);
		setSelectedOrders([]);
		if (selectedOrder && selectedOrder.active) {
			try {
				await _orders.cancel(selectedOrder._id);
				dispatch({ type: 'CANCEL_ORDER_SUCCESS' });
				toast.success('Pedido cancelado');
			} catch (ex) {
				toast.error('No se pudo cancelar la orden');
				console.error(ex);
			}
		} else {
			toast.error('Error. Solo puedes cancelar pedidos activos previamente cancelada.');
		}
		setShowCancelOrderModal(false);
		setSelectedOrder(null);
	};
	const cancelSelectedOrders = async () => {
		if (selectedOrders.every(order => order.active)) {
			try {
				await _orders.cancelMany(selectedOrders.map(order => order._id));
				dispatch({ type: 'CANCEL_ORDER_SUCCESS' });
				toast.success('Pedido cancelado');
			} catch (ex) {
				toast.error('No se pudo cancelar la orden');
				console.error(ex);
			}
		} else {
			toast.error('Error. Solo puedes cancelar pedidos activos.');
		}
		setShowCancelOrderModal(false);
		setSelectedOrder(null);
		setShowCheckboxColumn(false);
		setSelectedOrders([]);
	};
	async function deliverOrderHandler() {
		setShowCheckboxColumn(false);
		setSelectedOrders([]);
		try {
			dispatch({ type: 'PAY_REQUEST' });
			const { data } = await _orders.setAsDelivered(selectedOrder._id);
			dispatch({ type: 'DELIVER_SUCCESS', payload: data });
			toast.success('Pedido Confirmado');
		} catch (err) {
			dispatch({ type: 'DELIVER_FAIL' });
			if (getStatus(err) == 400 && err.response.data.missingStock) {
				toast.error('Error. No hay Stock suficiente para el pedido.');
			} else {
				console.error(getError(err));
				toast.error('Error.');
			}
		} finally {
			closeConfirmOrder();
		}
	}

	async function paymentOrderHandler() {
		setShowCheckboxColumn(false);
		setSelectedOrders([]);
		try {
			dispatch({ type: 'PAY_REQUEST' });
			const { data } = await _orders.setAsPaid(selectedOrder._id);
			dispatch({ type: 'PAY_SUCCESS', payload: data });
			toast.success('Pedido Pago');
		} catch (err) {
			dispatch({ type: 'PAYMENT_FAIL' });
			console.error(getError(err));
			toast.error('Error.');
		} finally {
			closeConfirmPay();
		}
	}

	async function mpPaymentOrderHandler() {
		setShowCheckboxColumn(false);
		setSelectedOrders([]);
		try {
			dispatch({ type: 'PAY_REQUEST' });
			const { data } = await _orders.setMercadoPagoStatusAsPaid();
			dispatch({ type: 'PAY_SUCCESS', payload: data });
			toast.success('Pedido Pago');
		} catch (err) {
			dispatch({ type: 'PAYMENT_FAIL' });

			console.error(getError(err));
			toast.error('Error.');
		} finally {
			closeConfirmPay();
		}
	}

	function handlePay() {
		if (selectedOrder.paymentMethod.toLowerCase() != 'mercadopago') {
			paymentOrderHandler();
		} else {
			mpPaymentOrderHandler();
		}
	}
	function handleShowConfirmOrder(order) {
		setSelectedOrder(order);
		setShowConfirmOrder(true);
	}
	function handleShowConfirmPay(order) {
		setSelectedOrder(order);
		setShowConfirmPay(true);
	}
	function closeConfirmOrder() {
		setSelectedOrder(null);
		setShowConfirmOrder(false);
	}
	function closeConfirmPay() {
		setSelectedOrder(null);
		setShowConfirmPay(false);
	}
	function closeCancelOrderModal() {
		setShowCancelOrderModal(false);
		setSelectedOrder(null);
	}
	async function seeDetailsHandler(order) {
		navigate(`/order/${order._id}`);
	}

	function handleSelectedOrdersChange(rows) {
		setSelectedOrders(rows);
	}






	return <>
		<Modal
			show={showConfirmOrder}
			onHide={closeConfirmOrder}
			dialogClassName="mt-5"
		>
			<Modal.Body>¿Seguro desea confirmar el pedido?</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<Button variant="primary" onClick={closeConfirmOrder}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={deliverOrderHandler}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
		<Modal
			show={showCancelOrderModal}
			centered
			onHide={closeCancelOrderModal}
			dialogClassName="cancel-order-modal"
		>
			<Modal.Body >
				<p className="text-center">
					{`¿Seguro desea cancelar ${selectedOrder ? 'el pedido' : 'los pedidos'}?`}
				</p></Modal.Body>
			<Modal.Footer className="justify-content-between">
				<Button variant="primary"
					onClick={closeCancelOrderModal}>
					Atrás
				</Button>
				<Button variant="primary" onClick={selectedOrder ? cancelOrderHandler : cancelSelectedOrders}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
		<Modal
			show={showConfirmPay}
			onHide={closeConfirmPay}
			dialogClassName="mt-2"
		>
			<Modal.Body>¿Seguro desea confirmar el pago?</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<Button variant="primary" onClick={closeConfirmPay}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handlePay}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>

		<Helmet>
			<title>Lista de Pedidos</title>
		</Helmet>
		<div id="admin-orders-container" className="admin-con p-3">
			<h1 className="text-right section-title" style={{ marginBottom: '2.5%' }}>
				<FaListUl></FaListUl>{' '}Lista de Pedidos
			</h1>
			<Card>
				<div className="tabs">
					<div className="tabs-container" style={{ width: '80%' }}>
						{statuses.map(status => (
							<Tab
								key={status.value}
								status={status.label}
								showQuantityInBadge={status.value === "Awaiting Confirmation"}
								active={fields.status === status.value || (!fields.status && status.value === 'all')}
								onClick={() => setFieldsAndSubmit({status: status.value})}
								count={reducerState[toCamelCase(status.value) + 'Count']}
							/>
						))}
					</div>
					<ExportToExcelButton
						dataFetcherFunction={_orders.list.bind(this,searchParams,true)} 
						data={orders}
						dataName={'orders'}
						overlayPlacement='top'
					/>
				</div>

				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : orders.length > 0 ? (		
					<>
					            <div className="action-bar d-flex justify-content-between align-items-center">

					<PermissionWrapper scopes={[SCOPES.canEdit]} field="cancel" fn="orders">
					<div className="d-flex align-items-center">

					<Button
              className='button-cancel'
              onClick={cancelSelectedOrders}
              disabled={selectedOrders.length === 0}
            >
              Cancelar Ordenes
            </Button>
              </div>
							{/* <div className="search-bar">
                                    <input 
                                        type="text" 
                                        placeholder="Buscar pedidos..." 
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <Button className="search-button"><BsSearch/></Button> 
                                </div> */}
								</PermissionWrapper> 
							</div>
					<DynamicListTable
						onChangeSelectedRows={handleSelectedOrdersChange}
						checkAllBoxes={true}
						showCheckboxColumn={true}
						data={orders}
						totalCount={getTotalCurrentCount()}
						itemsPerPage={fields.pageSize}
						setPageSize={setPageSize}
						currentPage = {fields.page}
						onPageChange={setPage}
						loading={loading}
						error={error}
						actionButtons={actionButtons}
						dataName="orders"
						links={true}
						exportOption={false}
						siblingCount={siblingCount}
						frontPaginator={true} 
						selectedItems={selectedOrders}
						setSelectedItems={setSelectedOrders}
						/>
						
						</>
		
				) :
					<MessageBox cols={10} variant='secondary'>
					No se encontraron pedidos
					</MessageBox>}
			</Card>
		</div>
	</>;

}

export default AdminOrders;
