import React, { useContext, useEffect, useState } from 'react'
import "./CartItem.css";
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../../Store';
import Badge from "react-bootstrap/Badge";
import _products from "../../../services/product.service.js";
import _productVariants from "../../../services/productVariant.service.js";
import { toast } from 'react-toastify';
import { BsTrash3 } from "react-icons/bs";
import QuantityPicker from '../../QuantityPicker/QuantityPicker.jsx';


export default function CartItem({ item, canChangeQuantity, canDeleteItem, removeItemHandler, showQuantityBadge}) {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(parseInt(item.quantity));
    const [loadingChangeQuantity, setLoadingChangequantity] = useState(false);
    const goToProduct = (slug) => {
        closeCart();
        navigate(`/product/${slug}`);
    }
    const { state, dispatch } = useContext(Store);
    const cartItems = state.cart.cartItems;

    const closeCart = () => {
        dispatch({ type: "CLOSE_CART"});
    }
    const changeProductQuantity = async () => {
        const productRequest = item.product ? _productVariants.get : _products.get;
        try {
            const { data: prod } = await productRequest(item._id);
            if (prod.countInStock > quantity) {
                const product = { ...prod, quantity };
                dispatch({ type: "CART_ADD_ITEM", payload: product });
                dispatch({ type: "OPEN_CART" });
            } else {
                toast.warning("El stock no es suficiente.");
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoadingChangequantity(false);
        }
    };

    useEffect(() => {
        if(loadingChangeQuantity) {
            changeProductQuantity();
        }
    },[loadingChangeQuantity])
    const addOne = () => {
        setQuantity(quantity  => quantity + 1 );
        setLoadingChangequantity(true);
    }
    const removeOne = () => {
        if (quantity > 1) {
            setQuantity(quantity => quantity - 1);
            setLoadingChangequantity(true);
        } else {
            removeItemHandler(item);
        }
    }
    return <div className='d-flex w-100 cart-item'>
        <a onClick={goToProduct.bind(this, item.slug)} className='cart-item-preview col-3 '>
{ showQuantityBadge &&<Badge>
                {item.quantity}
            </Badge>}
            {item.image && typeof item.image === "object" ? (
                <img
                    className='img-fluid'
                    src={item.image.fileLink}
                    alt={item.name}
                />
            ) : (
                <img
                    className='empty img-fluid'
                    src="/images/emptyPhoto.png"
                    alt="product_photo"
                />
            )}

        </a>
        <div className="col-6 px-2">
            {item.product ?
                <a className='item-name' onClick = {goToProduct.bind(this,item.product.slug)}>{item.product.name}</a> :
                <a className='item-name' onClick = {goToProduct.bind(this,item.slug)}>{item.name}</a>}
            <div className="item-data">
                <div> ${item.price}</div>
                {item.options && item.product && item.options.map((opt) => (
                        <span key={`${opt.option.name}: ${opt.value.value}`} className='d-inline-block pe-1 item-option'>{`${opt.option.name}: ${opt.value.value}`}</span>
                ))}
            </div>
        </div>
        <div className="col-3 d-flex flex-column justify-content-between align-items-center">
        { canChangeQuantity && <QuantityPicker quantity={item.quantity} onDecrement={removeOne} onIncrement={addOne}/>}
            <button  className={classnames("delete-button", {invisible : !removeItemHandler || !canDeleteItem })} 
                    onClick={() => canDeleteItem && removeItemHandler(item)}>
                    <BsTrash3/>
            </button>
        </div>
    </div>
}
