import React from "react";
//import "../../App.css";
import { useEffect, useLayoutEffect, useReducer, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import "./Footer.css";
import classnames from 'classnames';
import _settings from "../../services/settings.service";

const Footerr = ({className}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_COMPANY_REQUEST":
        return { ...state, loading: true };
      case "FETCH_COMPANY_SUCCESS":
        return { ...state, GotaMarketSettings: action.payload, loading: false };
      case "FETCH_COMPANY_FAIL":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  const [{ loading, GotaMarketSettings }, dispatch] = useReducer(reducer, {
    GotaMarketSettings: {},
    loading: true,
    error: "",
  });

  const [imageURL, setImageURL] = useState();
  const [companyName, setCompanyName] = useState("");
  const location = useLocation();
  const route = location.pathname;

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_COMPANY_REQUEST" });
        const { data } = await _settings.getPublicData();
        setImageURL(data.image ? data.image.fileLink : "");
        dispatch({ type: "FETCH_COMPANY_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_COMPANY_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, []);



  if (route.includes("AdminScreen")) return null;

  return (
    <footer className={classnames("footer-bs",{[className] : className, "d-none": route.includes("checkout")})}>
      <div className="row">
        <div className="col-12 footerbrand-img col-lg-1">
          <img src={imageURL} alt={`${GotaMarketSettings.companyName} Logo`} />
        </div>
        <div className="col-12 footerbrand col-lg-3">
          <h5>{GotaMarketSettings.companyName}</h5>
          <p className="footerbrand-sub">{GotaMarketSettings.companysubName || ""}</p>
          <p className="footerbrand-info">
            {GotaMarketSettings.companyAddress || ""}
            <br></br>
            {GotaMarketSettings.companyCity || ""}
            <br></br>
            {GotaMarketSettings.companyPhone ? `Tel :${GotaMarketSettings.companyPhone}`:  ""}
          </p>
        </div>
        <div className={classnames("col-lg-2 col-12", { ["footer-map-links"]: GotaMarketSettings.googleMapLocationUrl ||
       GotaMarketSettings.deliveryMapUrl})}>
          { (GotaMarketSettings.googleMapLocationUrl ||GotaMarketSettings.deliveryMapUrl ) && 
            <div className="row">
              <p className="footer-sub">Nosotros</p>
              <ul>
                <li>
                  {" "}
                  {GotaMarketSettings.googleMapLocationUrl && <Link className="nav-link" to="/Contacto">
                    Contacto
                  </Link>}
                </li>
                <li>
                  {GotaMarketSettings.deliveryMapUrl && <Link className="nav-link" to="/Envios">
                    Envios
                  </Link>}
                </li>
              </ul>
            </div>
          }
        </div>

        <div className="col-lg-2 col-12">
          <div className="row">
            <p className="footer-sub">Links Utiles</p>
            <ul>
              <li>
                {" "}
                <Link className="nav-link" to="/">
                  Inicio
                </Link>
              </li>
              <li>
                {" "}
                <Link className="nav-link" to="/search">
                  Productos
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          {/* <div className="row">
            <Newsletter></Newsletter>
          </div> */}
        </div>
      </div>
      { GotaMarketSettings.whatsappUrl && 
        <a
        href={GotaMarketSettings.whatsappUrl}
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
        >
        <BsWhatsapp color="white" />
      </a>
      }
    </footer>
  );
};

export default Footerr;
