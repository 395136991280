import React from 'react'
import Stepper from '../../components/Stepper/Stepper'
import classnames from 'classnames';

export default function VariantGenerationWizardStepper({ className, activeStep = 1 }) {
    const STEPS = [
    {
        counter: 1,
        name: 'Elige producto base',
    },
    {
        counter: 2,
        name: 'Indica opciones',
    },
    {
        counter: 3,
        name: 'Variantes',
    }
];
  return (
      <Stepper className={classnames('wizard-stepper', { [className]: className })}
          steps={STEPS} activeStep={activeStep} />
  )
}
