import React, { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import { Helmet } from "react-helmet-async";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { MdClose, MdOutlineEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import _categories from "../../services/category.service.js";


const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, category: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function EditCategoryScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const { userInfo } = state;
  const params = useParams();
  const { id } = params;
  const [{ loading, error, category }, dispatch] = useReducer(reducer, {
    category: [],
    loading: true,
    error: "",
  });
  const [name, setName] = useState("");

  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const result = await _categories.get(id);
      setName(result.data.name);
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: getError(error) });
    }
  };
  const editCategoryHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      await _categories.edit(id, name);
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      toast.success("Categoria actualizada.");
    } catch (err) {
      dispatch({ type: "FETCH_FAIL" });
      toast.error(getError(err));
    } finally {
      navigate(-1);
    }
  };
  useEffect(() => {
      fetchData();
  }, [id, userInfo]);

  return (
    <Modal size="lg" show={true} animation={false}>
      {loading && <LoadingBox></LoadingBox>}
      <div className="container align-items-center">
        <Helmet>
          <title>Editar categoria</title>
        </Helmet>
        <div className="row justify-content-around">
          <div className="col-lg-11 col-10">
            <h1 className="my-3">
              <MdOutlineEdit></MdOutlineEdit>Editar Categoria
            </h1>
          </div>
          <div className="col-lg-1 col-2">
            <MdClose
              className="my-3"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></MdClose>
          </div>
        </div>
        <Form>
          <div className="row align-items-center">
            <Form.Group className="mb-3 col-lg-12" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>
          <Row className="modal-btns">
            <Col>
              <Button className="cancelar" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button className="guardar" onClick={editCategoryHandler}>
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default EditCategoryScreen;
