import axios from '../restClient';

const BASE_URI = '/deliverySchedules';
class DeliverySchedulesService {
	async list() {
		const res = await axios.get(BASE_URI);
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async create(label, hourFrom, hourTo) {
		const res = await axios.post(BASE_URI, {
			label,
			hourFrom,
			hourTo,
		});
		return res;
	}
}
export default new DeliverySchedulesService();
