import React, { useEffect, useState, useContext } from 'react';
import "./productBasicData.css";
import { Store } from '../../../Store';
import ButtonSecondaryTranslucidHover from '../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import classnames from 'classnames'
import EditStockPriceModal from '../../EditStockPriceModal/EditStockPriceModal';
import EditStockModal from '../../EditStockModal/EditStockModal';
//#region BS COMPONENTS
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { BsPencilSquare } from 'react-icons/bs';

//#endregion


export default function ProductBasicData({onChange,product, variantTotalStock,  disabled, onCancel, onComplete, isStep}) {
	const parseToAbs = value => value.length ? Math.abs(value) : '';
	const { state} = useContext(Store);
	return (
    <>
		<Card id="new-product-basic-data" className='col-xl-7 col-lg-8 col-md-9 col-10'>
			<Card.Header  className={classnames('',{disabled: disabled && isStep})} as="h5">Datos principales</Card.Header>
			<Card.Body className={classnames('',{disabled:  disabled && isStep})}>
				<Form >
					<fieldset disabled = {disabled && isStep}>
						<Row className='g-3'>
							<Col md={6} >
								<FloatingLabel label="Nombre">
									<Form.Control placeholder='Nombre' value = {product.name} onChange={(e) => onChange('name', e.target.value)} />
								</FloatingLabel>
							</Col>
							<Col md={6} >
								<FloatingLabel label="Marca">
									<Form.Control placeholder='Marca' value = {product.brand} onChange={(e) => onChange('brand', e.target.value)} />
								</FloatingLabel>
							</Col>
								<Col xs={6} md={2} >
									<div className="edit-product-input-wrapper">
										<FloatingLabel label="Precio">
											<Form.Control
												placeholder="Precio"
												min={0}
												className='pe-2'
												type="number"
												value={product.price}
												onChange={(e) => onChange('price', parseToAbs(e.target.value))}
											/>
										</FloatingLabel>
									</div>
								</Col>
								<Col xs={6} md={2} >
									<div className="edit-product-input-wrapper">
										<FloatingLabel label="Stock">
											<Form.Control placeholder='Stock' min={0} type="number"
												value={product.hasVariants ? variantTotalStock : product.countInStock}
												disabled={product.hasVariants}
												onChange={(e) => onChange('countInStock', parseToAbs(e.target.value))} />
										</FloatingLabel>
									</div>
								</Col>
								<Col xs={6} md={4} >
									<div className="edit-product-input-wrapper">

										<FloatingLabel label="Stock mínimo">
											<Form.Control placeholder='Stock mínimo' type="number" value={product.minStockToConfirm}
												onChange={(e) => onChange('minStockToConfirm', parseToAbs(e.target.value))} />
										</FloatingLabel>
									</div>
								</Col>
								<Form.Group className="col-3 col-md-2" >
									<Form.Label className='mb-0'>Destacados</Form.Label>
									<Form.Check
										type="switch"
										checked={product.highlightedProduct}
										onChange={(e) => onChange('highlightedProduct', e.target.checked)}
									></Form.Check>
								</Form.Group>


							<Form.Group className="col-3 col-md-2" >
								<Form.Label className='mb-0'>Disponible</Form.Label>
								<Form.Check
									type="switch"
									checked = {product.active}
									onChange={(e) => onChange('active', e.target.checked)}
								></Form.Check>
							</Form.Group>
							<Col xs={12} >
								<FloatingLabel label="Resumen">
									<Form.Control as="textarea" placeholder='Resumen' value = {product.description} 
										onChange={(e) => onChange('description', e.target.value)} />
								</FloatingLabel>
							</Col>
						</Row>
					</fieldset>
				</Form>
			</Card.Body>
			<Card.Footer className='d-flex justify-content-end'>
				{
					disabled && isStep &&  
        <ButtonSecondaryTranslucidHover onClick={onCancel}>
          Editar
        </ButtonSecondaryTranslucidHover>
				}
				{isStep && <Button disabled={product && !product.isBasicDataValid() || disabled} onClick={onComplete}>
          Confirmar
				</Button>}
			</Card.Footer>
		</Card>
    </>
	)
}
