import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { FaUsers } from "react-icons/fa";
import { ROLES, SCOPES } from "../../permission-utils/scopes";
import PermissionWrapper from "../../permission-utils/PermissionWrapper";

function AddUser({ show, close, onSuccess, userData }) {
  const [user, setUser] = useState({
    _id: "",
    name: "",
    email: "",
    password: "",
    role: ""
  });
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (userData) {
      setUser({
        name: userData.name,
        email: userData.email,
        password: "",
        role: userData.role,
        _id: userData._id
      });
    }
  }, [userData]);

  const handleSubmit = () => {
    if(!userData){
      if (validateUser()) {
        if (user.password === confirmPassword) {
          onSuccess(user, true);
        } else {
          toast.error("Las contraseñas no coinciden");
        }
      } else {
        toast.error("Error. Todos los datos son requeridos.");
      }
    } else{
      onSuccess(user, false);
    }
    handleClose();
  };

  const validateUser = () => {
    const { name, email, password, role } = user;
    return name && email && role && (userData || password === confirmPassword);
  };

  const handleClose = () => {
    setUser({
      name: "",
      email: "",
      password: "",
      role: "",
      _id: ""
    });
    close();
  }

  return (
    <Modal
      dialogClassName="add-product-container"
      size="lg"
      show={show}
      animation={true}
    >
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-lg-11 col-10">
            <h1 className="my-3">
              <FaUsers /> {userData ? "Editar Usuario" : "Agregar Usuario"}
            </h1>
          </div>
          <div className="col-lg-1 col-2">
            <MdClose
              onClick={handleClose}
              className="my-3"
              style={{ fontSize: "30px", cursor: "pointer" }}
            ></MdClose>
          </div>
        </div>
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className="row justify-content-center align-items-center">
            <Form.Group className="mb-3 col-lg-6" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                value={user.name}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6" controlId="role">
              <Form.Label>Rol</Form.Label>
              <Form.Select
                value={user.role}
                onChange={(e) => setUser({ ...user, role: e.target.value })}
              >
                <option value="">-</option>
                {Object.keys(ROLES).map((roleId) => (
                  <PermissionWrapper
                    key={roleId}
                    fn="users"
                    field={roleId}
                    scopes={[SCOPES.canCreate]}
                  >
                    <option value={roleId}>{ROLES[roleId]}</option>
                  </PermissionWrapper>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 col-lg-12" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoComplete="new-username"
                value={user.email}
                type="email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </Form.Group>
            {!userData && 
            <>
            <Form.Group className="mb-3 col-lg-6" controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                autoComplete="new-password"
                value={user.password}
                type="password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-lg-6" controlId="confirmPassword">
              <Form.Label>Repetir contraseña</Form.Label>
              <Form.Control
                value={confirmPassword}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            </>
            }
          </div>
          <Row className="modal-btns">
            <Col>
              <Button  className="cancelar" onClick={handleClose}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!validateUser()}
                // className="guardar"
                onClick={handleSubmit}
              >
                {userData ? "Guardar Cambios" : "Agregar"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default AddUser;
