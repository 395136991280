import React from 'react'
import Form from "react-bootstrap/Form";
import "./QuantityInputContainer.css";

export default function QuantityInputContainer({value, onChangeValue,stock, disabled = false}) {
    return <div className="quantity-input-container">
    <label htmlFor="quantity-input">
      Cantidad:
    </label>
    <Form.Control
      disabled = {disabled}
      className="quantity-input"
      min={1}
      max={stock}
      value={value}
      onChange={onChangeValue}
    />
    <div className="product-stock-info">
      { Number.isInteger(stock) && 
        `${stock} disponible${stock > 1 ? "s" : ""}`
        }
    </div>
  </div>

}
