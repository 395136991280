const SCOPES = {
	canWrite: 'can-write',
	canCreate: 'can-create',
	canEdit: 'can-edit',
	canDelete: 'can-delete',
	canView: 'can-view',
};
const ROLES = {
	system_admin: 'Super-administrador',
	bussiness_admin: 'Administrador',
	operator: 'Empleado',
	client: 'Cliente',
};
const { canCreate, canEdit, canView, canDelete, canWrite } = SCOPES;
const getRoleName = (role) => ROLES[role] || '';
const PERMISSIONS = {
	//OPERATOR
	operator: {
		adminScreen: {
			pagePermissions: [canView],
			fields: {
				access: [canView],
			},
		},
		orders: {
			pagePermissions: [canView],
			fields: {
				paid: [canEdit],
				deliver: [canEdit],
				cancel: [],
			},
		},
		categories: {
			pagePermissions: [canView],
			fields: {
				products: [canCreate, canDelete, canView],
			},
		},
		products: {
			pagePermissions: [canView, canEdit, canCreate],
			fields: {
				price: [canView],
				stock: [canView],
				minStockToConfirm: [canView],
			},
		},
		users: {
			pagePermissions: [],
			fields: {
				client: [],
				operator: [],
				system_admin: [],
				bussiness_admin: [],
			},
		},
		config: {
			pagePermissions: [],
		},
	},
	//BUSSINESS_ADMIN
	bussiness_admin: {
		adminScreen: {
			pagePermissions: [canView],
			fields: {
				access: [canView],
			},
		},
		orders: {
			pagePermissions: [canView],
			fields: {
				paid: [canEdit],
				deliver: [canEdit],
				cancel: [canEdit],
			},
		},
		categories: {
			pagePermissions: [canWrite],
			fields: {
				products: [canWrite],
			},
		},
		products: {
			pagePermissions: [canWrite],
			fields: {
				price: [canEdit, canWrite],
				stock: [canWrite, canEdit],
				minStockToConfirm: [canEdit, canWrite]
			},
		},
		users: {
			pagePermissions: [canView, canCreate],
			fields: {
				client: [canCreate],
				operator: [canCreate],
				system_admin: [],
				bussiness_admin: [],
			},
		},
		config: {
			pagePermissions: [],
		},
	},

	//SYSTEM ADMIN

	system_admin: {
		adminScreen: {
			pagePermissions: [canView],
			fields: {
				access: [canView],
			},
		},
		orders: {
			pagePermissions: [canView],
			fields: {
				paid: [canEdit],
				deliver: [canEdit],
				cancel: [canEdit],
			},
		},
		categories: {
			pagePermissions: [canWrite],
			fields: {
				products: [canWrite],
			},
		},
		products: {
			pagePermissions: [canWrite],
			fields: {
				price: [canEdit, canWrite],
				stock: [canWrite, canEdit],
				minStockToConfirm: [canEdit, canWrite]
			},
		},
		users: {
			pagePermissions: [canWrite],
			fields: {
				client: [canCreate],
				operator: [canCreate],
				system_admin: [canCreate],
				bussiness_admin: [canCreate],
			},
		},
		config: {
			pagePermissions: [canWrite],
		},
	},
	client: {
		adminScreen: {
			pagePermissions: [],
			fields: {
				access: [],
			},
		},
		orders: {
			pagePermissions: [],
			fields: {
				paid: [],
				deliver: [],
				cancel: [],
			},
		},
		categories: {
			pagePermissions: [],
			fields: {
				products: [],
			},
		},
		products: {
			pagePermissions: [],
			fields: {
				price: [],
				stock: [],
			},
		},
		users: {
			pagePermissions: [],
			fields: {
				client: [],
				operator: [],
				system_admin: [],
				bussiness_admin: [],
			},
		},
		config: {
			pagePermissions: [],
		},
	},
};

export { PERMISSIONS, ROLES, SCOPES, getRoleName };
