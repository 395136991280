import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import _user from "../../services/user.service";

export default function SignupScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleRepeatPassword = () => {
    if (repeatPasswordType === "password") {
      setRepeatPasswordType("text");
      return;
    }
    setRepeatPasswordType("password");
  };

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const submitHandler = async (e) => {
    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }
    try {
      const { data } = await _user.signup(name,
        email,
        password);
      toast.success(data.message);
      navigate(redirect || "/");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <Container className="small-container">
      <Helmet>
        <title>Registro</title>
      </Helmet>
      <h1 className="my-3">Registro</h1>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Nombre:</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="email"
            required
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          ></Form.Control>
        </Form.Group>
        <Form.Label>Contraseña</Form.Label>
        <InputGroup className="mb-3 p-0" controlId="password">
          <Form.Control
            type={passwordType}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="btn btn--secondary"
            className="eyeButton"
            id="button-addon2"
            onClick={togglePassword}
          >
            {passwordType === "password" ? (
              <BsFillEyeSlashFill />
            ) : (
              <BsFillEyeFill />
            )}
          </Button>
        </InputGroup>
        <Form.Label>Repetir Contraseña</Form.Label>
        <InputGroup className="mb-3 p-0" controlId="password">
          <Form.Control
            type={repeatPasswordType}
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="btn btn--secondary"
            className="eyeButton"
            id="button-addon2"
            onClick={toggleRepeatPassword}
          >
            {repeatPasswordType === "password" ? (
              <BsFillEyeSlashFill />
            ) : (
              <BsFillEyeFill />
            )}
          </Button>
        </InputGroup>
        <div className="mb-3">
          <Button type="submit" onClick={submitHandler}>
            Registrarse
          </Button>
        </div>
        <div className="mb-3">
          Ya tienes una cuenta?{" "}
          <Link to={`/signin?redirect=${redirect}`}>Inicia sesion</Link>
        </div>
      </Form>
    </Container>
  );
}
