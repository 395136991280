import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { hasPermission } from "../../utils";
import { Store } from "../../Store";
import { SCOPES } from "../../permission-utils/scopes";

export default function EditStockModal({
  show,
  product = null,
  close,
  onSuccess
}) {
  const [stock, setStock] = useState(0);
  const [min, setMin] = useState(0);
  const {state} = useContext(Store);
  const {userInfo} = state;
  const [permission,setPermission] = useState();

  useEffect(() => {
    if(userInfo) {
      setPermission(hasPermission(userInfo.role,"products",[SCOPES.canWrite],"stock"));
    }
    if(product) {
      setStock(product.countInStock)
      if(!permission) {
        setMin(product.countInStock);
      }
    }
  }, [product]);
  const handleOnSuccess  = () => {
    console.info(permission)
    if(permission || stock >= min) {
      onSuccess(product._id,stock);
    } else {
      toast.error("Error. Solo puedes incrementar el stock.")
    }
  }

  return (
    <Modal show={show} onHide={close} centered  dialogClassName = "edit-stock-modal" size  ="sm">
      <Modal.Header closeButton>
        <Modal.Title>Editar Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
            <Form.Control min={min} type="number" value={stock} onChange={(e) => setStock(e.target.value)}/>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button onClick={close}>
          Cancelar
        </Button>
        <Button onClick={handleOnSuccess}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
