import React, {useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AddressBook.css";
import AddressCard from "../AddressCard/AddressCard";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import NewShippingAddress from "../NewShippingAddress/NewShippingAddress";
import { getError } from "../../utils";
import classnames from "classnames";
import AddCardButton from "../AddCardButton/AddCardButton";
import CustomSelect from "../CustomSelect/CustomSelect";
import _addresses from "../../services/address.service";
import _deliverySchedules from "../../services/deliverySchedules.service";

export default function AddressBook({
  title,
  addresses,
  submitHandler,
  userInfo,
  setAddresses,
  contextShippingAddress,
  goToPreviousStepHandler,
  setAddressHasErrors,
  addressBookRef
}) {
  const [selectedAddress, setSelectedAddress] = useState({});
  const [changeAddress, setChangeAddress] = useState(false);
  const [shippingDate, setShippingDate] = useState("");
  const [timeForDelivery, setTimeForDelivery] = useState("");
  const [comments, setComments] = useState("");
  const [show, setShow] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState();
  const [addressTemp, setAddressTemp] = useState({});
  const [deliverySchedules, setDeliverySchedules] = useState([]);
  const inputRef = useRef(null)
  useEffect(() => {
    if (submitHandler) {
      if (addresses) {
        if (addresses.length > 1) {
          const primaryAddress = addresses.find((a) => a.isPrimary);
          if (primaryAddress) {
            setSelectedAddress(primaryAddress);
          } else {
            setChangeAddress(true);
          }
        } else if (addresses.length === 1) {
          if (addresses[0].isPrimary) setSelectedAddress(addresses[0]);
        }
        if (
          contextShippingAddress &&
          Object.keys(contextShippingAddress).length
        ) {
          if (
            !addresses.find(
              (a) => a.addressName === contextShippingAddress.addressName
            )
          ) {
            const addressesCopy = new Array(...addresses);
            addressesCopy.push(contextShippingAddress);
            setAddresses(addressesCopy);
          }
        }
      }
    }
    getDeliverySchedules();
  }, []);

  useEffect(() => {
    if(setAddressHasErrors) {
      setAddressHasErrors(!selectedAddress.hasOwnProperty("addressName") || !timeForDelivery || !shippingDate);
    }
  },[selectedAddress,timeForDelivery,shippingDate,setAddressHasErrors])
  const selectHandler = (address) => {
    setSelectedAddress(address);
    setChangeAddress(false);
  };
  const cancelChangeAddress = () => {
    setChangeAddress(false);
  }
  const changeAddressModeHandler = () => {
    setChangeAddress(true);
  };
  const deleteHandler = (address) => {
    setAddressToDelete(address);
    setShowDeleteModal(true);
  };
  const closeDeleteHandler = () => {
    setAddressToDelete(null);
    setShowDeleteModal(false);
  };
  //#region REQUESTS
  const getDeliverySchedules = async () => {
    try {
      const result = await _deliverySchedules.list();
      setDeliverySchedules(result.data);
    } catch (error) {
      console.error();
      toast.error(getError(error));
    }
  }
  const deleteAddress = async () => {
    if (addressToDelete) {
      const deletedAddressId = addressToDelete._id;
      try {
        await _addresses.delete(addressToDelete._id);
        const index = addresses.findIndex((a) => a._id === deletedAddressId);
        const addressesCopy = new Array(...addresses);
        addressesCopy.splice(index, 1);
        setAddresses(addressesCopy);
        closeDeleteHandler();
        toast.success("Direccion eliminada");
      } catch (ex) {
        toast.error(getError(ex));
      }
    }
  };
  const makeAddressPrimary = async (address) => {
    if (address) {
      try {
        await _addresses.makePrimary(address._id);
        const addressesCopy = new Array(...addresses);
        const indexPrevPrimaryAddress = addressesCopy.findIndex(
          (a) => a.isPrimary
        );
        const indexNewPrimaryAddress = addressesCopy.findIndex(
          (a) => a._id === address._id
        );
        if (indexPrevPrimaryAddress > -1) {
          addressesCopy[indexPrevPrimaryAddress].isPrimary = false;
        }
        addressesCopy[indexNewPrimaryAddress].isPrimary = true;
        setAddresses(addressesCopy);
      } catch (ex) {
        console.error(ex);
        toast.error(getError(ex));
      }
    }
  };
  const newAddressSubmitHandler = async (e) => {
    e.preventDefault();
      const target = e.target;
      const {
        addressLine1,
        addressLine2,
        addressName,
        city,
        name,
        state,
        phone,
        country,
        isPrimary,
        saveAddress,
      } = target;

      if (
        addressLine1.value &&
        addressLine2.value &&
        addressName.value &&
        city.value &&
        name.value &&
        state.value &&
        phone.value &&
        country.value
      ) {
        if (!addresses.find((a) => a.addressName === addressName.value)) {
          if (!saveAddress || saveAddress.checked) {
            try {
              const { data } = await _addresses.create(
                {
                  user: userInfo._id,
                  addressName: addressName.value,
                  name: name.value,
                  addressLine1: addressLine1.value,
                  addressLine2: addressLine2.value,
                  city: city.value,
                  state: state.value,
                  country: country.value,
                  phone: phone.value,
                  isPrimary: isPrimary.checked,
                }
              );

              const addressesCopy = new Array(...addresses);
              addressesCopy.push(data.newAddress);
              setAddresses(addressesCopy);
              toast.success("Dirección guardada");
              setShow(false);
              if (addresses.length == 0 && submitHandler) {
                selectHandler(data.newAddress)
              } else {
                changeAddressModeHandler();
              }
            } catch (ex) {
                toast.error(getError(ex));
            }
          } else {
            const newAddress = {
              addressName: addressName.value,
              name: name.value,
              addressLine1: addressLine1.value,
              addressLine2: addressLine2.value,
              city: city.value,
              state: state.value,
              country: country.value,
              phone: phone.value,
              isPrimary: false,
            };
            const addressesCopy = new Array(...addresses);
            addressesCopy.push(newAddress);
            setAddresses(addressesCopy);
            if (submitHandler) {
              selectHandler(newAddress)
            }

            setShow(false);
          }
        } else {
          toast.error("Error. El nombre de la dirección debe ser único.");
        }
      } else {
        toast.error("Error. Complete todos los datos.");
      }
  };
  //#endregion


  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <NewShippingAddress
            displaySaveAddress={submitHandler && true}
            submitHandler={newAddressSubmitHandler}
            firstAddress={addresses.length === 0}
          ></NewShippingAddress>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que quieres borrar la dirección?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteAddress}>
            Borrar
          </Button>
          <Button variant="primary" onClick={closeDeleteHandler}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container small-container address-book mb-2 ">
        <div className="address-book-header">
          <h2 className="my-2">{title}</h2>
        </div>
        <div className="address-book-body  row gy-2 justify-content-start">
          {addresses.length > 0 &&
            addresses.map((a, i) => (
              <AddressCard
                selected={selectedAddress.hasOwnProperty("_id") ?   a._id === selectedAddress._id : 
                a.addressName === selectedAddress.addressName}
                key={i}
                address={a}
                makeAddressPrimary={changeAddress || !submitHandler ? makeAddressPrimary : null}
                selectHandler={submitHandler && changeAddress &&
                  (!selectedAddress.hasOwnProperty("_id") || (selectedAddress.hasOwnProperty("_id") &&
                    selectedAddress._id != a._id)) ? selectHandler : null
                  ? selectHandler : null}
                deleteHandler={changeAddress || !submitHandler ? deleteHandler : null}
              ></AddressCard>
            ))
          }
          {(!submitHandler || changeAddress || addresses.length == 0) && <AddCardButton handler={() => setShow(true)} />}
        </div>
        <div className="address-book-controls d-flex align-items-center justify-content-end px-2 py-1">

          {selectedAddress.hasOwnProperty("_id") && changeAddress && submitHandler && (
            <Button
              className="mt-1"
              variant="outline-danger"
              onClick={cancelChangeAddress}
            >
              Cancelar
            </Button>
          )}

          {!changeAddress &&
            addresses &&
            addresses.length > 0 &&
            submitHandler && (
              <Button
                className="mt-1"
                onClick={changeAddressModeHandler}
                variant="outline-secondary"
              >
                Cambiar direccion
              </Button>
            )}
        </div>{" "}
        {submitHandler && (
          <>
            <div className="address-book-order-details-title  p-0">
              <h2 className="mb-0 ms-2">Detalles del envío</h2>
            </div>
            <div className="address-book-order-details px-2 mb-2">
              <Row className="mb-2">
                <Col md={6} className="mb-md-0 mb-2">             
                  <FloatingLabel  label="Preferencia Fecha de Envio">
                    <Form.Control id="shipping-date-input"                      
                      type="date"                                            
                      onKeyDown={handleDateChangeRaw}
                      onFocus={(e)=> {e.preventDefault();inputRef.current.showPicker()}}
                      ref={inputRef}
                      min={new Date().toISOString().split("T")[0]}
                      value={shippingDate}
                      onChange={(e) => setShippingDate(e.target.value)}
                    />
                    {/* <BsCalendarDate/> */}
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-md-0 mb-2">
                  <CustomSelect label={"Preferencia Horario Envío"}
                    onChange={(value) => setTimeForDelivery(value)}
                    value={timeForDelivery}
                    options={deliverySchedules.map((schedule) => ({
                      title: `${schedule.label} (${schedule.hourFrom} a ${schedule.hourTo}hs)`,
                      value: schedule.label,
                    }))} />
                </Col>
              </Row>
              <Row>
                <FloatingLabel controlId="comments" label="Comentarios">
                  <Form.Control
                    as="textarea"
                    placeholder="Comentarios"
                    onChange={(e) => setComments(e.target.value)}
                  />
                </FloatingLabel>
              </Row>
            </div>
          </>
        )}
        <div
          className={classnames(
            "address-book-footer p-1 ps-2 d-flex  align-items-center",
            {
              "justify-content-between":
                selectedAddress.hasOwnProperty("_id") && (!timeForDelivery || !shippingDate),
              "justify-content-end":
                !selectedAddress.hasOwnProperty("_id") ||
                (selectedAddress.hasOwnProperty("_id") && shippingDate && timeForDelivery),
            }
          )}
        >
          {
            goToPreviousStepHandler && 
            <Button
            variant="outline-secondary"
            onClick={goToPreviousStepHandler}
          >
            Atrás
          </Button>
          }
          {submitHandler && (
            <>
              {selectedAddress.hasOwnProperty("_id") && (!timeForDelivery || !shippingDate) && (
                <div className="error-message">
                  Debe indicar fecha de envío y preferencia de horario.
                </div>
              )}
              <Button
                ref={addressBookRef}
                className="address-book-submit-btn"
                variant="outline-secondary"
                disabled={!selectedAddress.hasOwnProperty("addressName") || !timeForDelivery || !shippingDate}
                onClick={() =>
                  submitHandler(
                    selectedAddress,
                    timeForDelivery,
                    comments,
                    shippingDate
                  )
                }
              >
                Continuar
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
