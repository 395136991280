import React from 'react';
import { FaCog, FaFileUpload, FaHome, FaListAlt, FaListUl, FaSitemap, FaUsers } from 'react-icons/fa';
import { TbExchange } from "react-icons/tb";
import { Link, useLocation } from 'react-router-dom';
import "./AdminNavbarOptions.css"
import PermissionWrapper from "../../permission-utils/PermissionWrapper";
import { SCOPES } from '../../permission-utils/scopes';
import Navbar from "react-bootstrap/Navbar";
import classnames from 'classnames';


export default function AdminNavbarOptions({ Collapse, imageURL = null, companyName = ''}) {
  const {pathname} = useLocation();
  return (
      <>
      <div id = "mobile-menu-admin" className="d-flex flex-wrap w-100">
        <div className="admin-company-logo d-flex justify-content-center w-100">
            <Link to="/" className='d-inline-block h-100'>
              <img className = "img-fluid h-100" src={imageURL} alt={`${companyName || ""} Logo`} />
            </Link>
        </div>

        <div className = "nav-option d-block d-lg-none">
          <Link to="/" onClick={Collapse}
          >
            <span>Inicio</span>
          </Link>
        </div>
        <div className = "nav-option">
          <Link
            className={classnames('', {'focused-option': pathname.includes('AdminOrders') || 
            pathname == "/AdminScreen"})}
            to="/AdminScreen/AdminOrders"
            onClick={Collapse}
          >
            <FaListUl />
            <span>Pedidos</span>
          </Link>
        </div>
        <div className = "nav-option">
          <Link
            className={classnames('', {'focused-option':pathname.includes('Categories')})}
            to="/AdminScreen/Categories"
            onClick={Collapse}
          >
            <FaListAlt />
            <span>Categorías</span>
          </Link>
        </div>
        <div className = "nav-option">
          <Link
            className={classnames('', {'focused-option':pathname.includes('AdminProducts')})}
            to="/AdminScreen/AdminProducts/search"
            onClick={Collapse}
          >
            <FaFileUpload />
            <span>Productos</span>
          </Link>
        </div>
        <div className = "nav-option">
          <Link
            className={classnames('', {'focused-option':pathname.includes('Adminflaps')})}
            to="/AdminScreen/Adminflaps"
            onClick={Collapse}
          >
           <TbExchange />
            <span>Cambios</span>
          </Link>
        </div>
        <PermissionWrapper fn="config" scopes={[SCOPES.canView]}>
          <div className = "nav-option">
            <Link
              className={classnames('', {'focused-option':pathname.includes('AdminUsers')})}
              to="/AdminScreen/AdminUsers"
              onClick={Collapse}
            >
              <FaUsers />
              <span>Usuarios</span>
            </Link>
          </div>
          <div className = "nav-option">
            <Link
              className={classnames('', {'focused-option':pathname.includes('Config')})}
              to="/AdminScreen/Config"
              onClick={Collapse}
            >
              <FaCog />
              <span>Configuración</span>
            </Link>
          </div>
        </PermissionWrapper>
      </div>
      </>
    );
}