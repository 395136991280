import React from 'react';
import './Stepper.css';
import classnames from 'classnames';
import { PiCheckFatFill } from "react-icons/pi";


export default function Stepper({ steps = [], activeStep = 0, className}) {
	if (!steps.every((s) => s.counter && s.name)) {
		return;
	}

	return (
		<div className={classnames('stepper-wrapper',{[className] : className})}>
			{steps.map(({ counter, name }) => (
				<div
					key={counter}
					className={classnames('stepper-item', {
						completed: counter < activeStep,
						active: counter === activeStep,
					})}
				>
					<div className="step-counter">
						{
							counter < activeStep ? 
							<PiCheckFatFill/>
							: counter === activeStep ?
							 <div className='step-circle'>

							 </div>
							: <></>
						}
					</div>
					<div className="step-name">{name}</div>
				</div>
			))}
		</div>
	);
}
