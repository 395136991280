import React from 'react';
import { BsFillGearFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import "./ClientNavbarOptions.css";
import PermissionWrapper from '../../permission-utils/PermissionWrapper';
import { SCOPES } from '../../permission-utils/scopes';


export default function ClientNavbarOptions({ questions , collapse, deliveryMapUrl, googleMapLocationUrl, instagramUrl, facebookUrl, whatsappUrl, admin }) {
    return <ul className="mobile-menu navbar-left nav navbar-nav ms-0 align-items-center">
    <PermissionWrapper fn = 'adminScreen' scopes={[SCOPES.canView]}>
            <li>
                {" "}
                <Link
                    className="nav-link"
                    onClick={collapse}
                    to="/AdminScreen"
                    >
                    <span id='span-configuracion' className='ms-0 d-lg-none d-block'>Administrador</span>
                    <BsFillGearFill className="mt-1 d-lg-block d-none"></BsFillGearFill>
                </Link>
            </li>
    </PermissionWrapper>
        <li>
            {" "}
            <Link
                onClick={collapse}
                className="nav-link"
                to="/"
            >
                Inicio
            </Link>
        </li>
        <li>
            {" "}
            <Link
                onClick={collapse}
                className="nav-link"
                to="/search"
            >
                Productos
            </Link>
        </li>
{googleMapLocationUrl && <li>
            <Link
                onClick={collapse}
                className="nav-link"
                to="/contacto"
            >
                Contacto
            </Link>
        </li>}
        {deliveryMapUrl && <li>
            <Link
                onClick={collapse}
                className="nav-link"
                to="/Envios"
            >
                Envios
            </Link>
        </li>}

{questions.length > 0 &&  <li>
            <Link
                onClick={collapse}
                className="nav-link"
                to="faq"
            >
                Ayuda
            </Link>
        </li>}
        {instagramUrl && <li className="d-lg-none">
            <a className="nav-link" href={instagramUrl}>
                Instagram
            </a>
        </li>}
        {whatsappUrl && <li className="d-lg-none">
            <a className="nav-link" href={whatsappUrl}>
                Whatsapp
            </a>
        </li>}
        {facebookUrl &&
            <li className="d-lg-none">
                <a className="nav-link" href={facebookUrl}>
                    Facebook
                </a>
            </li>}
    </ul>
}