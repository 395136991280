import React, { useEffect, useState} from 'react';
import "./productDescription.css";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from "draft-js-import-html";
//#region BS COMPONENTS
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import LoadingBox from '../../LoadingBox/LoadingBox';
//#endregion


export default function NewProductDescription({ product, onChange}) {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(product.htmlDescription)));
    const handleEditorChange = (state) => {
        setEditorState(state);
    };
    useEffect(() => {
        if (editorState) {
            convertToHtml();
        }
    }, [editorState]);

    const convertToHtml = () => {
        const convertedContentOne = stateToHTML(
            editorState.getCurrentContent()
        );
        onChange("htmlDescription",convertedContentOne);
    };
    return (
        <Card id="new-product-description" className='col-xl-7 col-lg-8 col-md-9 col-10'>
            <Card.Header as={'div'}>
                <h5>
                Descripción (opcional)
                </h5>
                <p className='new-product-section-caption'>
                Una descripción detallada un de producto sirve para informara los compradores sobre las características y beneficios del producto.
                </p>
            </Card.Header>
                <>
                    <Card.Body>
                        {editorState ?
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                            />
                            : <LoadingBox />
                        }
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-center'>
                    </Card.Footer>
                </>
        </Card>
    )
}
