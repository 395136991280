import React, { useEffect, useState } from 'react'
import ButtonTranslucidHover from '../ButtonTranslucidHover/ButtonTranslucidHover'

export default function ButtonSecondaryTranslucidHover({children, onClick, className, disabled}) {
    const [color, setColor] = useState();

    useEffect(() => {
        setColor(getComputedStyle(document.documentElement)
            .getPropertyValue('--secondaryColor'))
    }, [getComputedStyle(document.documentElement)
        .getPropertyValue('--secondaryColor')]);
  return <ButtonTranslucidHover disabled = {disabled} color={color} onClick={onClick} className={className}>
    {children}
  </ButtonTranslucidHover>
}
