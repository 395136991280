import React, { useEffect, useState } from 'react';
import "./PreviewProductModal.css";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../LoadingBox/LoadingBox';
import CustomSelect from "../CustomSelect/CustomSelect";
import DOMPurify from "dompurify";

export default function PreviewProductModal({ show, product, closeHandler}) {
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState("");
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html),
        };
    };
    useEffect(() => {
        if (product && product.options) {
            setOptions(product.options.map(opt => {
                return { title: opt, value: opt }
            }))
        }
    }, [product])
    return <Modal show={show} size='xl' dialogClassName='preview-product-modal' onHide={closeHandler}>
        <Modal.Header closeButton>
            <Modal.Title>Previsualización</Modal.Title>
        </Modal.Header>
        {
            product ?
                <div className="productScreenContainer">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={8}>
                                    <Row className="justify-content-center">
                                        {product.image && typeof product.image === "object" ? (
                                            <img
                                                className="prod-page-img-lg"
                                                src={product.image.fileLink}
                                                alt={product.name}
                                            />
                                        ) : (
                                            <img
                                                alt="product_photo"
                                                src="/images/emptyPhoto.png"
                                                className="empty-list-image-preview"
                                            />
                                        )}
                                    </Row>
                                </Col>
                                <Col lg={4}>

                                    <Card>
                                        <Card.Body>
                                            <Helmet>
                                                <title>{product.name.toUpperCase()}</title>
                                            </Helmet>
                                            <Row>
                                                {" "}
                                                <h1 className="font-weight-700">
                                                    {product.name.toUpperCase()}
                                                </h1>
                                                <p>
                                                    {product.active && product.countInStock > 0 ? (
                                                        <Badge bg="success" className="p-1">
                                                            Disponible
                                                        </Badge>
                                                    ) : !product.active ? (
                                                        <Badge bg="danger">No disponible</Badge>
                                                    ) : (
                                                        <Badge bg="danger">Sin stock</Badge>
                                                    )}
                                                </p>
                                            </Row>
                                            <Row>
                                                {" "}
                                                <p>{product.brand}</p>
                                            </Row>
                                            <Row className="align-items-center d-flex ">
                                                <h1>${product.price}</h1>
                                            </Row>
                                            <Row className="justify-content-start my-2">
                                                <Col lg={12}>
                                                    {product.options.length > 0 &&
                                                        <div className="select-options-container">
                                                            <CustomSelect label="Seleccione una opcion" options={options} value={option}
                                                                onChange={(v) => setOption(v)} fontSize="0.9em" />
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <p>{product.description}</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {product.htmlDescription && <>
                                    <Row className="mt-5">
                                        <h1>
                                            Descripcion:
                                        </h1>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="product-description-container"
                                                dangerouslySetInnerHTML={createMarkup(product.htmlDescription)}
                                            ></div>
                                        </Col>
                                    </Row>
                                </>}
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                :
                <div className="loading-box-modal">
                    <LoadingBox size="lg" />
                </div>

        }
    </Modal>
}