import React from 'react';
import Alert from 'react-bootstrap/Alert';
import './Span.css';

function Span(props) {
	const statusClass = props.variant ? `status-${props.variant}` : ''; // Generate status class

	return (
		<Alert variant={props.variant || 'info'} className={`status-span ${statusClass}`}>
			<span className="text-center">{props.children}</span>
		</Alert>
	);
}

export default Span;
