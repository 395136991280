import axios from '../restClient';

const BASE_URI = '/addresses';

class AddressService {
	async list(userId) {
		if (userId) {
			const uri = `${BASE_URI}/${userId}`;
			const res = await axios.get(uri);
			return res;
		} else {
			console.error('Missing userId');
			throw new Error('Error. No se pudo obtener las direcciones');
		}
	}
	//TODO crear address como un clase con metodos de validacion.
	async create(data) {
		const res = await axios.post(BASE_URI, data);
		return res;
	}
	async delete(addressId) {
		if (addressId) {
			const res = await axios.delete(`${BASE_URI}/${addressId}`);
			return res;
		} else {
			console.error('Missing addressId');
			throw new Error('Error. No se pudo eliminar la dirección');
		}
	}
	async makePrimary(addressId) {
		if (addressId) {
			const res = await axios.put(`${BASE_URI}/make_primary/${addressId}`);
			return res;
		} else {
			console.error('Missing addressId');
			throw new Error('Error. No se pudo establecer la dirección como primaria');
		}
	}
}
export default new AddressService();
