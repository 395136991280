import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './CreateDiscountCodeModal.css'; // Adjust the CSS file name if needed
import { toast } from 'react-toastify'; 
import moment from 'moment'; // Ensure you have moment installed
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DiscountCode from '../../models/DiscountCode'; // Adjust the path if needed
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function CreateDiscountCodeModal({ show, handleClose, handleEditCode, handleCreateCode,
    discountCodeToEdit, products, deselectElement }) {
    const [discountCode, setDiscountCode] = useState(DiscountCode.createEmpty());
    const [active, setActive] = useState(true);
    const [errors, setErrors] = useState({});
    const [noEndDate, setNoEndDate] = useState(false);
    useEffect(() => {
        setDiscountCode(getInitalDiscountState);
    }, [show]);
    function getInitalDiscountState() { return discountCodeToEdit ? DiscountCode.create(discountCodeToEdit) : DiscountCode.createEmpty() };
    const resetModalState = () => {
        setDiscountCode(DiscountCode.createEmpty());
        setErrors({});
        setNoEndDate(false);
        setActive(true);
    };

    const handleModalClose = () => {
        resetModalState();
        handleClose();
    };
    const handleNoEndDateChange = (e) => {
        setNoEndDate(e.target.checked);
        if (e.target.checked) {
            changeProperty("dateTo", '');
        }
    };
    const changeProperty = (key, value) => {
        setDiscountCode(DiscountCode.create({ ...discountCode, [key]: value }));
    }
    const handleAddIncludedProduct = (products) => {
        const code = DiscountCode.create({...discountCode, products});
        setDiscountCode(code);
    }
    const handleSubmit = () => {
        setActive(discountCode.isActive());
        const errors = discountCode.validate();
        const {_id, code, discountPercent, dateFrom, dateTo,
            products } = discountCode;
        if (Object.keys(errors).length === 0) {
            const discountCodeData = {
                code,
                discountPercent: parseFloat(discountPercent),
                dateFrom,
                dateTo: noEndDate ? null : dateTo,
                active,
                products
            };
            if (discountCodeToEdit) {
                discountCodeData._id = _id;
                handleEditCode(discountCodeData);
            } else {
                handleCreateCode(discountCodeData)
            }
            handleModalClose();
        } else {
            setErrors(errors);
        }
    };

    return (
        <Modal show={show} onHide={handleModalClose} onExited={deselectElement} centered dialogClassName='create-discount-code-modal'>
            <Modal.Header closeButton>
                <Modal.Title className='text-center w-100'>{discountCodeToEdit ? 'Editar código de descuento' : 'Crear código de descuento'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formCode">
                        <FloatingLabel label="Código">
                            <Form.Control placeholder='Ingrese el código del descuento' type="text" value={discountCode.code}
                                onChange={(e) => changeProperty('code', e.target.value )} />
                        </FloatingLabel>
                        {errors.code && <Form.Text className="text-danger">{errors.code}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDiscountPercent">
                        <FloatingLabel label="Porcentaje de Descuento">
                            <Form.Control placeholder='Ingrese el porcentaje de descuento' type="number" min={1}
                                value={discountCode.discountPercent} onChange={(e) => changeProperty('discountPercent', e.target.value) }/>
                        </FloatingLabel>
                        {errors.discountPercent && <Form.Text className="text-danger">{errors.discountPercent}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDateFrom">
                        <FloatingLabel label="Fecha de Inicio">
                            <Form.Control type="date" value={moment.utc(discountCode.dateFrom).format('YYYY-MM-DD')} onChange={(e) => changeProperty('dateFrom', e.target.value)}/>
                        </FloatingLabel>
                        {errors.dateFrom && <Form.Text className="text-danger">{errors.dateFrom}</Form.Text>}
                    </Form.Group>
                    <Form.Group controlId="formDateTo">
                        <FloatingLabel label="Fecha de Fin">
                            <Form.Control type="date" value={moment.utc(discountCode.dateTo).format('YYYY-MM-DD')} onChange={(e) => changeProperty('dateTo', e.target.value)}/>
                        </FloatingLabel>
                        {errors.dateTo && <Form.Text className="text-danger">{errors.dateTo}</Form.Text>}
                        {errors.dateRange && <Form.Text className="text-danger">{errors.dateRange}</Form.Text>}
                    </Form.Group>
                    {discountCodeToEdit &&
                        <Form.Group controlId="formNoEndDate">
                            <Form.Check
                                type="switch"
                                label="Sin fecha de finalización"
                                checked={noEndDate}
                                onChange={handleNoEndDateChange}
                            />
                        </Form.Group>}
                    <Form.Group controlId='included-rooducts'>
                        <Form.Label>Productos incluidos</Form.Label>
                            <Typeahead
                                id="included-products-typeahead"
                                labelKey={(option) => products.find(p => p._id === option).name}
                                multiple
                                onChange={(selected) => handleAddIncludedProduct(selected)}
                                selected={discountCode.products}
                                options={products.map(p  => p._id)}
                            />
                        {errors.noProducts && <Form.Text className="text-danger">{errors.noProducts}</Form.Text>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateDiscountCodeModal;
