import classnames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import './BtnOutlineSecondary.css';

export default function BtnOutlineSecondary({className, clickHandler, children}) {
    return <Button
        variant="outline-secondary"
        className={classnames("button-outline-secondary", { [className]: className })}
        onClick={clickHandler}
    >
       {children}
    </Button>
}