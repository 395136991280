import React, { useState } from 'react';
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import { BsTrash3 } from "react-icons/bs";

import './QuantityPicker.css';

const QuantityPicker = ({quantity, onIncrement, onDecrement}) => {
    return (
        <div className='quantity-picker'>
            <button onClick={onDecrement}>{quantity === 1 ? <BsTrash3/> : <HiMinusSm/>}</button>
            <span>{quantity}</span>
            <button onClick={onIncrement}>{<HiPlusSm/>}</button>
        </div>
    );
};

export default QuantityPicker;