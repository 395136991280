import React from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";

function MessageBox({cols = 3, className, variant = "info", children}) {
  return (
    <Row className={classnames("justify-content-center message-box", { [className] : className})}>
      <Col md={cols}>
        <div className="d-flex justify-content-center align-items-center m-2">
          <Alert variant={variant} className="mb-0">
            <span className="text-center">{children}</span>
          </Alert>
        </div>
      </Col>
    </Row>
  );
}
export default MessageBox;
