import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { getError, getStatus } from '../../utils';
import './MpSuccessScreen.css';

function MpSuccessScreen() {
	const params = useParams();
	const { id, prefId } = params;
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [updated, setUpdated] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const update = async () => {
			try {
				await updateTransaction();
				await updateOrder();
				setTimeout(() => handleUpdate(),1500);
			} catch (ex) {
				console.error(ex);
			}
		};
		const updateTransaction = async () => {
			const data = {
				order: id,
				collection_id: urlParams.get('collection_id'),
				collection_status: urlParams.get('collection_status'),
				payment_id: urlParams.get('payment_id'),
				externalReference: urlParams.get('externalReference'),
				payment_type: urlParams.get('payment_type'),
				merchant_order: urlParams.get('merchant_order'),
			};
			try {
				await axios.put(`/api/mercadopago/transaction/${prefId}`, data);
			} catch (ex) {
				console.error(ex);
			}
		};
		const updateOrder = async () => {
			try {
				await axios.put(
					`/api/orders/${id}/payMp`,
					{},
					{
						headers: { authorization: `Bearer ${userInfo.token}` },
					}
				);
				toast.success('Pedido Pago');
			} catch (err) {
				console.error(getError(err));

			}
		};
		update();
	}, []);

	const redirect = () => {
		ctxDispatch({ type: 'USER_SIGNOUT' });
		navigate('/signin');
		toast.error('Sesion expirada. Vuelve a ingresar.');
	};
	const handleUpdate = () => {
		setUpdated(true);
		toast.success('Pedido Pago');
	};

	return (
		<div id="payment-success-screen">
			{updated && <Navigate to={`/order/${id}`}></Navigate>}
			<Helmet>
				<title>Procesando pago</title>
			</Helmet>
			<Row className="justify-content-center">
				<Col xs={6}>
					<Row className="justify-content-center">
						<Col xs = {12}>
							<div className="text-center payment-status-message">
            Su pago esta siendo procesado. Por favor no cierre esta ventana.
							</div> 
						</Col>
					</Row>
					<Row className="justify-content-center mt-4">
						<Col xs = {2} >
							<i className="fas fa-spinner"></i>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

export default MpSuccessScreen;
