import React from 'react';
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/esm/Row';
import { FaPlus } from "react-icons/fa";
import classnames from 'classnames';
export default function AddCardButton({ handler, className }) {

    return <Card id="add-card" className={classnames("address-card p-0 me-1", { [className]: className })}
    onClick={handler}>
        <Card.Body>
            <div id="plus-container">
                <i className='p-auto fas fa-plus m-auto'></i>
            </div>
            <h3 className='m-auto'>Agregar dirección</h3>
        </Card.Body>
    </Card>
}