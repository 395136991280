import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./NewFaqQuestionModal.css";
import { Store } from "../../../Store";

export default function NewFaqQuestionModal({
  show,
  saveHandler,
  editHandler,
  handleClose,
  edit,
  editQuestion,
}) {
  const {state} = useContext(Store);
  const {userInfo} = state;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (edit) {
      setQuestion(editQuestion.question);
      setAnswer(editQuestion.answer);
      setActive(editQuestion.active);
    }
  }, [edit]);

  const handleNewQuestion = () => {
    if (question && answer) {
      saveNewQuestion();
    } else {
      toast.error("Todos los campos son requeridos");
    }
  };
  const saveNewQuestion = async () => {
    try {
      await saveHandler( question,answer,active)
      toast.success("Pregunta creada");
      handleClose();
    } catch (ex) {
      toast.error("Error. Intentelo de nuevo.");
      console.error(ex);
    }
  };
  const handleEditQuestion = async () => {
    try {
      await editHandler(question, answer, active);
      toast.success("Cambios guardados.");
      handleClose();
    } catch (ex) {
      toast.error("Error");
      console.error(ex);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-faq-config">
      <Modal.Header closeButton>
        <Modal.Title>{edit ? "Editar pregunta" : "Nueva pregunta"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row justify-content-start align-items-end">
          <Form.Group className="col-6 mb-2" controlId="newQuestion">
            <Form.Control
              onChange={(e) => setQuestion(e.target.value)}
              required
              placeholder="Pregunta"
              defaultValue={edit ? editQuestion.question : ""}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="col-1 mb-2" controlId="newActive">
            <Form.Check
              type="checkbox"
              label="Activo"
              onChange={(e) => setActive(e.target.checked)}
              defaultChecked={edit ? editQuestion.active : false}
            />
          </Form.Group>
          <Form.Group className="col-10" controlId="newDetails">
            <Form.Control
              onChange={(e) => setAnswer(e.target.value)}
              defaultValue={edit ? editQuestion.answer : ""}
              className="answer-text"
              as="textarea"
              placeholder="Respuesta"
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          className="m-0"
          variant="primary"
          onClick={editQuestion ? handleEditQuestion : handleNewQuestion}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
