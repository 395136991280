import React, { useState } from 'react';
import {FloatingLabel, Form,} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';

export default function NewVariantOption({className,submitHandler}) {
    const [name, setName] = useState("");

    return <>
        <Helmet>
            <title>Agregar Opcion de Variante</title>
        </Helmet>
                <Form onSubmit = {submitHandler} className={classnames("", { [className]: className })} id='form-new-variant-option'>
                    <FloatingLabel controlId="optionName" label="Nombre de opción">
                        <Form.Control
                            className='pb-1'
                            maxLength={20}
                            type="text"
                            placeholder='Nombre de opcion'
                            onChange={(e) => setName(e.target.value)}
                            required
                        ></Form.Control>
                    </FloatingLabel>
                </Form>
    </>
}