import React from 'react';
import { Modal } from 'react-bootstrap';
import NewImageCropper from '../NewImageCropper/NewImageCropper';

const ImageCropModal = ({ imgSrc, setImgSrc, onCrop, onHide, show }) => {

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajusta la imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <NewImageCropper imgSrc={imgSrc} setImgSrc={setImgSrc} onExit = {onHide} onCrop={onCrop} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageCropModal;