import React, { useContext, useEffect, useReducer, useState } from "react";
import { DropdownButton, Dropdown, Table, Card, Button } from "react-bootstrap";
import { getError, getStatus } from "../../utils";
import { Store } from "../../Store";
import { FaPlusCircle, FaUsers } from "react-icons/fa";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { SCOPES, getRoleName } from "../../permission-utils/scopes.js";
import PermissionWrapper from "../../permission-utils/PermissionWrapper.jsx";
import AddUser from "../../components/AddUser/AddUser.jsx";
import DynamicListTable from "../../components/DynamicListTable";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal/DeleteConfirmationModal.js";
import _users from "../../services/user.service.js";
import useQueryFilters from "../../hooks/useQueryFilters.jsx";
import ExportToExcelButton from "../../components/ExportData/ExportToExcel.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        users: action.payload.users,
        totalCount: action.payload.userCount,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function AdminUsers() {
  const [{ loading, error, users, itemQuantity, totalCount }, dispatch] = useReducer(
    reducer,
    {
      users: [],
      loading: true,
      totalCount: 0,
      error: "",
      itemQuantity: 0,
    }
  );

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creatingUser, setCreatingUser] = useState(false); 
  const [editingUser, setEditingUser] = useState(false);
  const { search } = useLocation();
	const searchParams = new URLSearchParams(search);


	const { setPageSize, setPage, fields, siblingCount } = 
  useQueryFilters({ baseUrl: '/AdminScreen/AdminUsers', resizeWidth: 400 });

    useEffect(() => {
      if (parseInt(fields.page) > Math.ceil(totalCount / fields.pageSize)) {
        setPage(1); // Reset page if it's out of bounds
      }
    }, [search, totalCount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await _users.list(searchParams);
        dispatch({ type: "FETCH_SUCCESS", payload: data });
        setSelectedUser(null);
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
        console.error(err);
        toast.error("No se pudo obtener a los usuarios");
      }
    };
    fetchData();
  }, [userInfo, editingUser, creatingUser,search]);

  const submitHandler = async (user, createUser) => {
    if(createUser){
      setCreatingUser(true);
      try {
        await _users.create(user);
        toast.success("Usuario creado con éxito");
        
      } catch (err) {
        toast.error(getError(err));
      }
      setCreatingUser(false);

    } else{
      try {
        setEditingUser(true);
        await _users.editById(user);
        toast.success("Usuario actualizado con éxito");
      
      } catch (err) {
        toast.error(getError(err));
      }
      setEditingUser(false);

    }
    setSelectedUser(null);
    setShowAddUserModal(false);
  };

  const deleteHandler = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      await _users.delete(selectedUser._id);
      setShowDeleteModal(false);
      toast.success(`Usuario ${selectedUser.name} eliminado exitosamente`);
    } catch (error) {
      toast.error("Error al eliminar el usuario");
    }
  };

  const editHandler = (user) => {
    setSelectedUser(user);
    setShowAddUserModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setShowAddUserModal(false);
  }

  return (
    <>
      <Helmet>
        <title>Lista de usuarios</title>
      </Helmet>
      <AddUser
        close={()=> handleCloseModal()}
        show={showAddUserModal}
        onSuccess={submitHandler}
        userData={selectedUser}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        user={selectedUser}
        handleDelete={handleDeleteUser}
      />
      <div id="admin-users-container" className="admin-con p-3">
        <h1 className="text-right section-title" style={{ marginBottom: "2.5%" }}>
          <FaUsers></FaUsers>Lista de usuarios
        </h1>
        <div className="row adminProdBtnRow align-items-center">
          <div className="col-lg-8 col-sm-12 col-md-12 search-bar-container"></div>
          <div className="col-lg-3 col-sm-12">
            <PermissionWrapper fn={"users"} scopes={[SCOPES.canCreate]}>
              <Button onClick={() => {
                setSelectedUser(null)
                setShowAddUserModal(true)}}>
                <FaPlusCircle className="pe-1"></FaPlusCircle> Agregar usuario
              </Button>
            </PermissionWrapper>
          </div>
          <ExportToExcelButton
						dataFetcherFunction={_users.list.bind(this,searchParams,true)} 
						data={users}
						dataName={'users'}
						overlayPlacement='top'
					/>
        </div>
        {loading || creatingUser || editingUser ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Card>
            <DynamicListTable
              data={users}
              loading={loading}
              error={error}
              count={itemQuantity}
              totalCount={totalCount}
              itemsPerPage={fields.pageSize}
              setPageSize={setPageSize}
              currentPage = {fields.page}
              onPageChange={setPage}
              dataName="users"
              showCheckboxColumn={false}
              links={false}
              exportOption={false}
              siblingCount={siblingCount}
              actionButtons={{
                editHandler: editHandler,
                deleteHandler: deleteHandler,
              }}
            />
          </Card>
        )}
      </div>
    </>
  );
}

export default AdminUsers;
