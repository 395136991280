import React from "react";
import "../Screens.css";
import { useLayoutEffect, useReducer } from "react";
import { Card, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import DOMPurify from "dompurify";
import _settings from "../../services/settings.service";
import _richText from "../../services/richText.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        text: action.payload.text,
        gotaSettings: action.payload.settings,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ShippingInfo() {
  const [{ loading, text, gotaSettings }, dispatch] = useReducer(reducer, {
    text: {},
    loading: true,
    error: "",
    gotaSettings: {},
  });

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await _richText.getShippingPage();
        const { data: settings } = await _settings.getPublicData();
        dispatch({
          type: "FETCH_SUCCESS",
          payload: { text: data, settings: settings },
        });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, []);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="container container-envios">
      <Helmet>
        <title>Envios</title>
      </Helmet>
      <Card>
        <Row className="envios-Row">
          <div className="col-lg-6 ">
            <iframe
              title="myTitle"
              src={gotaSettings.deliveryMapUrl}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-lg-6 col col-envios-contact">
            {/* <h1 className="title-col">Condiciones de Envio</h1> */}
            <div dangerouslySetInnerHTML={createMarkup(text.htmlText)}></div>
            {/* <ul>
              <h5>Dias de entrega</h5>
              <li>
                <b>Lunes a viernes:</b> Para recibir su pedido en el día
                corriente, deberá realizarlo antes de las 14:00hs. Si lo hace
                luego de ese horario, posiblemente reciba su pedido el día
                siguiente (Consultar previamente).{" "}
              </li>
              <li>
                <b>Sábados:</b> Para recibir su pedido el mismo sábado, deberá
                realizarlo antes de las 13:30hs. Luego de ese horario, recibirá
                su pedido el lunes siguiente.
              </li>
            </ul>
            <ul>
              <h5>Zonas de Entrega y Costos</h5>
              <li>
                {" "}
                Mínimo de pedidos $900 (3Km a la redonda de nuestra
                ubicación-ver MAPA-) de lo contrario consultar por mínimo de
                envío de su zona{" "}
              </li>
              <li>Consultar Costo de Envio por zonas especificas</li>
            </ul>
            <ul>
              <h5>Pagos y Envio</h5>
              <li>
                Las entregas con pagos con pos se realizan en los horarios de
                14:30 a 17:00 o después de las 20:30hs
              </li>
              <li>Horarios de entrega a coordinar por pagos efectivo.</li>
            </ul>
            <ul>
              <h4>Por consultas 26280292 // 095510460</h4>
            </ul> */}
          </div>
        </Row>
      </Card>
    </div>
  );
}
