import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Store } from "../../Store";
import { useNavigate } from "react-router-dom";
import CheckoutSteps from "../../components/CheckoutSteps/CheckoutSteps.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "./ShippingAddress.css";
import { toast } from "react-toastify";
import "./ShippingAddress.css";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import AddressBook from "../../components/AddressBook/AddressBook";
import { getStatus } from "../../utils";
import _adresses from "../../services/address.service.js";
import CheckoutStepper from "../../components/CheckoutStepper/CheckoutStepper.jsx";
import CheckoutNavigator from "../../components/CheckoutNavigator/CheckoutNavigator.jsx";

function ShippingAddressScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const addressBookBtnRef = useRef(null);
  const {
    userInfo,
    cart: { shippingAddress },
  } = state;
  const [addresses, setAddresses] = useState(null);
  const [addressHasErrors, setAddressHasErrors] = useState(false);
  useEffect(() => {
    if (!state.cart?.cartItems.length) {
      navigate("/");
    }
    const fetchAddresses = async () => {
        try {
          const { data } = await _adresses.list(userInfo._id)
          setAddresses(data);
        } catch (ex) {
          console.error(ex);
        }
    };
    fetchAddresses();
  }, []);

  const addressSubmitHandler = (
    address,
    timeForDelivery,
    comments,
    shippingDate
  ) => {
    ctxDispatch({
      type: "SAVE_SHIPPING_ADDRESS",
      payload: {
        id: address._id,
        addressName: address.addressName,
        name: address.name,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        country: address.country,
        phone: address.phone,
        saveAddress: address.saveAddress,
      },
    });
    ctxDispatch({
      type: "SAVE_ORDER_DELIVERY_DETAILS",
      payload: {
        timeForDelivery: timeForDelivery,
        comments: comments,
        shippingDate: shippingDate,
      },
    });
    navigate("/checkout/payment");
  };
  const triggerSubmit = () => {
    addressBookBtnRef.current.click();
  }
  return (<>
    <div className="order-shipping-address-screen">
      <Helmet>
        <title>Datos de envio</title>
      </Helmet>

      {addresses ? (
        <AddressBook
          userInfo={userInfo}
          addresses={addresses}
          submitHandler={addressSubmitHandler}
          setAddresses={setAddresses}
          contextShippingAddress={shippingAddress}
          title="Enviar a:"
          addressBookRef={addressBookBtnRef}
          setAddressHasErrors={setAddressHasErrors}
        ></AddressBook>
      ) : (
        <LoadingBox></LoadingBox>
      )}
    </div>
    <CheckoutNavigator activeStep={2} nextStepDisabled = {addressHasErrors} nextStepHandler = {triggerSubmit}/>
    </>
  );
}

export default ShippingAddressScreen;
