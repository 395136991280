import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import "./PillInput.css";
import classnames from 'classnames';

function PillInput({value, id, onChange, onSelect, addHandler, removeHandler, placeholder, className, selected = false, disabled = false}) {
    const [newValue, setNewValue] = useState("");
    const onChangeNewValue = (e) => setNewValue(e.target.value);

    const add = async() => {
        await addHandler(newValue);
        setNewValue("");
    }
    return <Badge onClick={addHandler || disabled ? null : onSelect} pill className={classnames("variant-option-pill", { disabled : disabled && !selected, selected, new: addHandler, [className]: className })}>
    { addHandler ? 
        <Form.Control className='pill-input' placeholder={placeholder} onChange={onChange || onChangeNewValue}
        value={value || newValue}/>
        :
        <div>
            {value}
        </div>
    }
{ addHandler && 
    <button 
        className='pill-button' onClick={add}>
        <FaPlusCircle />
    </button>}
{ removeHandler && 
    <button 
        className='pill-button' onClick={() => removeHandler(id)}>
        <FaTimesCircle />
    </button>}
</Badge>
  
}

export default PillInput