import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState, useReducer } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import _settings from "../../services/settings.service";

export default function VerifyAdminEmail() {
  const navigate = useNavigate();
  const params = useParams();
  const { token } = params;

  useEffect(() => {
    const verifyUser = async () => {
      try {

        await _settings.verifyAdminEmail(token);

        toast.success("E-mail verificado correctamente");
      } catch (err) {
        console.error(err);
        toast.error("No se pudo verificar el e-mail");
      }
      navigate("/");
    };
    verifyUser();
  }, [navigate]);

  return <div></div>;
}