import React, { useEffect, useState } from "react";
import "../SearchBoxOverlay/SearchBoxOverlay.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import SearchBox from "../SearchBox/SearchBox";

function SearchComponent({ showSearch, handleSearchToggle }) {
  const handleSearchBlur = () => {
    handleSearchToggle(false);
  };

  const [showBar, setShowBar] = useState(showSearch);

  useEffect(() => {
    setShowBar(showSearch);
  }, [showSearch]);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    handleSearchToggle(false);
    navigate(query ? `/search/?query=${query}` : "/search");
  };

  const onChange = (e) => {
    setQuery(e.target.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, ""))
  }
  return (
    <>
      {showBar && (
        <div className="search-box-overlay">
          <Form onSubmit={submitHandler} className="w-100 d-flex justify-content-center">
            <SearchBox value = {query} onChange={onChange} onBlur={handleSearchBlur} />
          </Form>
        </div>
      )}
    </>
  );
}

export default SearchComponent;
