import React from "react";
import "./AdminConfig.css";
import { FaCog } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import EmailConfig from "../../components/EmailConfig/EmailConfig.jsx";
import ColorConfig from "../../components/ColorConfig/ColorConfig.jsx";
import AdminInfo from "../../components/AdminInfoConfig/AdminInfo.jsx";
import FaqQuestionsConfig from "../../components/FaqQuestionsConfig/FaqQuestionsConfig/FaqQuestionsConfig.jsx";
import ContactPageEditor from "../ContactPageEditor/ContactPageEditor";
import ShippingPageEditor from "../ShippingPageEditor/ShippingPageEditor";
import MercadoPagoConfig from "../../components/MercadoPagoConfig/MercadoPagoConfig";
import PaymentMethodConfig from "../../components/PaymentMethodConfig/PaymentMethodConfig";
import AdminBanners from "../AdminBanners/AdminBanners";
import DeliverySchedule from "../DeliverySchedule/DeliverySchedule.jsx";
import DiscountCodes from "../../components/DiscountCodes/DiscountCodes.jsx";

export default function AdminConfigScreen() {
  return (
    <div id = "admin-config-screen" className="admin-con p-3">
      <h1 className="text-right section-title">
        <FaCog></FaCog>Configuración
      </h1>
      <div className="container config-items">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Datos de la empresa</Accordion.Header>
            <Accordion.Body>
              <AdminInfo></AdminInfo>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Correos Electrónicos del Sistema
            </Accordion.Header>
            <Accordion.Body>
              <EmailConfig></EmailConfig>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Tema</Accordion.Header>
            <Accordion.Body>
              <ColorConfig></ColorConfig>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Preguntas frecuentes</Accordion.Header>
            <Accordion.Body>
              <FaqQuestionsConfig></FaqQuestionsConfig>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>MercadoPago</Accordion.Header>
            <Accordion.Body>
              <MercadoPagoConfig></MercadoPagoConfig>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Pagina de contacto</Accordion.Header>
            <Accordion.Body>
              <ContactPageEditor></ContactPageEditor>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Pagina de envíos</Accordion.Header>
            <Accordion.Body>
              <ShippingPageEditor></ShippingPageEditor>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Métodos de pago</Accordion.Header>
            <Accordion.Body>
              <PaymentMethodConfig></PaymentMethodConfig>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Banners</Accordion.Header>
            <Accordion.Body>
              <AdminBanners></AdminBanners>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>Horarios de reparto</Accordion.Header>
            <Accordion.Body>
              <DeliverySchedule/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Códigos de descuento</Accordion.Header>
            <Accordion.Body>
              <DiscountCodes/>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
