import React, { useEffect, useRef, useState } from 'react';
import "./CustomSelect.css"
import classnames from 'classnames';
import { GoChevronDown } from "react-icons/go";

export default function CustomSelect({ label, options = [], onChange, value = {}, className, fontSize = "1em", disabled}) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value.title || "-");
    const optionsRef = useRef(null);
    const containerRef = useRef(null)

    const closeMenu = (e) => {
        if(!e || !e.relatedTarget || e.relatedTarget !== optionsRef.current) {
            setMenuIsOpen(false)
        }
    };
    const toggleMenu = (e) => {
        if(!disabled) {
            setMenuIsOpen(menuIsOpen => !menuIsOpen);
        }
    };

    const handleSelectOption = (e, option) => {
        e.stopPropagation();
        closeMenu();
        setSelectedValue(option.title)
        onChange(option.value);
    }
    
    return <div  tabIndex={0} ref = {containerRef} onClick={toggleMenu} onBlur={closeMenu} style = {{fontSize : fontSize}}className={classnames("custom-select", { open: menuIsOpen, disabled, [className] : className})}>
        <div className="content">
            <label  tabIndex={0} >{label}</label>
            <input value={selectedValue}  className = "custom-select-input" readOnly type="text" />
            <div tabIndex={0} ref={optionsRef} className="options">
                {options.map((option,key) =>
                    <div key = {`${option.value + key}`} className="dropdown-option" onClick={(e) => handleSelectOption(e, option)}>
                        {option.title}
                    </div>
                )}
            </div>
        </div>
        <div className="toggle">
            <button className='custom-select-toggle-button'>
            <GoChevronDown />
            </button>
        </div>
    </div>
}
