import axios from '../restClient';

const BASE_URI = '/products';

class ProductService {
	async list() {
		const res = await axios.get(BASE_URI);
		return res;
	}
	async get(id) {
		const res = await axios.get(`${BASE_URI}/${id}`);
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async edit(id, data) {
		const res = await axios.put(`${BASE_URI}/${id}`, data);
		return res;
	}
	/**
	 * 
	 * @param {{}} searchParams 
	 * @returns Product list filtered by paramaters sent
	 */
	async search(searchParams) {

		const res = await axios.get(`${BASE_URI}/search`, {
			params: searchParams,
		});
		return res;
	}
	//TODO: revisar este endpoint, es redundante creo
	/**
	 * 
	 * @param {URLSearchParams} searchParams 
	 * @returns AxiosResponse
	 */
	async adminSearch(searchParams) {
		const searchParamsKeys = searchParams.keys();
		const query = {};
		for (const key of searchParamsKeys) {
			query[key] = searchParams.get(key);
		}
		const res = await axios.get(`${BASE_URI}/AdminScreen/AdminProducts/search`, {
			params: query,
		});
		return res;
	}
	async updateProductStock(id, stock) {
		const res = await axios.put(`${BASE_URI}/update-product-stock/${id}`, {
			stock,
		});
		return res;
	}
	async getBySlug(slug) {
		const res = await axios.get(`${BASE_URI}/slug/${slug}`);
		return res;
	}
	async getWithVariants(id) {
		const res = await axios.get(`${BASE_URI}/product-with-variants/${id}`);
		return res;
	}
	async getDrafts() {
		const res = await axios.get(`${BASE_URI}/draft/pending`);
		return res;
	}
	async createDraft(productData) {
		const res = await axios.post(`${BASE_URI}/draft`, {
			productData,
		});
		return res;
	}
	async editDraft(productId, productData) {
		const res = await axios.patch(
			`${BASE_URI}/draft`,
			{
				productData,
			},
			{ params: { productId } },
		);
		return res;
	}
	async getRelatedProducts(slug, categoryId) {
		const res = await axios.get(
			`${BASE_URI}/similProducts/${slug}/${categoryId}`,
		);
		return res;
	}
	async uploadProductImage(formData) {
		const res = await axios.post(
			BASE_URI + '/uploadProductImg',
			formData,
			{
				headers : {
					"Content-Type": "multipart/form-data",
				}
			},
		);
		return res;
	}
	async deleteProductImage(imageId, productId) {
		const res = await axios.delete(`${BASE_URI}/image/${imageId}/${productId}`);
		return res;
	}
	async getProductsWithCategory(categoryId) {
		const res = await axios.get(`${BASE_URI}/category/${categoryId}`);
		return res;
	}

	async updateProductsPrice(products) {
    const res = await axios.post(`${BASE_URI}/update-products-price`, {
      products,
    });
  
  }

}
export default new ProductService();
