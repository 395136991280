import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState, useReducer } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import _user from "../../services/user.service";

export default function VerifyUser() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { token } = params;
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, user: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  useEffect(() => {
    const verifyUser = async () => {
      try {
        dispatch({ type: "USER_REQUEST" });
        const { data } = await _user.verify(token)
        ctxDispatch({ type: "USER_SIGNIN", payload: data });
        navigate("/");
        toast.success("Usuario verificado correctamente");
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
        toast.error(getError(err));
      }
    };
    verifyUser();
  }, [navigate]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, user }, dispatch] = useReducer(reducer, {
    user: {},
    loading: true,
    error: "",
  });

  // const submitHandler = async (e) => {
  //   try {
  //     console.log('tk',token)
  //     const { data } = await axios.put(
  //       `/api/users/verifyUser/${token}`,
  //     );
  //     dispatch({
  //       type: "UPDATE_SUCCESS",
  //     });
  //     ctxDispatch({ type: "USER_SIGNIN", payload: data });
  //     localStorage.setItem("userInfo", JSON.stringify(data));
  //     navigate("/");
  //     toast.success("Usuario actualizado correctamente");
  //   } catch (err) {
  //     dispatch({ type: "FETCH_FAIL" });
  //     toast.error(getError(err));
  //   }
  // };

  return <div></div>;
}
