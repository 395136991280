import { Helmet } from "react-helmet-async";
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import { getError, responsive } from "../../utils";
import { Store } from "../../Store";
import GoBackBtn from "../../components/GoBackButton/GoBackBtn.jsx";
import "./ProductScreen.css";
import Product from "../../components/Product/Product.jsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import PillInput from "../../components/PillInput/PillInput.jsx";
import _products from "../../services/product.service.js";
import _productVariants from "../../services/productVariant.service.js";
import AddToCartButton from "./AddToCartButton.jsx";


function ProductScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const [optionsOfVariant, setOptionsOfVariant] = useState([]);
  const [loadedVariant,setLoadedVariant] = useState(null);

  const { state, dispatch: cxtDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const { cartItems } = cart;
  const [item, setItem] = useState("");
  const [numOfProd, setNumOfProd] = useState(1);
  const [quantityError, setQuantityError] = useState(null);
  const [{ loading, error, product, options, optionValues, categoryId
     ,variants, hasVariants, simil }, dispatch] = useReducer(reducer, {
    simil: [],
    product: {},
    hasVariants: false,
    variants : [],
    options: [],
    optionValues: [],
    loading: true,
    error: "",
    quantityError,
    categoryId: ""
  });


  //#region EFFECTS
  useEffect(() => {
    if (!product._id || product.slug !== slug) {
      fetchProduct();
    }
    if (hasVariants && product._id && !options.length && !optionValues.length) {
      fetchVariantsData();
    }
    if (categoryId) {
      fetchSimil();
    }
  }, [slug, hasVariants, product, categoryId]);

  useEffect(() => {
      const parsedQty = parseInt(numOfProd);
      //cambiar && por notacion .? cuando se actualice node
      const stock = product.hasVariants ? (loadedVariant && loadedVariant.countInStock) : product.countInStock;
      const parsedStock = parseInt(stock);
      if(parsedStock >=0 ) {
        if(parsedQty) {
        if (parsedQty <= stock) {
          setQuantityError(null)
        } else {
          setQuantityError("Ingrese una cantidad menor.");
        }
      } else {
        setQuantityError("Debe ingresar una cantidad de 1 o más.");
      }
    }
  }
  , [numOfProd]);


  //#endregion 
  //#region REQUESTS
  async function fetchProduct() {
    dispatch({ type: actions.FETCH_REQUEST });
    try {
      const { data: product } = await _products.getBySlug(slug);
      dispatch({
        type: actions.FETCH_PRODUCT_SUCCESS,
        payload: {
          product, categoryId: product.category ? product.category._id : null,
          hasVariants: product.hasVariants, loading: product.hasVariants
        }
      });
    } catch (error) {
      dispatch({ type: actions.FETCH_DATA_FAIL, payload: getError(error) });
    }
  }
  async function fetchVariantsData() {
    try {
      const { data } = await _products.getWithVariants(product._id);
      dispatch({type: actions.FETCH_PRODUCT_WITH_VARIANTS_SUCCESS, payload: data})
    } catch (ex) {
      console.error(ex);
      dispatch({  type: actions.FETCH_DATA_FAIL, payload: getError(ex) });
    }
  }
  async function fetchSimil() {
    dispatch({ type: actions.FETCH_SIMILPRODUCTS_REQUEST});
    try {
      const {data} = await _products.getRelatedProducts(slug, categoryId);
      dispatch({
        type: actions.FETCH_SIMILPRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: actions.FETCH_SIMILPRODUCTS_FAIL,
        payload: getError(error),
      });
    }
  };
  //#endregion




  const cartHandler = async (e) => {
    setNumOfProd(e.target.value);
  };

  const addToCartHandler = async () => {
    const parsedNumOfProd = parseInt(numOfProd);
    const {hasVariants} = product;
    if (parsedNumOfProd) {
      if ((!hasVariants || !options.length) || loadedVariant) {
        const productRequest = (hasVariants && options.length)
          ? _productVariants.get.bind(this, loadedVariant._id)
          : _products.get.bind(this, product._id);
        try {
          const { data: prod } = await productRequest();
          const existingProduct = cartItems.find((cartItem) => cartItem._id === prod._id);
          const quantity = existingProduct ? parseInt(existingProduct.quantity) + parsedNumOfProd : parsedNumOfProd;
          let totalItemsQuantity = quantity;
          if (prod.countInStock >= totalItemsQuantity) {
            cxtDispatch({ type: "CART_ADD_ITEM", payload: { ...prod, quantity} });
            cxtDispatch({ type: "OPEN_CART"});
          } else {
            toast.warning("El stock no es suficiente.");
          }
        } catch(ex) {
        console.error(ex);
        }
      } else {
        toast.warning("Debe seleccionar una opción");
      }
    } else {
      toast.warning("Cantidad inválida.");
    }
  };
  const selectValueHandler = ({_id: valueToAdd, variantOption: optionToAdd}) => {
    const existingOptionValueIndex = optionsOfVariant
    .findIndex(({option,value}) => option === optionToAdd && value === valueToAdd );
    if(existingOptionValueIndex === -1) {
      const optionsOfVariantCopy = [...optionsOfVariant];
      const existingOptionIndex = optionsOfVariantCopy.findIndex(({option}) => option === optionToAdd);
      if(existingOptionIndex >= 0) {
        optionsOfVariantCopy.splice(existingOptionIndex, 1);
      }
      optionsOfVariantCopy.push({ option: optionToAdd, value: valueToAdd });
      if(optionsOfVariantCopy.length === options.length) {
        const variant = findVariant(optionsOfVariantCopy);
        if(variant) {
          setLoadedVariant(variant);
        }
      }
      setOptionsOfVariant(optionsOfVariantCopy);
    }
  }
  const findVariant = (optionsToMatch) => {
    const variant = variants.find(variant => {
      return variant.options.every(variantOption => optionsToMatch
        .some(({option,value}) => variantOption.option._id === option && variantOption.value._id === value));
    })
    return variant;
  }


  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  const getImageForProduct = () => {
    const alt = product.name;
    let className = 'empty-list-image-preview';
    let src = '/images/emptyPhoto.png';
    if(product.hasVariants) {
      if(loadedVariant?.image || variants.some(v => v.image)) {
        src = loadedVariant?.image?.fileLink || variants.find(v => v.image).image.fileLink;
        className = 'prod-page-img-lg';
      }
    } else if(product.image?.fileLink) {
      src = product.image?.fileLink;
      className = 'prod-page-img-lg';
    }
    return <img
      className={className}
      src={src}
      alt={alt}
    />
  }
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className="productScreenContainer">
      <Card>
        <Card.Body>
          <Row>
            <Col lg={8}>              
              <Row className="justify-content-center"> 
              {getImageForProduct()}
              </Row>
            </Col>
            <Col lg={4}>
              <GoBackBtn></GoBackBtn>
              <Card>
                <Card.Body>
                  <Helmet>
                    <title>{product.name.toUpperCase()}</title>
                  </Helmet>
                  <Row>
                    {" "}
                    <h1 className="font-weight-700">
                      {product.name.toUpperCase()}
                    </h1>
                    <p>
                      {product.active && product.countInStock > 0 ? (
                        <Badge bg="success" className="p-1">
                          Disponible
                        </Badge>
                      ) : !product.active ? (
                        <Badge bg="danger">No disponible</Badge>
                      ) : (
                        <Badge bg="danger">Sin stock</Badge>
                      )}
                    </p>
                  </Row>
                  <Row>
                    {" "}
                    <p>{product.brand}</p>
                  </Row>
                  <Row className="align-items-center d-flex ">
                    <h1>{loadedVariant ? `$${loadedVariant.price}` : product.priceRange || `$${product.price}`}</h1>
                  </Row>
                  <hr />
                  {
                    product.active && hasVariants && options.length > 0 && options.map((opt) => (
                      <div className="option-value-list" key={opt._id}>
                        <h4>
                          {opt.name}
                        </h4>
                        <div className="value-list">
                          {
                            optionValues.filter(v => v.variantOption === opt._id)
                            .map((v,i) => (
                              <PillInput
                              onSelect = {() => selectValueHandler(v)}
                              selected = {optionsOfVariant.some(opt => opt.value === v._id)}
                              value={v.value} key={v.value + "optionvalue" + i}/>
                              ))
                          }
                        </div>
                      </div>
                    ))
                  }
                      <AddToCartButton item={item} product={product}
                        loadedVariant={loadedVariant}
                        options={options}
                        optionsOfVariant={optionsOfVariant}
                        numOfProd={numOfProd}
                        cartHandler={cartHandler}
                        quantityError={quantityError}
                        addToCartHandler={addToCartHandler} />
                      <p>{product.description}</p>
                </Card.Body>
              </Card>
            </Col>
{ product.htmlDescription &&  <>
            <Row className="mt-5">
              <h1>
            Descripcion:
              </h1>
            </Row>
            <Row>
              <Col>
                <div className="product-description-container"
                  dangerouslySetInnerHTML={createMarkup(product.htmlDescription)}
                  ></div>
              </Col>
            </Row>
            </>}
          </Row>
          {simil.length != 0 ? (
            <Row>
              <h1 style={{ margin: "2.5% auto" }}>Mas productos</h1>
              <Carousel responsive={responsive} infinite={true}>
                {simil.map((product) =>
                  product.active ? (
                    <Col lg={10} sm={10} key={product._id}>
                      <Product product={product}></Product>
                    </Col>
                  ) : null
                )}
              </Carousel>
            </Row>
          ) : (
            <Row></Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
export default ProductScreen;

const reducer = (state, action) => {
  switch (action.type) {
    case actions.FETCH_REQUEST:
      return { ...state, loading: true };
    case actions.FETCH_PRODUCT_SUCCESS:
      return {
        ...state, product: action.payload.product, hasVariants: action.payload.hasVariants,
        categoryId: action.payload.categoryId, loading: action.payload.loading
      };
    case actions.FETCH_PRODUCT_WITH_VARIANTS_SUCCESS:
      return {
        ...state,options : action.payload.options,
        optionValues : action.payload.values, variants : action.payload.variants,
        loading: false
      }
    case actions.FETCH_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    case actions.FETCH_SIMILPRODUCTS_REQUEST:
      return { ...state, loading: true };
    case actions.FETCH_SIMILPRODUCTS_SUCCESS:
      return { ...state, simil: action.payload, loading: false };
    case actions.FETCH_SIMILPRODUCTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const actions = {
  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_PRODUCT_WITH_VARIANTS_SUCCESS: "FETCH_PRODUCT_WITH_VARIANTS_SUCCESS",
  FETCH_PRODUCT_SUCCESS:"FETCH_PRODUCT_SUCCESS",
  FETCH_DATA_FAIL: "FETCH_DATA_FAIL",
  FETCH_SIMILPRODUCTS_REQUEST: "FETCH_SIMILPRODUCTS_REQUEST",
  FETCH_SIMILPRODUCTS_FAIL: "FETCH_SIMILPRODUCTS_FAIL",
  FETCH_SIMILPRODUCTS_SUCCESS: "FETCH_SIMILPRODUCTS_SUCCESS"


}