import React from 'react';
import "./NavbarToggler.css";
import { HiBars3 } from "react-icons/hi2";
import classnames from 'classnames';
import { isColorDark } from '../../utils/colorUtils';



export default function NavbarToggler({ onClick, className, backgroundColor = "#ffffff" }) {

    return <div className={classnames("nav-toggler", { [className]: className, light: isColorDark(backgroundColor) })} >
        <button onClick={onClick}>
            <HiBars3 />
        </button>
    </div>
}