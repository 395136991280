import React, {
  useContext,
  useState,
  useReducer,
  useEffect,
  useRef,
} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import "./AdminInfo.css";
import { getError, getStatus, whatsappBaseUrl } from "../../utils";
import _settings from "../../services/settings.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, gotamarketsettings: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const AdminInfo = () => {
  const [{ loading, error, gotamarketsettings }, dispatch] = useReducer(
    reducer,
    {
      gotamarketsettings: {},
      loading: true,
      error: "",
    }
  );

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [companyName, setCompanyName] = useState("");
  const [companysubName, setCompanysubName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [googleMapLocationUrl, setGoogleMapLocationUrl] = useState("");
  const [deliveryMapUrl, setDeliveryMapUrl] = useState("");
  const [minStockToConfirm, setMinStockToConfirm] = useState("");
  const [usesOrderConfirmation, setUsesOrderConfirmation] = useState(false);
  const [usesShipping, setUsesShipping] = useState(false);
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState(null);
  const inputFileLogoRef = useRef(null);
  const inputFileIconRef = useRef(null);


  //#region REQUESTS
  const fetchData = async () => {
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const { data } = await _settings.getPublicData();
      setCompanyName(data.companyName);
      setCompanysubName(data.companysubName);
      setCompanyAddress(data.companyAddress);
      setCompanyPhone(data.companyPhone);
      setCompanyCity(data.companyCity);
      setWhatsappNumber(data.whatsappNumber);
      setUsesOrderConfirmation(data.usesOrderConfirmation)
      setUsesShipping(data.usesShipping)
      setFacebookUrl(data.facebookUrl);
      setInstagramUrl(data.instagramUrl);
      setDeliveryMapUrl(data.deliveryMapUrl);
      setGoogleMapLocationUrl(data.googleMapLocationUrl);
      setMinStockToConfirm(data.minStockToConfirm || "");
      if (data.favicon) {
        setIcon(data.favicon);
      }
      if (data.image) {
        setImage(data.image);
      }
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: error.message });
    }
  };
  const setGotamarketsettings = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const whatsappUrl = `${whatsappBaseUrl}${whatsappNumber}`;
      const { data } = await _settings.edit(
        {
          companyName,
          companysubName,
          companyAddress,
          companyPhone,
          image,
          companyCity,
          whatsappNumber,
          usesOrderConfirmation,
          usesShipping,
          whatsappUrl,
          facebookUrl,
          instagramUrl,
          googleMapLocationUrl,
          deliveryMapUrl,
          icon,
          minStockToConfirm
        }
      );
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      ctxDispatch({ type: "UPDATE_SUCCESS", payload: data });
      ctxDispatch({ type: "UPDATE_GLOBAL_PARAMS", payload: { minStockToConfirm , usesShipping} });
      toast.success("Empresa actualizada Correctamente");
    } catch (error) {
      dispatch({ type: "FETCH_FAIL" });
      console.error(error);
      toast.error(getError(error));
    }
  };
  const uploadPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);

    try {
      if (image) {
        formData.append("documentId", image._id);
      }
      const response = await _settings.uploadLogo(formData);
      setImage(response.data);
      toast.success("imagen agregada correctamente");
    } catch (ex) {
      console.error(ex);
      toast.error("Error subiendo imagen");
    }
  };
  const uploadIcon = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    try {
      if (icon) {
        formData.append("documentId", icon._id);
      }
      const response = await _settings.uploadIcon(formData);
      setIcon(response.data);
      toast.success("Icono agregado correctamente");
    } catch (ex) {
      console.error(ex);
      toast.error("Error subiendo icono.");
    }
  };
  //#endregion
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="admin-info">
      <div className="container  ps-0 mt-2">
        <Form>
          <Row className="justify-content-between">
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companyName"
            >
              <Form.Label>Nombre de Empresa</Form.Label>
              <Form.Control
                onChange={(e) => setCompanyName(e.target.value)}
                defaultValue={companyName}
              ></Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="instagramUrl"
            >
              <Form.Label>URL Instagram</Form.Label>
              <Form.Control
                onChange={(e) => setInstagramUrl(e.target.value)}
                defaultValue={instagramUrl}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companysubName"
            >
              <Form.Label>Frase/tipo de Empresa</Form.Label>
              <Form.Control
                onChange={(e) => setCompanysubName(e.target.value)}
                defaultValue={companysubName}
              ></Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="whatsappNumber"
            >
              <Form.Label>Numero Whatsapp</Form.Label>
              <Form.Control
                onChange={(e) => setWhatsappNumber(e.target.value)}
                defaultValue={whatsappNumber}
              ></Form.Control>
              <small id="emailHelp" className="form-text text-muted">
                Respetando formato: 5989xxxxxxx
              </small>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companyAdress"
            >
              <Form.Label>Direccion de Empresa</Form.Label>
              <Form.Control
                onChange={(e) => setCompanyAddress(e.target.value)}
                defaultValue={companyAddress}
              ></Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="facebookUrl"
            >
              <Form.Label>URL de Facebook</Form.Label>
              <Form.Control
                onChange={(e) => setFacebookUrl(e.target.value)}
                defaultValue={facebookUrl}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companyCity"
            >
              <Form.Label>Ciudad-Pais</Form.Label>
              <Form.Control
                onChange={(e) => setCompanyCity(e.target.value)}
                defaultValue={companyCity}
              ></Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="deliveryMapUrl"
            >
              <Form.Label>URL de zona de envíos</Form.Label>
              <Form.Control
                onChange={(e) => setDeliveryMapUrl(e.target.value)}
                defaultValue={deliveryMapUrl}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companyPhone"
            >
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                onChange={(e) => setCompanyPhone(e.target.value)}
                defaultValue={companyPhone}
              ></Form.Control>
            </Form.Group>
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="companyLocationUrl"
            >
              <Form.Label>URL de ubicacion de la empresa</Form.Label>
              <Form.Control
                onChange={(e) => setGoogleMapLocationUrl(e.target.value)}
                defaultValue={googleMapLocationUrl}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row className="align-items-center">
            <Form.Group
              className="mb-3 col-md-3 col-12"
              controlId="companyLocationUrl"
            >
              <Form.Label>Stock minimo</Form.Label>
              <Form.Control
                onChange={(e) => setMinStockToConfirm(e.target.value)}
                type="number"
                value={minStockToConfirm}
              ></Form.Control>
            </Form.Group>
          <Col md={3} className="d-flex justify-content-center">
              <Form.Group >
              <Form.Label className="me-1">Utiliza envíos</Form.Label>
                <Form.Check
                  inline
                  checked={usesShipping}
                  onChange={(e) => setUsesShipping(e.target.checked)}
                ></Form.Check>
              </Form.Group>
            </Col>
            <Col md={3} className="d-flex justify-content-center">
              <Form.Group className="" >
              <Form.Label className="me-1">Confirmacion de pedidos</Form.Label>
                <Form.Check
                inline
                checked={usesOrderConfirmation}
                  onChange={(e) => setUsesOrderConfirmation(e.target.checked)}
                ></Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col className="row align-items-center mb-2" lg={6}>
              <Form.Group className="d-none">
                <Form.Control
                  ref={inputFileLogoRef}
                  id="input-file-logo"
                  type="file"
                  className="d-none"
                  onChange={uploadPicture}
                  name="logo"
                ></Form.Control>
              </Form.Group>
              <Button
                className="btn-file col-7 col-md-6 m-0 h-50"
                onClick={() => inputFileLogoRef.current.click()}
              >
                {" "}
                Subir logo{" "}
              </Button>
              <Col md={6} className="col-5 d-flex justify-content-start">
                {image && image.fileLink ? (
                  <img
                    alt="logo_photo"
                    src={image.fileLink}
                    className="logo-photo img-fluid rounded img-thumbnail"
                  />
                ) : (
                  <img
                    alt="logo_photo"
                    src="/images/emptyPhoto.png"
                    className="logo-photo img-fluid rounded img-thumbnail"
                  />
                )}
              </Col>
            </Col>
            <Col lg={6} className="row align-items-center">
              <Form.Group className="d-none" controlId="inputIcon">
                <Form.Control
                  ref={inputFileIconRef}
                  type="file"
                  onChange={uploadIcon}
                  name="icon"
                  className="d-none"
                ></Form.Control>
              </Form.Group>
              <Button
                onClick={() => inputFileIconRef.current.click()}
                className="btn-file col-7 col-md-6 m-0 h-50"
              >
                Subir Icono
              </Button>
              <Col md={6} className="col-5 d-flex justify-content-start mb-1">
                {icon && icon.fileLink ? (
                  <img
                    alt="logo_photo"
                    src={icon.fileLink}
                    className="logo-photo img-fluid rounded img-thumbnail"
                  />
                ) : (
                  <img
                    alt="logo_photo"
                    src="/images/emptyPhoto.png"
                    className="logo-photo img-fluid rounded img-thumbnail"
                  />
                )}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button md={2} onClick={setGotamarketsettings}>
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default AdminInfo;
