import React, { useEffect } from 'react'
import Stepper from '../Stepper/Stepper'
import classnames from 'classnames';
export default function CheckoutStepper({ className, activeStep = 1, usesShipping = true}) {
    const STEPS = [{
        counter: 1,
        name: 'Iniciar sesión',
    },
    {
        counter: 2,
        name: 'Envío',
    },
    {
        counter: 3,
        name: 'Pago',
    },
    {
        counter: 4,
        name: 'Resumen',
    }];

    const STEPS_WITHOUT_SHIPPING = [{
        counter: 1,
        name: 'Iniciar sesión',
    },
    {
        counter: 2,
        name: 'Pago',
    },
    {
        counter: 3,
        name: 'Confirmar pedido',
    }];

  return <Stepper className={classnames('checkout-stepper',{[className] : className})} 
            steps = {usesShipping   ? STEPS : STEPS_WITHOUT_SHIPPING} activeStep={activeStep}/>
}
