import axios from '../restClient';

const BASE_URI = '/texts';
class RichTextService {
	async getContactPage() {
		const res = await axios.get(`${BASE_URI}/contacto`);
		return res;
	}
	async editContactPage(htmlText) {
		const res = await axios.put(`${BASE_URI}/modifyContacto`, { htmlText });
		return res;
	}
	async getShippingPage() {
		const res = await axios.get(`${BASE_URI}/envios`);
		return res;
	}
	async editShippingPage(htmlText) {
		const res = await axios.put(`${BASE_URI}/modifyEnvios`, { htmlText });
		return res;
	}
}
export default new RichTextService();
