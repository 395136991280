import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PRICES, getError } from "../../utils";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import MessageBox from "../../components/MessageBox/MessageBox.jsx";
import Product from "../../components/Product/Product.jsx";
import "./SearchScreen.css";
import { Store } from "../../Store";
import Pagination from "../../components/ParamPagination/ParamPagination.jsx";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FaFilter } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import _products from "../../services/product.service.js";
import _categories from "../../services/category.service.js";

const categoriesToMap = (categories) => {
  const map = {};
  categories.forEach((category) => {
    map[category._id] = category.name;
  });
  return map;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_CATEGORIES_REQUEST":
      return { ...state, loadingCategories: true };
    case "FETCH_CATEGORIES_SUCCESS":
      return {
        ...state,
        loadingCategories: false,
        categories: action.payload,
        categoriesMap: categoriesToMap(action.payload),
      };
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        countProducts: action.payload.countProducts,
        pageSize: action.payload.pageSize,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "SET_PAGE_SIZE": 
    return {...state, pageSize: action.payload}
    default:
      return state;
  }
};

export default function SearchScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); // /search?category=Shirts
  const category = sp.get("category") || "all";
  const query = sp.get("query") || "all";
  const price = sp.get("price") || "all";
  const rating = sp.get("rating") || "all";
  const order = sp.get("order") || "newest";
  const pageSize = sp.get("pageSize") || 16;
  let page = sp.get("page") || 1;
  const [isMobile, setIsMobile] = useState(false);
  const [toggleCatFilter, setToggleCatFilter] = useState(false);
  const [togglePriceFilter, setTogglePriceFilter] = useState(false);

  const handleCatMenu = (e) => {
    e.stopPropagation();
    setToggleCatFilter(!toggleCatFilter);
    setTogglePriceFilter(false);
  };
  const handlePriceMenu = (e) => {
    e.stopPropagation();
    setTogglePriceFilter(!togglePriceFilter);
    setToggleCatFilter(false);
  };
  const [
    {
      loading,
      error,
      products,
      pages,
      countProducts,
      categories,
      categoriesMap,
    },
    dispatch,
  ] = useReducer(reducer, {
    categoriesMap: [],
    categories: [],
    loadingCategories: true,
    loading: true,
    error: "",
    countProducts: 0,
  });

  //#region REQUESTS
  const fetchData = async () => {
    try {
      const {data} = await _products.search({page,query,category, price, rating, pageSize});
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (err) {
      dispatch({
        type: "FETCH_FAIL",
        payload: getError(error),
      });
    }
  };
  const fetchCategories = async () => {
    try {
      const { data } = await _categories.list();
      dispatch({ type: "FETCH_CATEGORIES_SUCCESS", payload: data });
    } catch (err) {
      toast.error(getError(err));
    }
  };
  //#endregion 
  const [siblingCount,setSiblingCount] = useState(1);

  const setPageSize = (size) => {
    navigate(getFilterUrl({pageSize: size}));
  }

  window.addEventListener("resize", (e) => {
    if(window.innerWidth < 400) {
      setSiblingCount(0);
    } else {
      setSiblingCount(1);
    }
  })


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!categories || (categories && categories.length === 0)) {
      fetchCategories();
    }
    fetchData();
  }, [
    category,
    error,
    order,
    page,
    price,
    query,
    pageSize,
    rating,
    categories,
    dispatch,
  ]);

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1;
    const filterCategory = filter.category || category;
    const filterQuery = filter.query || query;
    const filterRating = filter.rating || rating;
    const filterPrice = filter.price || price;
    const filterPageSize = filter.pageSize || pageSize;
    const sortOrder = filter.order || order;
    //TODO: 
    return `/search?category=${filterCategory}&query=${filterQuery}&price=${filterPrice}&rating=${filterRating}&order=${sortOrder}&page=${filterPage}&pageSize=${filterPageSize}`;
  };

  const onPageChange = (page) => {
    navigate(getFilterUrl({ page: page }));
  };
  return (
    <div>
      <Helmet>
        <title>Productos</title>
      </Helmet>
      <Row className="screenRow">
        {isMobile ? (
          <Dropdown className="filterDrop mb-2">
            <Dropdown.Toggle
              id="dropdown-basic"
              className={isMobile ? "d-md-none " : "d-none"}
            >
              <FaFilter></FaFilter>Filtrar
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2 dropdown-menu-left">
              <DropdownItem>
                <h5 className="m-0"> Filtrar por:</h5>
              </DropdownItem>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleCatMenu}>
                <h2 className="dropdown dropdown-toggle mb-2"> Categorias</h2>
                {toggleCatFilter && (
                  <>
                    <Dropdown.Item>
                      <Link
                        className={"all" === category ? "text-bold" : ""}
                        to={getFilterUrl({ category: "all" })}
                      >
                        {category === "all" && <TiTick></TiTick>}
                        Todas las Categorias
                      </Link>
                    </Dropdown.Item>
                    {categories.map((c) => (
                      <Dropdown.Item>
                        <Link
                          className={
                            c.name === category
                              ? "text-bold dropdown-item"
                              : "dropdown-item"
                          }
                          to={getFilterUrl({ category: c._id })}
                          key={c._id}
                        >
                          {category === c._id && <TiTick></TiTick>}
                          {c.name}
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </>
                )}
                <Dropdown.Divider />
              </Dropdown.Item>
              <Dropdown.Item onClick={handlePriceMenu}>
                <h2 className="dropdown dropdown-toggle "> Precios</h2>
                {togglePriceFilter && (
                  <>
                    <Dropdown.Item>
                      <Link
                        className={
                          "all" === price
                            ? "font-weight-bold dropdown-item"
                            : "dropdown-item"
                        }
                        to={getFilterUrl({ price: "all" })}
                      >
                        {price === "all" && <TiTick></TiTick>}Todos los precios
                      </Link>
                    </Dropdown.Item>
                    {PRICES.map((p) => (
                      <Dropdown.Item>
                        <Link
                          to={getFilterUrl({ price: p.value })}
                          className={
                            p.value === price
                              ? "font-weight-bold dropdown-item"
                              : "dropdown-item"
                          }
                          key={p.value}
                        >
                          {p.value === price && <TiTick></TiTick>}
                          {p.name}
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </>
                )}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Col md={2} className={isMobile ? "d-none" : "searchCol"}>
            <div className="search-screen">
              <ul>
                <li>
                  <h4>Productos</h4>
                </li>
                <li>
                  <span>{"Resultados:" + " " + countProducts}</span>
                </li>
              </ul>
              <ul>
                <li>
                  <h5 className="">Categorias</h5>
                </li>
                <li className="">
                  <Link
                    className={"all" === category ? "text-bold" : ""}
                    to={getFilterUrl({ category: "all" })}
                  >
                    Todas las Categorias
                  </Link>
                </li>

                {categories.map((c) => (
                  <li className="" key={c._id}>
                    <Link
                      className={c.name === category ? "text-bold" : ""}
                      to={getFilterUrl({ category: c._id })}
                    >
                      {c.name === category && <TiTick></TiTick>}
                      {c.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="search-screen">
              <ul>
                <li>
                  <h5 className="">Precios</h5>
                </li>

                <li>
                  <Link
                    className={"all" === price ? "text-bold" : ""}
                    to={getFilterUrl({ price: "all" })}
                  >
                    Todos los precios
                  </Link>
                </li>
                {PRICES.map((p) => (
                  <li className="" key={p.value}>
                    <Link
                      to={getFilterUrl({ price: p.value })}
                      className={p.value === price ? "text-bold" : ""}
                    >
                      {p.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        )}
        <Col md={10}>
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Row className="justify-content-between mb-3">
                <Col md={6}>
                  <div className="searchDiv mb-2 ">
                    {/* TODO: use this tag for each item and add remove function */}
                    {countProducts === 0
                      ? "No se encontraron productos"
                      : countProducts + " Productos encontrados"}
                    {query !== "all" ||
                    category !== "all" ||
                    price !== "all" ? (
                      <div>
                        {query !== "all" ? (
                          <span className="tag label label-info">
                            {query !== "all" && "Busqueda : " + query}
                            <Button
                              variant="light"
                              onClick={() => {
                                navigate(getFilterUrl({ query: "all" }));
                              }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </Button>
                          </span>
                        ) : null}
                        {category !== "all" ? (
                          <span className="tag label label-info">
                            {category !== "all" &&
                              "Categoria : " + categoriesMap[category]}
                            <Button
                              variant="light"
                              onClick={() => {
                                navigate(getFilterUrl({ category: "all" }));
                              }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </Button>
                          </span>
                        ) : null}
                        {price !== "all" ? (
                          <span className="tag label label-info">
                            {price !== "all" && "Precio : " + price}
                            <Button
                              variant="light"
                              onClick={() => {
                                navigate(getFilterUrl({ price: "all" }));
                              }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </Button>
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col className="text-end search-select">
                  Ordenar por{" "}
                  <select
                    value={order}
                    onChange={(e) => {
                      navigate(getFilterUrl({ order: e.target.value }));
                    }}
                  >
                    <option value="newest">Nuevos ingresos</option>
                    <option value="lowest">Precio: Mas bajo a Mas Alto</option>
                    <option value="highest">Precio: Mas Alto a Mas Bajo</option>
                  </select>
                </Col>
              </Row>
              {products.length === 0 && (
                <MessageBox>No encontramos su producto</MessageBox>
              )}

              <Row>
                {products.map((product) =>
                  product.active ? (
                    <Col
                      lg={3}
                      md={4}
                      sm = {6}
                      className="mb-3"
                      key={product._id}
                    >
                      <Product product={product}></Product>
                    </Col>
                  ) : null
                )}
              </Row>
                  <div className="admin-pagination-container">

                    <Pagination
                      siblingCount={siblingCount}
                      className="pagination-bar"
                      totalCount={countProducts}
                      onPageChange={onPageChange}
                      currentPage={parseInt(page)}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                    />
                  </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
