import axios from '../restClient';

const BASE_URI = '/orders';
class OrderService {
	async get(id) {
		const res = await axios.get(`${BASE_URI}/${id}`);
		return res;
	}
	async list(searchParams, nonPaginated = false) {
		const searchParamsKeys = searchParams.keys();
		const query = {};
		for (const key of searchParamsKeys) {
			query[key] = searchParams.get(key);
		}
		if(nonPaginated) {
			query.nonPaginated = true;
		}
		const res = await axios.get(`${BASE_URI}` , {
			params: query
		});
		return res;
	}
	async create(order, usesPaymentGateway = false) {
		const res = await axios.post(BASE_URI, order, {
			params: {
				usesPaymentGateway
			},
		});
		return res;
	}
	async listOwn() {
		const res = await axios.get(`${BASE_URI}/mine`);
		return res;
	}
	//TODO:  revisar estos metodos y sustituirlos a lo mejor por un patch o algo asi
	async setAsPaid(id) {
		const res = await axios.put(`${BASE_URI}/${id}/pay`);
		return res;
	}
	async setAsDelivered(id) {
		const res = await axios.put(`${BASE_URI}/${id}/deliver`);
		return res;
	}
	async setMercadoPagoStatusAsPaid(id) {
		const res = await axios.put(`${BASE_URI}/${id}/payMp`);
		return res;
	}
	async setMercadoPagoStatusAsPending(id) {
		const res = await axios.put(`${BASE_URI}/pendingMp/${id}`);
		return res;
	}
	async cancel(id) {
		const res = await axios.put(`${BASE_URI}/${id}/cancel`);
		return res;
	}
	async cancelMany(orders) {
		const res = await axios.put(`${BASE_URI}/cancel/`, {
			orders,
		});
		return res;
	}
	async getExcelSheet(selectedFields, dateFrom, dateTo,  fileName) {
		const res = await axios.post(`${BASE_URI}/exportExcel`, {
			selectedFields,
			fileName,
			dateFrom,
			dateTo,
		}, {
			responseType: 'arraybuffer',
		});
		return res;
	}
	async uploadCOmprobante(formData){
		try {
			const response = await axios.post(`${BASE_URI}/uploadComprobante`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	
			return response;
		} catch (error) {
			console.error('Error in uploadCOmprobante service:', error);
			throw error;
		}
	};

	async cancelPayment(id) {
		const res = await axios.put(`${BASE_URI}/cancelPayment/${id}`);
		return res
		;
	}

	async getUnavailableToAutoconfirm(items) {
		const res = await axios.post(`${BASE_URI}/get-unavailable-items`, {
			items,
		});
		return res.data;
	}
}
export default new OrderService();
