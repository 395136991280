import React from "react";
import "../Screens.css";

function AdminScreen2() {
  return (
    <div>
      <div className="container admin-con container-admin">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row col-admin ">
              <div className="col">
                <h5>Visitantes</h5>
                <p>Visitas del ultimo mes</p>
              </div>
              <div className="col">
                <span>34445</span>
              </div>
            </div>
            <div className="row col-admin">
              <div className="col">
                <h5>Tiempo de carga</h5>
                <p>Promedio tiempo de carga de la pagina</p>
              </div>
              <div className="col">
                <span>34445</span>
              </div>
            </div>
            <div className="row col-admin">
              <div className="col">
                <h5>Incidentes</h5>
                <p>Problemas de sistema y alertas</p>
              </div>
              <div className="col">
                <span>34445</span>
              </div>
            </div>{" "}
          </div>
          <div className="col-12 col-lg-4 col-admin">
            <div className="row">
              <img src="/images/chart.webp" alt="chart1"></img>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <h3>Evolucion de Ventas</h3>
          </div>
          <div className="row">
            <img src="/images/chart2.png" alt="chart2"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5 col-admin">
            <div className="row">
              <h3>Registro del sistema</h3>
            </div>
            <div className="comments-admin">
              <p>Registro 1 </p>
              <p>Registro 2 </p>
              <p>Registro 3 </p>
              <p>Registro 4 </p>
            </div>
          </div>
          <div className="col-12 col-lg-5 col-admin">
            <div className="row">
              <h3>Nuevos Registros</h3>
            </div>
            <div className="comments">
              <p>Registro 1 </p>
              <p>Registro 2 </p>
              <p>Registro 3 </p>
              <p>Registro 4 </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footerr></Footerr> */}
    </div>
  );
}

export default AdminScreen2;
