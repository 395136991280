import React, { useEffect, useRef, useState } from 'react';
import "./newProductVariants.css";
import ButtonSecondaryTranslucidHover from '../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import classnames from 'classnames';
//#region UI COMPONENTS
import LoadingBox from '../../LoadingBox/LoadingBox';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { FaPlusCircle } from 'react-icons/fa';
import PillInput from '../../PillInput/PillInput';
import { toast } from 'react-toastify';
import _productVariants from "../../../services/productVariant.service";
//#endregion


export default function NewProductVariantOptions({ product , options, setOptions, optionValues, setOptionValues, 
        token, disabled, onComplete}) {
    const [newOptionName, setNewOptionName] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    //#region UI HANDLERS

    //#endregion
    //#region SUBMIT HANDLERS
    const addOption = async () => {
        const existingOption = options.find(opt => opt.name === newOptionName);
        if (newOptionName) {
            if (!existingOption) {
                try {
                    const optionData = {
                        name: newOptionName,
                        productId: product._id
                    };
                    const { data: option } = await _productVariants.createOption(optionData);
                    setOptions([...options, option]);
                    setNewOptionName("");
                    toast.success("Opción agregada.")
                } catch (ex) {
                    toast.error("Error. No se pudo guardar la nueva opcion");
                }
            } else {
                toast.error("Ya existe esta opción");
            }
        } else {
            toast.error("Debes ingresar un nombre para la nueva opción");
        }
    }
    const deleteOption = async () => {
        const optionIndex = options.findIndex(opt => opt._id === selectedOption._id);
        if (optionIndex >= 0) {
            try {
                await _productVariants.removeOption(selectedOption._id);
                const optionsCopy = [...options];
                optionsCopy.splice(optionIndex, 1);
                setSelectedOption(null);
                setOptions(optionsCopy);
                toast.success("Opción eliminada");
            } catch (ex) {
                    toast.error("Error. No se pudo borrar la opción");
                    console.error(ex);
            }

        } else {
            toast.error("Error. Intentelo de nuevo.");
        }
    }

    const addOptionValue = async (newOptionValue) => {
        if (newOptionValue) {
            const existingValue = optionValues
                .find(({ value, variantOption }) => value === newOptionValue && variantOption === selectedOption._id);
            if (!existingValue) {
                try {
                    const { data: insertedValues } = await _productVariants.createOptionValues({ values: [newOptionValue], variantOption: selectedOption._id });
                    setOptionValues([...optionValues, ...insertedValues])
                } catch (ex) {
                    toast.error("Error. No se pudo guardar el nuevo valor");
                    console.error(ex);
                }
            } else {
                toast.error("Este valor ya existe.")
            }
        } else {
            toast.error("Debes ingresar un valor.");
        }
    }

    
    const deleteOptionValue = async (id) => {
        try {
            await _productVariants.removeOptionValue(id);
            const optionValuesCopy = [...optionValues];
            const valueIndex = optionValues
                .findIndex(v => v._id === id);
            optionValuesCopy.splice(valueIndex, 1);
            setOptionValues(optionValuesCopy);
            toast.success("Valor eliminado");
        } catch (ex) {
                toast.error("Error. No se pudo borrar el valor");
                console.error(ex);
        }
    }
    async function handleGenerateVariants() {
        const  optionsWithValues = options.map((option) => {
            const values = optionValues.filter(value => value.variantOption === option._id);
            return values.map(v => { return { option: option, value: v } })
        }).filter(arr => arr.length);
        if(optionsWithValues.length) {
        try {
            const {data} = await _productVariants.generateVariants( optionsWithValues,product._id);
            onComplete(data, options,optionValues);
            toast.success("Variantes generadas.");
        } catch(ex) {
            console.error(ex);
        }
    } else {
        toast.error("Debes definir al menos una opcion y un valor");
    }
    }
    //#endregion
    return (
        <Card id="new-product-variant-options" className={classnames('col-xl-7 col-lg-8 col-md-9 col-10', { disabled })}>
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <Col xs={10}>
                    <h5 className='m-0'>
                        Opciones de variantes
                    </h5>
                </Col>
            </Card.Header>

                    <Card.Body>
                        <Tab.Container id="left-tabs-example" >
                            <div className='d-flex'>
                                <Col sm={3}>
                                    <Nav className="d-flex flex-wrap w-100" variant="pills">
                                        <Nav.Item className={classnames("col-12 pill-input-bordered")}>
                                            <div className="new-option-container" >
                                            <input type="text" value={newOptionName}
                                                className={classnames('variant-option-tab',
                                                    {
                                                        "empty": !newOptionName
                                                    })}
                                                placeholder='Nueva opción'
                                                onChange={(e) => setNewOptionName(e.target.value)} />
                                                <button
                                                    className='pill-button text-center'
                                                    disabled={!newOptionName}
                                                >
                                                    <FaPlusCircle onClick={addOption} />
                                                </button>
                                            </div>
                                        </Nav.Item>
                                        {
                                            options.map((option) =>
                                                <Nav.Item className='col-12 d-flex align-items-center' key={`${option.name}tab`}>
                                                    <Nav.Link href='' onClick={() => setSelectedOption(option)}
                                                        className='variant-option-tab' eventKey={option.name}>
                                                        <div className='w-100 d-flex align-items-center justify-content-center'>
                                                            <div className='w-auto'>
                                                                {option.name}
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={7}>
                                    <Tab.Content>
                                    {options.map(option =>
                                        <Tab.Pane eventKey={option.name} key={option.name + "tabpane"}
                                            onClick={() => setSelectedOption(option)}>
                                            <div className='row mx-2'>
                                                {
                                                    optionValues.filter(({ variantOption }) => variantOption === option._id)
                                                        .map((value, index) =>
                                                            <PillInput value={value.value} id={value._id}
                                                                removeHandler={deleteOptionValue}
                                                                key={value + "optionvalue" + index} />
                                                        )
                                                }
                                                {selectedOption &&
                                                    <PillInput addHandler={addOptionValue} placeholder={"Nuevo valor"} />
                                                }
                                            </div>
                                        </Tab.Pane>
                                    )}
                                    </Tab.Content>
                                </Col>
                            <Col sm={2} className='actions-container'>
                                <Button
                                    disabled={!selectedOption}
                                    className="mt-1"
                                    variant="outline-danger"
                                    onClick={deleteOption}
                                >
                                    Borrar opción
                                </Button>
                            </Col>
                            </div>
                        </Tab.Container>
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-center'>
                        <Button onClick={handleGenerateVariants}>
                            Generar variantes
                        </Button>
                    </Card.Footer>
        </Card>
    )
}
