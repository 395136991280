import React, { useContext } from 'react'
import LoginPrompt from '../LoginPrompt/LoginPrompt'
import _settings from '../../services/settings.service';
import _user from '../../services/user.service';
import _auth from '../../services/auth.service';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getError } from '../../utils';
import "./CheckoutLoginPrompt.css";

export default function CheckoutLoginPrompt() {
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const navigate = useNavigate();

    const checkoutHandler = () => {
        const redirect = state.usesShipping ? "shipping" : "payment";
        navigate(`/checkout/${redirect}`);
    };

    const handleGuestCheckout = async (email) => {
        try {
            const { data } = await _user.createGuest(email);
            submitHandler(data.email, data.password);
        } catch (ex) {
            console.error(ex);
            toast.error(getError(ex));
        }
    }
    const submitHandler = async (email, password) => {
        try {
            const { data } = await _auth.login(email, password);
            ctxDispatch({ type: "USER_SIGNIN", payload: data });
            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('refreshToken', data.refreshToken);
            checkoutHandler();
        } catch (err) {
            toast.error(getError(err));
        }
    };
    return <div className="login-container bg-white rounded">
        <LoginPrompt className="bg-transparent w-100"
            showPassword submitHandler={submitHandler}
            buttonCaption="Ingresar"
            caption="Inicia sesión" />
        <div className="separator">O</div>
        <LoginPrompt className="bg-transparent w-100"
            submitHandler={handleGuestCheckout}
            buttonCaption="Continuar"
            caption="Continuar como invitado" />
    </div>
}
