import LoadingBox from "../../components/LoadingBox/LoadingBox.jsx";
import React, { useContext, useReducer, useState, useEffect, useLayoutEffect } from "react";
import { Store } from "../../Store";
import { Helmet } from "react-helmet-async";
import { Button, Modal, Row, Col, Form, Accordion, Tab, Tabs, Nav } from "react-bootstrap";
import { MdClose, MdOutlineEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { getError, getStatus, hasPermission } from "../../utils";
import AvatarImageCropper from "react-avatar-image-cropper";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditProductVariantOptions from "./EditProductVariantOption/EditProductVariantOptions.jsx";
import TagsInput from "react-tagsinput";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from "draft-js-import-html";
import PermissionWrapper from "../../permission-utils/PermissionWrapper.jsx";
import { SCOPES } from "../../permission-utils/scopes.js";
import "./EditProduct.css";
import classnames from 'classnames';

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_CATEGORIES_REQUEST":
      return { ...state, loadingCategories: true };
    case "FETCH_CATEGORIES_SUCCESS":
      return { ...state, categories: action.payload.categories, loadingCategories: false };
    case "FETCH_CATEGORIES_FAIL":
      return { ...state, loadingCategories: false, error: action.payload };
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "EMPTY_PRODUCT":
      return { ...state, product: null };
    default:
      return state;
  }
};

function EditProduct({ slug, show, closeHandler, edit, onSuccessSave }) {
  const navigate = useNavigate();

  const [{ loading, error, product, loadingCategories, categories }, dispatch] =
    useReducer(reducer, {
      categories: [],
      loadingCategories: true,
      product: null,
      loading: true,
      error: "",
    });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [minStockToConfirm, setMinStockToConfirm] = useState("");
  const [active, setActive] = useState(false);
  const [image, setImage] = useState("");
  const [hasVariants, setHasVariants] = useState(false);
  const [description, setDescription] = useState("");
  const [htmlDescription, setHtmlDescription] = useState("");
  const [highlightedProduct, sethighlightedProduct] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState(true);
  const [productOptions, setProductOptions] = useState([]);
  const [minStock, setMinStock] = useState(0);
  const [editorState, setEditorState] = useState(null);
  const [convertedContent, setConvertedContent] = useState(null);
  const [permission] = useState(hasPermission(userInfo.role, "products", [SCOPES.canWrite], "stock"));

  const redirect = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    navigate("/signin");
    toast.error("Sesion expirada. Vuelve a ingresar.");
  };
  useEffect(() => {
    if (product) {
      const priceInput = document.querySelector("#product-input-price");
      const fileInput = document.querySelector(".edit-product-container input[type=file]");
      if (fileInput) {
        fileInput.addEventListener("input", () => setActiveButton(false));
      }
      if (priceInput) {
        priceInput.addEventListener("change", (e) => {
          e.currentTarget.value = parseFloat(e.currentTarget.value).toFixed(2);
        });
      }
    }
  }, [product]);

  useEffect(() => {
    if (show) {
      if (slug) {
        setIsEditable(edit);
        dispatch({ type: "FETCH_REQUEST" });
        fetchData();
      } else {
        setIsEditable(true);
        emptyStates();
        dispatch({ type: "FETCH_SUCCESS", payload: {} });
      }
    }
    fetchCategories();
  }, [slug, show]);

  //#region DATA REQUESTS
  const loadData = (product) => {
    const { name, brand, category, price, countInStock,
      minStockToConfirm, active, image, description,
      htmlDescription, options, highlightedProduct,
      hasVariants: productHasVariants } = product;
    setName(name);
    setBrand(brand);
    setCategory(category ? category._id : "");
    setPrice(parseFloat(price).toFixed(2));
    setCountInStock(countInStock);
    setMinStock(permission ? 0 : countInStock);
    setMinStockToConfirm(minStockToConfirm || "");
    setActive(active);
    setImage(image);
    setDescription(description);
    setHtmlDescription(htmlDescription || "<p><br></p>");
    setProductOptions(options)
    sethighlightedProduct(highlightedProduct);
    setHasVariants(!!productHasVariants);
    const content = stateFromHTML(htmlDescription || "<p><br></p>");
    setEditorState(() => EditorState.createWithContent(content));

  }

  const emptyStates = () => {
    setName("");
    setBrand("");
    setCategory("");
    setPrice("");
    setCountInStock("");
    setMinStock(0);
    setMinStockToConfirm(0);
    setActive(false);
    setImage("");
    setDescription("");
    setHtmlDescription("<p><br></p>");
    setProductOptions([])
    sethighlightedProduct(false);
    const content = stateFromHTML("<p><br></p>");
    setEditorState(() => EditorState.createWithContent(content));
  }
  const fetchData = async () => {
    try {
      const result = await axios.get(`/api/products/slug/${slug}`);
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      loadData(result.data);
    } catch (error) {
      console.error(error);
      dispatch({ type: "FETCH_FAIL", payload: getError(error) });
    }
  };
  const fetchCategories = async () => {
    dispatch({ type: "FETCH_CATEGORIES_REQUEST" });
    try {
      const result = await axios.get("/api/categories", {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "FETCH_CATEGORIES_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: getError(error) });
    }
  };

  //#endregion 
  const imgErrorHandler = (err) => {
    if (err === "not_image") {
      toast.error("Formato no válido. Adjunte imagen jpeg/png/jpg.");
    } else {
      toast.error("Tamaño máximo de imagen excedido.");
    }
    setActiveButton(true);
  };
  const validateForm = () => {
    return (
      name &&
      brand &&
      category &&
      parseFloat(price) > 0 &&
      image &&
      Number.isInteger(Number(countInStock)) &&
      countInStock >= minStock &&
      Number(countInStock) >= 0 &&
      description
    );
  };
  const editProductHandler = async () => {
      if (validateForm()) {
        try {
          dispatch({ type: "CREATE_REQUEST" });
          const { data } = await axios.put(
            `/api/products/${product._id}`,
            {
              name,
              brand,
              category,
              price,
              countInStock,
              minStockToConfirm: minStockToConfirm || null,
              active,
              options: productOptions,
              image,
              description,
              htmlDescription: htmlDescription === "<p><br></p>" ? "" : htmlDescription,
              highlightedProduct,
              hasVariants
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          );
          dispatch({
            type: "UPDATE_SUCCESS",
          });
          ctxDispatch({ type: "PRODUCT_UPDATE_SUCCESS", payload: data });
          localStorage.setItem("product", JSON.stringify(data));
          toast.success("Producto actualizado Correctamente");
          onSuccessSave();
          setIsEditable(false)
        } catch (err) {
          dispatch({ type: "FETCH_FAIL" });
          toast.error(getError(err));
        }
      } else {
        toast.error("Error. Revise los datos.");
      }
  };
  const addProductHandler = async () => {
      if (validateForm()) {
        try {
          dispatch({ type: "CREATE_REQUEST" });
          await axios.post(
            "/api/products",
            {
              name,
              brand,
              category,
              price,
              image,
              countInStock,
              active,
              minStockToConfirm: minStockToConfirm || null,
              options: productOptions,
              description,
              htmlDescription: htmlDescription === "<p><br></p>" ? "" : htmlDescription,
              highlightedProduct,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          );
          toast.success("Producto creado");
          onSuccessSave();
          closeModal();
        } catch (ex) {
            closeModal();
            console.error(ex);
            toast.error(getError(ex));
        }
      } else {
        closeModal();
        toast.error("Error. Revise los datos.");
      }
  };

  const apply = async (file) => {
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (image) {
      formData.append("documentId", image._id);
    }
    const multiPart = { "content-type": "multipart/form-data" };
    try {
      const response = await axios.post(
        "/api/products/uploadProductImg",
        formData,
        { ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
      );
      setImage(response.data);
      setActiveButton(true);
    } catch (err) {
      console.error(getError(err));
      toast.error("Error cargando imagen.");
    }
  };

  useLayoutEffect(() => {
    if (htmlDescription && !convertedContent) {
      const content = stateFromHTML(htmlDescription);
      setEditorState(() => EditorState.createWithContent(content));
    }
  }, [htmlDescription]);

  const convertToHtml = () => {
    const convertedContentOne = stateToHTML(
      editorState.getCurrentContent(),
      productOptions
    );
    setConvertedContent(convertedContentOne);
    setHtmlDescription(convertedContentOne);
  };
  useEffect(() => {
    if (editorState) {
      convertToHtml();
    }
  }, [editorState]);
  const cancelHandler = () => {
    setIsEditable(false);
    if (slug) {
      loadData(product);
    } else {
      closeModal();
    }
  }
  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  const closeModal = () => {
    dispatch({ type: "EMPTY_PRODUCT" });
    closeHandler();
  }
  return (
    <Modal
      show={show}
      dialogClassName="edit-product-container"
      size="lg"
      animation
      onHide={closeModal}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      {/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
      <div className="container align-items-center edit-product-modal">
        <Helmet>
          <title>
            {slug ? (isEditable ? "Editar Producto" : "Detalles del producto") : "Nuevo producto"}
          </title>
        </Helmet>
        {!product ?
          <div className="loading-box-modal">
            <LoadingBox size="lg" />
          </div>
          :
          <>
            <Tabs
              defaultActiveKey="details"
              id="product-modal-tabs"
              className="mb-2 w-100 product-modal-tabs"
              variant="underline"
              fill
            >
              <Tab eventKey="details" title="Detalles" className="text-align-center">
              <div className="row justify-content-around">
                        <div className="col-lg-11 col-10">
                          {/* <h1 className="my-3">
                      <MdOutlineEdit></MdOutlineEdit>
                      {slug ? (isEditable ? "Editar Producto" : "Detalles del producto") : "Nuevo producto"}
                    </h1> */}
                        </div>
                      </div>
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <fieldset disabled={!isEditable}>
                          <div className="row align-items-center">

                            <Form.Group className="mb-3 col-lg-4" controlId="name">
                              <Form.Label>Nombre</Form.Label>
                              <Form.Control

                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-4" controlId="brand">
                              <Form.Label>Marca</Form.Label>
                              <Form.Control
                                type="text"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-4">
                              <Form.Label>Categoria</Form.Label>
                              <Form.Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                {categories.map((cat) => {
                                  return (
                                    <option key={cat._id} value={cat._id}>
                                      {cat.name}
                                    </option>
                                  );
                                })}
                                <option key="blankChoice" hidden value>
                                  {" "}
                                  Elige la categoria{" "}
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div className="row align-items-center">
                            <PermissionWrapper fn="products" field="price" scopes={[SCOPES.canEdit]}>
                              <Form.Group className="mb-3 col-lg-3" >
                                <Form.Label>Precio</Form.Label>
                                <Form.Control
                                  id="product-input-price"
                                  type="number"
                                  value={price}
                                  onChange={(e) =>
                                    setPrice(parseFloat(e.target.value).toFixed(2))
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </PermissionWrapper>
                            <Form.Group className="mb-3 col-lg-3" controlId="countInStock">
                              <Form.Label>Stock</Form.Label>
                              <Form.Control
                                type="number"
                                min={minStock}
                                value={countInStock}
                                onChange={(e) => setCountInStock(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-2" controlId="">
                              <Form.Label>Disponible</Form.Label>
                              <Form.Check
                                checked={active}
                                name="activeId"
                                onChange={(e) => setActive(e.target.checked)}
                              ></Form.Check>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-2" controlId="hpId">
                              <Form.Label>Destacado</Form.Label>
                              <Form.Check
                                checked={highlightedProduct}
                                name="hpId"
                                onChange={(e) => sethighlightedProduct(e.target.checked)}
                              ></Form.Check>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-2" controlId="hasVariants">
                              <Form.Label>¿Tiene variantes?</Form.Label>
                              <Form.Check
                                checked={hasVariants}
                                name="hasVariants"
                                onChange={(e) => setHasVariants(e.target.checked)}
                              ></Form.Check>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-12" controlId="description">
                              <Form.Label>Subtítulo</Form.Label>
                              <Form.Control
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6" controlId="">
                              <Form.Label>Opciones</Form.Label>
                              <TagsInput value={productOptions} disabled={!isEditable} onChange={(tags) => setProductOptions(tags)}
                                inputProps={{ placeholder: isEditable ? "Agregar" : "" }} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6" controlId="description">
                              <Form.Label>Stock minínmo</Form.Label>
                              <Form.Control
                                type="number"
                                value={minStockToConfirm}
                                onChange={(e) => setMinStockToConfirm(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-12">
                              <Form.Label>Descripcion</Form.Label>
                              <div className="richText">
                                <div className="container  ps-0 mt-2">
                                  {editorState && (
                                    <Editor
                                      readOnly={!isEditable}
                                      editorState={editorState}
                                      onEditorStateChange={handleEditorChange}
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                    />
                                  )}
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="row align-items-centers">
                            <div
                              className="product-image-preview"
                              style={{
                                backgroundImage:
                                  image && image.fileLink
                                    ? "url(" + image.fileLink + ")"
                                    : "url(/images/emptyPhoto.png)",
                              }}
                            >
                              <AvatarImageCropper
                                cancel={() => setActiveButton(true)}
                                className="product-image-cropper"
                                apply={apply}
                                text="Adjuntar la imagen"
                                errorHandler={(err) => imgErrorHandler(err)}
                              />
                            </div>
                          </div>
                        </fieldset>
                        <Row className={classnames("modal-btns", { "justify-content-end": !isEditable })}>
                          {isEditable ?
                            <>
                              <Col>
                                <Button className="cancelar" onClick={cancelHandler}>
                                  Cancelar
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  className="guardar"
                                  disabled={!activeButton}
                                  onClick={slug ? editProductHandler : addProductHandler}
                                >
                                  Confirmar
                                </Button>
                              </Col>
                            </>
                            :
                            <PermissionWrapper fn={"products"} scopes={[SCOPES.canCreate]}>
                              <Col sm={2}>
                                <Button className="w-100" onClick={() => setIsEditable(true)}>
                                  Editar
                                </Button>
                              </Col>
                            </PermissionWrapper>
                          }
                        </Row>

                      </Form>
              </Tab>
              {slug  && hasVariants &&
                <Tab eventKey="variants" disabled = {isEditable} title="Variantes" className="text-align-center">
                  <EditProductVariantOptions product={product} token={userInfo.token} redirect={redirect} />
                </Tab>
              }
            </Tabs>
          </>
        }
      </div>
    </Modal>
  );
}
export default EditProduct;
