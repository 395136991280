import React from "react";
import  "./AdminFooter.css";

function AdminFooter({companyName}) {
  return (
    <footer id="admin-footer" className="d-flex flex-wrap flex-column justify-content-end align-items-center">
        <p className="copyrightFooter m-0">©Copyright {new Date().getFullYear()} {companyName} <br />  E-commerce</p>
      <div className="logo-container">
        Powered by
        <a
          href="https://gotasoft.io/"
          target="_blank"
          title="Powered by Gotasoft"
        >
          <img src="/logoFooter.svg" className="img-fluid  m-0 h-75" alt="Gotasoft logo" />
        </a>
      </div>
    </footer>
  );
}
export default AdminFooter;
