import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import _auth from "../../services/auth.service";
import LoginPrompt from "../../components/LoginPrompt/LoginPrompt";
import "./SignInScreen.css";

export default function SignInScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectInUrl = searchParams.get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  
  const submitHandler = async (email, password) => {
    try {
      const {data} = await _auth.login(email,password);
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
			localStorage.setItem('refreshToken', data.refreshToken);
      navigate(redirect || "/");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
    if(!userInfo) {
      ctxDispatch({ type: "USER_SIGNOUT" });
    }
  }, [navigate, redirect, userInfo]);

  return (
    <Container id="login-screen" >
      <Helmet>
        <title>Iniciar sesión</title>
      </Helmet>
      <div className="d-flex justify-content-center w-100">
        <div className="login-container bg-white rounded w-70">
          <LoginPrompt className="bg-transparent w-100"
            showPassword submitHandler={submitHandler}
            showForgotPassword
            showSignUp
            buttonCaption="Ingresar"
            caption="Inicia sesión" />
        </div>
      </div>
    </Container>
  );
}
