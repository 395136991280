import axios from '../restClient';
import { getParamsObj } from '../utils';

const BASE_URI = '/categories';
class CategoriesService {
	async get(id) {
		const res = await axios.get(`${BASE_URI}/${id}`);
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async edit(id, name) {
		const res = await axios.put(`${BASE_URI}/${id}`, { name });
		return res;
	}
	async list() {
		const res = await axios.get(BASE_URI);
		return res;
	}
		/**
	 * 
	 * @param {URLSearchParams} searchParams 
	 * @returns AxiosResponse
	 */
	async adminList(searchParams, nonPaginated = false) {
		const searchParamsKeys = searchParams.keys();
		const query = {};
		if(nonPaginated) {
			query.nonPaginated = true;
		}
		for (const key of searchParamsKeys) {
			query[key] = searchParams.get(key);
		}
		const res = await axios.get(`${BASE_URI}/admin/search`, {
			params: query,
		});
		return res;
	}
	async create(name) {
		const res = await axios.post(BASE_URI, { name });
		return res;
	}
}
export default new CategoriesService();
