import React, {
  useContext,
  useReducer,
  useState,
  useLayoutEffect,
  useEffect,
} from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DOMPurify from "dompurify";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError, getStatus } from "../../utils";
import { useNavigate } from "react-router-dom";
import _richText from "../../services/richText.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_TEXT_REQUEST":
      return { ...state, loading: true };
    case "FETCH_TEXT_SUCCESS":
      return { ...state, text: action.payload, loading: false };
    case "FETCH_TEXT_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const ShippingPageEditor = () => {
  const [{ loading, error, text }, dispatch] = useReducer(reducer, {
    text: {},
    loading: true,
    error: "",
  });
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [editorState, setEditorState] = useState(null);
  const [convertedContent, setConvertedContent] = useState(null);
  const [htmlText, setHtmlText] = useState();

  useLayoutEffect(() => {

    fetchData();
  }, []);
  useLayoutEffect(() => {
    if (htmlText && convertedContent == null) {
      const content = stateFromHTML(htmlText);
      setEditorState(() => EditorState.createWithContent(content));
    }
  }, [htmlText]);

  //inline styles
  let options = {
    inlineStyleFn: (styles) => {
      let key = "color-";
      let color = styles.filter((value) => value.startsWith(key)).first();

      if (color) {
        return {
          element: "span",
          style: {
            color: color.replace(key, ""),
          },
        };
      }
    },
  };

  const convertToHtml = () => {
    const convertedContentOne = stateToHTML(
      editorState.getCurrentContent(),
      options
    );
    setConvertedContent(convertedContentOne);
    setHtmlText(convertedContent);
  };
  useEffect(() => {
    if (editorState) {
      convertToHtml();
    }
  }, [editorState]);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

 async function fetchData() {
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const { data } = await _richText.getShippingPage();
      if (!data.htmlText) {
        setHtmlText("<div></div>");
      } else {
        setHtmlText(data.htmlText);
      }
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: error.message });
    }
  };
  async function saveHtml() {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await _richText.editShippingPage(htmlText);
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      ctxDispatch({ type: "UPDATE_SUCCESS", payload: data });
      toast.success("html actualizado Correctamente");
    } catch (error) {
      dispatch({ type: "FETCH_FAIL" });
      console.error(getError(error));
      toast.error("Error. No se pudo guardar los cambios");
    }
  };

  return (
    <div className="richText">
      <div className="container  ps-0 mt-2">
        {editorState && (
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        )}

        <Row>
          <Col>
            <Button md={2} onClick={saveHtml}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ShippingPageEditor;
