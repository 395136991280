import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./SearchBox.css";
import classnames from "classnames";

function SearchBox({ className, onChange, onBlur, value, icon, submitFilters, placeholder = "Buscar productos..."}) {
  const formRef = useRef(null);

  const handleSubmit = (event) => {
    if (event.key === "Enter" && document.activeElement === formRef.current.value) {
      submitFilters();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleSubmit);
    return () => {
      document.removeEventListener("keydown", handleSubmit);
    };
  }, []);




  return (
    <div className={classnames("search-box-container",{[className] : className})}>
    <InputGroup>
      <InputGroup.Text>
      {
            icon || <i className="fas fa-search"></i>
      }
      </InputGroup.Text>
      <Form.Control
        ref={formRef}
        type="text"
        value={value}
        className="search-box"
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="input-addon-lens"
        onBlur={onBlur}
        onChange={onChange}
      />
    </InputGroup>
  </div>
  );
}

export default SearchBox;
