import React, { useEffect, useState } from 'react';
import "./newProductCategories.css";
import classnames from 'classnames'
import { getError } from '../../../utils';
import LoadingBox from '../../LoadingBox/LoadingBox';
import MessageBox from '../../MessageBox/MessageBox';
//#region BS COMPONENTS
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import _categories from "../../../services/category.service";
//#endregion
export default function NewProductCategories({ product ,onChange, disabled, isStep}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  async function fetchCategories() {
    try {
      const { data } = await _categories.list();
      setCategories(data);
    } catch (ex) {
      console.error(ex);
      setError(getError(ex));
    }
  }

  useEffect(() => {
    fetchCategories();
    setLoading(false);
  }, [loading]);
  const handleChange = (e) => {
    onChange("category",e.target.value);
  }
  return (
    <Card id="new-product-categories" className='col-xl-7 col-lg-8 col-md-9 col-10'>
      <Card.Header className={classnames('',{disabled: isStep && disabled})} as="div">
        <h5>
          Categoría del producto
        </h5>
        <p className='new-product-section-caption'>
        Permite clasificar, identificar y agrupar productos similares.
        </p>
      </Card.Header>
      <Card.Body className={classnames('',{disabled: isStep && disabled})}>
        <Form >
          <fieldset disabled={disabled && isStep}>
            <Row className='justify-content-center p-3'>
              {!loading ?
                <>
                  {!error ?
                    <Col md={6} xs={10}>
                      <FloatingLabel controlId="new-product-category" label="Categoría">
                        <Form.Select aria-label="Categoría" value={product.category} onChange={handleChange}>
                          <option>-</option>
                          {categories.map((cat, i) =>
                            <option value = {cat._id} key={`${cat + i}`}>
                              {cat.name}
                            </option>
                          )}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    :
                    <MessageBox variant="danger">
                      {error}
                    </MessageBox>
                  }
                </>
                : <LoadingBox />
              }
            </Row>
          </fieldset>
        </Form >
      </Card.Body>
    </Card>
  )
}
