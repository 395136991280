import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";

import moment from "moment";
import "./previousDraftsModal.css"
import { FaRegTrashAlt } from 'react-icons/fa';
import _products from "../../services/product.service";
import { toast } from 'react-toastify';
import LoadingBox from '../LoadingBox/LoadingBox';
import classnames from 'classnames';
export default function PreviousDraftsModal({ show, drafts, hide, setDrafts, selectPreviousDraft }) {
    const [loadingDraft, setLoadingDraft] = useState(false);

    const deleteDraft = async (product) => {
        setLoadingDraft(true)
        try {
            await _products.delete(product._id);
            const draftIndex = drafts.findIndex(p => p._id === product._id);
            const draftsCopy = [...drafts];
            draftsCopy.splice(draftIndex, 1);
            setDrafts(draftsCopy);
            toast.success('Borrador eliminado')
            if (!draftsCopy.length) {
                hide();
            }
        } catch (ex) {
            console.error(ex);
        }
        setLoadingDraft(false);
    };
    const selectDraft = async (product) => {
        setLoadingDraft(true)
        await selectPreviousDraft(product);
        setLoadingDraft(false);
    };

    return (
        <Modal show={show} onHide={hide} dialogClassName='previous-drafts-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Borradores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loadingDraft ?
                    drafts.map((product, i) => {
                        return <div className={classnames('draft', { invisible: loadingDraft })}
                            key={product.name + i}>
                            <div className="draft-data" onClick={selectDraft.bind(this, product)}>
                                <h3>
                                    {product.name}
                                </h3>
                                <p>
                                    Creacion de producto comenzada el {moment(product.createdAt).format("DD/MM/YYYY")}
                                </p>
                            </div>
                            <button onClick={deleteDraft.bind(this, product)}>
                                <FaRegTrashAlt />
                            </button>
                        </div>
                    })
                    :
                    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                        <LoadingBox />
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}
