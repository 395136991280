import React, { useContext, useReducer, useState } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { MdLibraryAdd, MdClose } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import { useNavigate } from "react-router-dom";
import _deliverySchedules from "../../services/deliverySchedules.service.js";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddSchedule() {
  const { state, dispatch: ctxDispatch } = useContext(Store);

  const [{ loadingCreate }, dispatch] = useReducer(reducer, {
    loadingCreate: false,
  });
  const [label, setLabel] = useState("");
  const [hourFrom, setHourFrom] = useState('00:00');
  const [hourTo, setHourTo] = useState('00:00');
  
  const navigate = useNavigate();

  const addScheduleHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      await _deliverySchedules.create(label, hourFrom, hourTo);
      dispatch({ type: "CREATE_SUCCESS" });
      toast.success("Horario de Envio agregado");
      navigate("/AdminScreen/Config");
    } catch (ex) {
      dispatch({ type: "CREATE_FAIL" });
      console.error(ex);
      toast.error("Error. No se pudo agregar el horario de envio.");
    }
  };


  return (
    <Modal size="lg" show={true} animation={false}>
      <div className="container align-items-center">
        <Helmet>
          <title>Nuevo Horario de Envio</title>
        </Helmet>
        <div className="row justify-content-around">
          <div className="col-lg-11 col-10">
            <h1 className="my-3">
              <MdLibraryAdd></MdLibraryAdd>Agregar Horario de Envio
            </h1>
          </div>
          <div className="col-lg-1 col-2">
            <MdClose
              className="my-3"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></MdClose>
          </div>
        </div>
        <Form>
          <div className="row">
            <Form.Group className="mb-3 col-lg-6" controlId="">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control
                onChange={(e) => setLabel(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>
          <div className="row">
          <Form.Group controlId="timeInputFrom">
          <Form.Label>Hora de Inicio</Form.Label>
          <Form.Control
            type="time"
            value={hourFrom}
            onChange={(e) => setHourFrom(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="timeInputTo">
          <Form.Label>Hora de Finalización</Form.Label>
          <Form.Control
            type="time"
            value={hourTo}
            onChange={(e) => setHourTo(e.target.value)}
          />
        </Form.Group>
          </div>
    
          <Row className="modal-btns">
            <Col>
              <Button className="cancelar" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button className="guardar" onClick={addScheduleHandler}>
                Agregar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default AddSchedule;
