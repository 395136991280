import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import BtnPlus from "../../PlusButton/BtnPlus";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useEffect } from "react";
import "./FaqQuestionsConfig.css";
import TextFloatingLabel from "../../TextFloatingLabel/TextFloatingLabel.jsx";
import NewFaqQuestionModal from "../NewFaqQuestionModal/NewFaqQuestionModal.jsx";
import ModalDeleteFaqQuestion from "../DeleteFaqQuestionModal/DeleteFaqQuestionModal";
import { toast } from "react-toastify";
import _faq from "../../../services/faq.service.js"

export default function FaqQuestionsConfig() {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [loadQuestions, setLoadQuestions] = useState(true);
  const [deleteShow, setDeleteShow] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const fetchQuestions = async () => {
    if (loadQuestions) {
      try {
        const fetchedQuestions = await _faq.list();
        setQuestions(fetchedQuestions.data);
      } catch (ex) {
        console.error(ex);
        toast.error("Error. No se pudo obtener las preguntas de ayuda.");
      }
      setLoadQuestions(false);
    }
  };
  const deleteQuestion = async (id) => {
    try {
      await _faq.delete(id);
      toast.success("Pregunta eliminada");
      handleClose();
    } catch (ex) {
      toast.error("Error");
      console.error(ex);
    }
  };
  useEffect(() => {
    fetchQuestions();
  }, [loadQuestions]);


  const handleOpen = () => setShow(true);
  const handleEdit = () => {
    setEdit(true);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setDeleteShow(false);
    setEdit(false);
    setLoadQuestions(true);
    setSelectedQuestion("");
  };
  const handleSelectDeletion = (q) => {
    setSelectedQuestion(q);
    setDeleteShow(true);
  };

  return (
    <div id="faq-config">
      <ModalDeleteFaqQuestion
        deleteShow={deleteShow}
        deleteHandler={deleteQuestion}
        handleClose={handleClose}
        question={selectedQuestion}
        onHide={() => setDeleteShow(false)}
      ></ModalDeleteFaqQuestion>
      <NewFaqQuestionModal
        show={show}
        saveHandler = {_faq.create}
        editHandler = {_faq.edit}
        handleClose={handleClose}
        edit={edit}
        editQuestion={selectedQuestion}
      ></NewFaqQuestionModal>
      <Row className="align-items-center">
        <Col xs={6}>
          {/* agregar clases css */}
          <div className="d-block ms-1">
            <p id="label-boton-faq" className="d-inline-block">
              Agregar nueva pregunta.
            </p>
            <BtnPlus
              className="col-1 d-inline-block mb-1 ms-1"
              id="new-question-btn"
              onClick={handleOpen}
            ></BtnPlus>
          </div>
          <Row>
            <Col xs={10}>
              <ListGroup id="lista-preguntas">
                {questions.length > 0 &&
                  questions.map((q, index) => {
                    return (
                      <ListGroup.Item key={index} id={q._id}>
                        <Row className="justify-content-between px-2">
                          <Col lg={6}>{q.question}</Col>
                          <Col
                            sm={8}
                            lg={2}
                            className="d-flex justify-content-around p-0"
                          >
                            <button
                              onClick={() => setSelectedQuestion(q)}
                              className="action-button"
                            >
                              <FaEye></FaEye>
                            </button>
                            <button
                              className="action-button"
                              onClick={() => handleSelectDeletion(q)}
                            >
                              <FaTrash></FaTrash>
                            </button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Col>
          </Row>
        </Col>
        {selectedQuestion && (
          <Col xs={5}>
            <Row
              id="faq-data"
              className=" justify-content-start align-items-center mb-1"
            >
              <TextFloatingLabel
                className="mb-2"
                label="Pregunta"
                data={selectedQuestion && selectedQuestion.question}
                edit={selectedQuestion && handleEdit}
              ></TextFloatingLabel>
              <TextFloatingLabel
                edit={selectedQuestion && handleEdit}
                label="Respuesta"
                data={selectedQuestion && selectedQuestion.answer}
              ></TextFloatingLabel>
            </Row>
            <Row className="align-items-center">
              <span id="label-activo" className="inline-block col-1 me-1">
                Activo
              </span>
              <Form.Check
                readOnly
                className="col-1 pe-0 me-1"
                checked={selectedQuestion ? selectedQuestion.active : false}
              ></Form.Check>
              <button
                id="pen-checkbox-faq"
                className="col-1 pen-edit-method"
                onClick={handleEdit}
              >
                <FaPen></FaPen>
              </button>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
