import React from 'react';
import "./newProductVariantSwitch.css";
//#region BS COMPONENTS
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
//#endregion


export default function ProductVariantSwitch({ product, onChange }) {

    return (
        <Card id="new-product-variant-switch" className='col-xl-7 col-lg-8 col-md-9 col-10'>
            <Card.Header as={'div'}>
                <h5>
                    Variantes
                </h5>
                <p className='new-product-section-caption'>
                Permiten configurar versiones específicas de un producto con atributos diferentes, como color, tamaño o estilo, ofreciendo opciones personalizadas. 
                </p>
            </Card.Header>
            <Card.Body className='justify-content-center d-flex'>
                <Form.Group >
                    <Form.Switch
                        checked={product.hasVariants}
                        onChange={(e) => onChange(e.target.checked)}
                    ></Form.Switch>
                </Form.Group>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-end'>
            </Card.Footer>
        </Card>
    )
}
