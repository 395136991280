import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import classnames from "classnames";
import { addAlphaToHex } from '../../utils';

export default function ButtonTranslucidHover({ children, color = "", onClick, className, disabled }) {
    const dateId = 'a' + Date.now().toString();
    return (
        <>
            <Button onClick={onClick} disabled={disabled} id={dateId}
                className={classnames('btn-translucid', { [className]: className })}>
                {children}
            </Button>
            <style>
                {`
                        ${"#" + dateId} {
                            background-color: transparent !important;
                            color: ${color} !important;
                          }
                          ${"#" + dateId}:hover {
                            color: white !important;
                            background-color: ${addAlphaToHex(color.replace("#", ""), 0.7)} !important;
                          }
                        `
                }
            </style>
        </>
    )
}
