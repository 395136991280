import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './StatusModal.css';
import orderService from '../../services/order.service';

function StatusModal({ show, handleClose, status, options, order }) {
	const [unavailableItems, setUnavailableItems] = useState([]);

	useEffect(() => {
    const fetchModalMessage = async () => {
        if (status === 'Awaiting Confirmation') {
            try {
                const data = await orderService.getUnavailableToAutoconfirm(order.orderItems);
                setUnavailableItems(data);
            } catch (error) {
                console.error('Error fetching unavailable items:', error);
            }
        }
    };

    fetchModalMessage();
}, [status, order.orderItems]);

const getButtonClass = (label) => {
	switch (label) {
			case 'Confirmar Pedido':
				return 'button-confirm';
				case 'Cancelar Orden':
					return 'button-cancel';
				case 'Confirmar Pago':
					return 'button-confirm-payment';
			default:
				return 'button-default';
			}
};


	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>{order.internalId}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{status === 'Awaiting Confirmation' && unavailableItems?.length > 0 ? (
				 <div>
				 <table>
						 <thead>
								 <tr>
										 <th>Nombre</th>
										 <th>Stock</th>
										 <th>Minimo</th>
								 </tr>
						 </thead>
						 <tbody>
								 {unavailableItems.map((item, index) => (
										 <tr key={index}>
												 <td>{item.product.name} {item.variant && `- ${item.variant.name}`}</td>
												 <td>{item.variant ? item.variant.countInStock : item.product.countInStock}</td>
												 <td>{item.minStock}</td>
										 </tr>
								 ))}
						 </tbody>
				 </table>
		 </div>
				) : (
					<p>Selecciona si desea cancelar o confirmar la orden </p>
				)}
			</Modal.Body>
			<Modal.Footer className="justify-end">
			{options.map((item, index) => (
					<Button
						key={index}
						className={`me-2 ${getButtonClass(item.label)}`}
						onClick={() => {
							item.onClick();
							handleClose();
						}}
					>
						{item.label}
					</Button>
				))}
			</Modal.Footer>
		</Modal>
	);
}

export default StatusModal;
