import axios from '../restClient';

const BASE_URI = '/colors';
class ColorsService {
	async get() {
		const res = await axios.get(BASE_URI);
		return res;
	}
	async set(
		primaryColor,
		shadowNavColor,
		secondaryColor,
		backgroundColor,
		utilsColor,
		navFontColor,
		iconsColor,
		titlesColor,
		btnFontColor,
	) {
		const res = await axios.put(BASE_URI, {
			primaryColor,
			shadowNavColor,
			secondaryColor,
			backgroundColor,
			utilsColor,
			navFontColor,
			iconsColor,
			titlesColor,
			btnFontColor,
		});
		return res;
	}
}
export default new ColorsService();
