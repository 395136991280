import React, { useEffect, useState } from "react";
import "./NewShippingAddress.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import countries from "../../static/countries.json";
import statesJson from "../../static/countriesStates.json";
import moment from "moment";

export default function NewShippingAddress({
  submitHandler,
  shippingAddress,
  firstAddress,
  displaySaveAddress,
}) {
  const [addressName, setAddressName] = useState(
    shippingAddress ? shippingAddress.addressName : ""
  );
  const [name, setName] = useState(shippingAddress ? shippingAddress.name : "");
  const [addressLine1, setAddressLine1] = useState(
    shippingAddress ? shippingAddress.addressLine1 : ""
  );
  const [addressLine2, setAddressLine2] = useState(
    shippingAddress ? shippingAddress.addressLine2 : ""
  );
  const [state, setState] = useState(
    shippingAddress ? shippingAddress.state : ""
  );
  const [city, setCity] = useState(shippingAddress ? shippingAddress.city : "");
  const [country, setCountry] = useState(
    shippingAddress ? shippingAddress.country : ""
  );
  const [phone, setPhone] = useState(
    shippingAddress ? shippingAddress.phone : ""
  );
  const [states, setStates] = useState([]);
  const [disableStates, setDisableStates] = useState(true);
  const [saveAddress, setSaveAddress] = useState(true);
  const [isPrimary, setIsPrimary] = useState(firstAddress);
  const [show, setShow] = useState(false);

  const parseDate = (date) => {
    let parsedDate = null;
    if (moment(date).isValid()) {
      parsedDate = moment(date).toDate();
    } else if (moment(date, "DD/MM/YYYY").isValid()) {
      parsedDate = moment(date, "DD/MM/YYYY").toDate();
    }
    return parsedDate;
  };

  useEffect(() => {
    setDisableStates(true);
    if (country) {
      setStates(statesJson[country]);
      setDisableStates(false);
    }
  }, [country]);
  useEffect(() => {
    if (!saveAddress) {
      setShow(true);
    }
  }, [saveAddress]);
  const closeModal = () => {
    setSaveAddress(true);
    setShow(false);
  };
  return (
    <>
      <Modal size="sm" show={show} centered onHide={closeModal}>
        <Modal.Body>
          ¿Estás seguro de que no quieres guardar la dirección?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container small-container select-shipping-address mt-2 px-0">
        <div className="select-shipping-address-header">
          <h3 className="ps-3 my-3 mb-1">Dirección de entrega</h3>
        </div>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col md={6} className="mb-md-0 mb-2">
              <FloatingLabel
                controlId="addressName"
                label="Alías de la dirección"
              >
                <Form.Control
                  maxLength={70}
                  value={addressName}
                  type="text"
                  onChange={(e) => setAddressName(e.target.value)}
                  placeholder="Alías de la dirección"
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="name" label="Nombre del recipiente">
                <Form.Control
                  maxLength={30}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nombre del recipiente"
                  value={name}
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col className="mb-md-2 mb-0">
              <FloatingLabel controlId="addressLine1" label="Dirección">
                <Form.Control
                  maxLength={70}
                  type="text"
                  onChange={(e) => setAddressLine1(e.target.value)}
                  placeholder="Dirección"
                  value={addressLine1}
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel controlId="addressLine2" label="Esquina">
                <Form.Control
                  maxLength={70}
                  type="text"
                  onChange={(e) => setAddressLine2(e.target.value)}
                  placeholder="Esquina"
                  value={addressLine2}
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-md-0 mb-2">
              <FloatingLabel controlId="country" label="País">
                <Form.Select
                  onChange={(e) => setCountry(e.target.value)}
                  aria-label="Pais"
                  required
                  value={country}
                >
                  <option value="">Selecciona un pais</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="state" label="Estado">
                <Form.Select
                  onChange={(e) => setState(e.target.value)}
                  aria-label="Estado"
                  required
                  value={state}
                  disabled={disableStates}
                >
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-md-0 mb-2">
              <FloatingLabel controlId="city" label="Ciudad">
                <Form.Control
                  maxLength={40}
                  type="text"
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Ciudad"
                  value={city}
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="phone" label="Teléfono">
                <Form.Control
                  maxLength={30}
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Teléfono"
                  value={phone}
                  required
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="ms-1">
            {displaySaveAddress && (
              <Col md={6}>
                <Form.Group controlId="saveAddress">
                  <Form.Check
                    defaultChecked={saveAddress}
                    type="checkbox"
                    label="¿Guardar esta dirección?"
                    onChange={(e) => setSaveAddress(e.target.checked)}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md={6}>
              <Form.Group controlId="isPrimary">
                <Form.Check
                  defaultChecked={isPrimary}
                  type="checkbox"
                  label="Establecer como direccion primaria"
                  onChange={(e) => setIsPrimary(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mb-3">
            <Button variant="primary" type="submit">
              Continuar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
