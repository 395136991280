import React from "react";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import "./AddressCard.css";
import classnames from "classnames";

export default function AddressCard({
  address,
  selectHandler = null,
  deleteHandler = null,
  selected,
  makeAddressPrimary,
}) {
  const {
    addressLine1,
    addressLine2,
    addressName,
    city,
    name,
    state,
    phone,
    country,
    isPrimary,
  } = address;

  return (

    <Card className={classnames("address-card p-0 me-1", { ["selected-address"]: selected })}>
      <Card.Header className="d-flex">
        {isPrimary && deleteHandler  &&
          <Badge className="me-1">PRINCIPAL</Badge>
        }
        {selected &&

          <Badge id="selected-badge">SELECCIONADO</Badge>

        }

      </Card.Header>
      <Card.Body>
        <Card.Text>
          <span className="address-line address-name">{addressName}</span>
          <span className="address-line">{name}</span>
          <span className="address-line">{addressLine1}</span>
          <span className="address-line">y {addressLine2}</span>
          <span className="address-line">
            {city}, {state}
          </span>
          <span className="address-line">{country}</span>
          <span className="address-line">{phone}</span>
        </Card.Text>
      </Card.Body>
      <Card.Footer className="address-card-action-buttons">
          {selectHandler && (
            <button 
              onClick={() => selectHandler(address)}
            >
              Seleccionar
            </button>
          )}{" "}
          {deleteHandler && !selected &&  address.user && (
            <button 
              onClick={() => deleteHandler(address)}
            >
              Eliminar
            </button>
          )}
          {!isPrimary && makeAddressPrimary && address.user &&  (
            <button 
              onClick={() => makeAddressPrimary(address)}
            >
              Hacer principal
            </button>
          )}
      </Card.Footer>
    </Card>
  );
}
